// a React template for Gapfill type question

import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import GapFill from "../../components/question/GapFill";
import TagCheckPrivate from "../../components/question/TagCheckPrivate";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/examsQuestionSlice";
import { getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

export const Essay = memo(function Essay({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  questions,
  questionId,
  isAnswered,
  isPreview,
  handleSubmitAnswer
}) {
  const cache = getCachesQuestion(questionId, questionIndex);
  const dispatch = useDispatch();
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = [""];
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation?.images[1]?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const answeredQuestions = useSelector(selectAnsweredQuestions);

  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
  };
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }

  const [answersItem] = useState(() => {
    return questions
      ?.filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer !== null ? item.answer : [])
      .flat();
  });

  const [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      return question.state.answers;
    } else {
      if (answersItem && answersItem.length > 0) {
        return answersItem;
      } else {
        return [];
      }
    }
  });

  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (question.showSolution) {
      let newCorrectAnswers = [];
      for (let i = 0; i < questionSolutions.length; i++) {
        if (answersItem[i] === questionSolutions[i]) {
          newCorrectAnswers[i] = true;
        } else {
          newCorrectAnswers[i] = false;
        }
      }

      return newCorrectAnswers;
    } else {
      return [];
    }
  });

  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading] = useState(
    cache || !checkImage(questionChoices[0]) ? false : true
  );
  const [submitState, setSubmitState] = useState(isAnswered);
  const divRef = useRef(null);

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      divRef.current.clientWidth / ConstantStrings.BASE_REM !== maxWidth &&
      divRef.current.clientWidth / ConstantStrings.BASE_REM < 60
    ) {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  const saveAnswer = (answers) => {
    if (!question.showSolution) {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
        },
      };
      var answer = questionChoices.map((item) => false);

      if (answers.length > 0) {
        answers.map((item, index) => (answer[index] = item));
      } else {
        answer = null;
      }

      if (
        answer?.every((item) => item === "" || item === false || item === null)
      ) {
        answer = null;
      }

      var result = [...answeredQuestions];
      var newAnswer = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      // setSelected(selected);
      if (result.length === 0) {
        result.push(newAnswer);
      } else {
        var flag = true;
        for (
          let question = 0;
          question < answeredQuestions.length;
          question++
        ) {
          if (answeredQuestions[question].questionId === newAnswer.questionId) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (!flag) {
          result.push(newAnswer);
        } else {
          result = answeredQuestions.map((question) =>
            question.questionId === questionId ? newAnswer : question
          );
        }
      }
      var questionsAnswered = result;
      const submit = { submit: false, questions: [newAnswer] };
      var questionAnswered = {
        questionIndex: questionIndex,
        status: true,
      };

      if (answer === null) {
        questionAnswered.status = false;
      }
      const value = {
        questionState,
        questionAnswered,
        questionsAnswered,
        submit,
      }
      localStorage.setItem("questionStateExams", JSON.stringify(value));
    }
  };

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    // không cho xuống dòng và nhiều dấu cách cạnh nhau
    // const normalizedValue = e.target.value.replace(/\s+/g, " ");
    newAnswers[index] = e.target.value;
    saveAnswer(newAnswers);
    setAnswers(newAnswers);
  };
  const handleSubmit = () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    var payload = result.submit;
    if (!isPreview) {
      handleSubmitAnswer(payload, localStorage.getItem("examId"))
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    } else {
      setSubmitState(true);
      let newCorrectAnswers = [];
      for (let i = 0; i < questionSolutions.length; i++) {
        if (answers[i] === questionSolutions[i]) {
          newCorrectAnswers[i] = true;
        } else {
          newCorrectAnswers[i] = false;
        }
      }
      setCorrectAnswers(newCorrectAnswers);
    }
  };
  const handleReset = () => {
    setAnswers([]);
    setSubmitState(false);
    saveAnswer([]);
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    dispatch(setQuestionState(result.questionState));
    dispatch(setQuestionAnswered(result.questionAnswered));
    dispatch(setQuestionsAnswered(result.questionsAnswered));
  };

  return (
    <>
      <div style={backgroundStyle} ref={divRef} className="">
        <BackgroundImageFromUrl
          style={backgroundStyle}
          objectId={backgroundImage}
        >
          {/* if gapFillWithImages is false
        if questionChoices has length 4, render the textinput in a 2x2 grid
        else use columns is-multiline is-centered
    */}
          {isLoading && (
            <div className="has-text-kurio-main-color-black has-text-centered">
              Loading
            </div>
          )}
          {!explanationState && (
            <div
              className={`${
                isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column`}
              style={{
                padding: "1.5rem 2rem",
                gap: "1rem",
              }}
            >
              {
                //render questionTexts if its array has length greater than 0
                questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div
                      className="has-text-centered m-auto"
                      style={{ maxWidth: "40rem" }}
                    >
                      {questionTexts}
                    </div>
                  )
              }
              <div
                className="columns is-multiline is-centered"
                style={{ gap: "0.5rem" }}
              >
                {questionChoices.map((choice, index) => {
                  return (
                    <GapFill
                      key={index}
                      index={index}
                      type={"ESSAY"}
                      onChange={handleChange(index)}
                      answer={answers[index] || ""}
                      checkingState={isPreview !== undefined ? isPreview && submitState: submitState}
                      correctAnswers={correctAnswers[index]}
                      choice={questionChoices[index]}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {question.showSolution && (
            <>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center"
                style={{
                  gap: "2rem",
                }}
              >
                <ImageFromUrl
                  style={{ padding: "0rem 2rem" }}
                  objectId="admin/frontend-images/exam/Vector 2.png"
                />

                <h1
                  className="has-text-kurio-main-color-black"
                  style={{
                    fontWeight: 700,
                    fontSize: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  Hướng dẫn
                </h1>
              </div>
              <div
                className="is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "1.5rem" }}
              >
                {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                {question.showSolution && explanationState === false && (
                  <>
                    {(explanation.texts !== null ||
                      explanation.images !== null) &&
                    (explanation.texts?.length > 0 ||
                      explanation.images.length > 0) ? (
                      <div
                        style={{
                          gap: "1.5rem",
                          fontSize: "1.25rem",
                          padding: "1rem 3rem",
                          width: "100%",
                        }}
                        className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                      >
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 0 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[0]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 0 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[0]} />
                              </div>
                            </div>
                          )}
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 1 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[1]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 1 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[1]} />
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div
            className="has-background-kurio-main-color-light-gray is-flex is-align-items-center is-justify-content-center is-flex-gap-5"
            style={{
              padding: "0 2rem 2rem 2rem",
              borderRadius: "0 0 1rem 1rem",
            }}
          >
            {submitState && (
              <>
                <TagCheckPrivate />
                <PrimaryButtonQuestion onClick={handleReset}>
                  {ConstantStrings.RETRY}
                </PrimaryButtonQuestion>
              </>
            )}
            {!submitState && (
              <PrimaryButtonQuestion
                onClick={handleSubmit}
                disabled={
                  answers.some((item) => item === undefined || item === "") ||
                  answers.length <= 0
                }
              >
                {ConstantStrings.SUBMIT}
              </PrimaryButtonQuestion>
            )}
          </div>
        </BackgroundImageFromUrl>
      </div>
    </>
  );
});

export default Essay;
