/* eslint-disable react/jsx-pascal-case */
import React, { memo } from "react";
import { decodeToken } from "react-jwt";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { checkImage } from "../../../constants/checkImage";
import QPrompt1_1 from "../../../templates/content/QPrompt1_1";
import QPrompt1_2 from "../../../templates/content/QPrompt1_2";
import QPrompt1_3 from "../../../templates/content/QPrompt1_3";
import QPrompt2_1 from "../../../templates/content/QPrompt2_1";
import QPrompt2_2 from "../../../templates/content/QPrompt2_2";
import QPrompt2_3 from "../../../templates/content/QPrompt2_3";
import QPrompt2_4 from "../../../templates/content/QPrompt2_4";
import QPrompt3_1 from "../../../templates/content/QPrompt3_1";
import QPrompt3_2 from "../../../templates/content/QPrompt3_2";
import EquationMultiple from "../../../templates/exams-question/EquationMultiple";
import EquationSingle from "../../../templates/exams-question/EquationSingle";
import Essay from "../../../templates/exams-question/Essay";
import GapFillFloat from "../../../templates/exams-question/GapFillFloat";
import GapFillImage from "../../../templates/exams-question/GapFillImage";
import GapFillText from "../../../templates/exams-question/GapFillText";
import MultipleChoice from "../../../templates/exams-question/MultipleChoice";
import ShortAnswerFraction from "../../../templates/exams-question/ShortAnswerFraction";
import ShortAnswerNumber from "../../../templates/exams-question/ShortAnswerNumber";
import ShortAnswerText from "../../../templates/exams-question/ShortAnswerText";
import jsonParser from "../../../templates/helper/jsonParser";
import { getImageUrl } from "../../../templates/helper/parseImage";

export const ExamQuestionRightWrapper = memo(function ExamQuestionRightWrapper({
  question,
  handleQuestionChange,
  questions,
  widthScreen,
  handleSubmitAnswer,
  listUserInfo,
  selectedQuestion,
  isPreview,
}) {
  const promptTemplate = question?.template.prompt;
  const questionTemplate = question?.template.question;
  const cookies = new Cookies();
  const token = cookies.get("signinUser", { path: "/" });
  const decode = decodeToken(token);
  const userName = decode?.sub;
  const backgroundStyle = {
    backgroundSize: "cover",
    backgroundImage: checkImage(question?.backgroundImage)
      ? `url(${getImageUrl(question?.backgroundImage)})`
      : `url("")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "1rem",
  };
  const showNextQuestion = () => {
    if (
      question?.questionIndex !== questions.length - 1 ||
      selectedQuestion === -1
    ) {
      handleQuestionChange(
        selectedQuestion === -1 ? 0 : question.questionIndex + 1
      );
    } else {
      return;
    }
  };
  const showPreviousQuestion = () => {
    if (selectedQuestion !== -1 && question?.questionIndex !== 0) {
      handleQuestionChange(question.questionIndex - 1);
    }
  };

  const TemplateComponent = () => {
    switch (promptTemplate) {
      case "QPrompt1_1":
        return (
          <QPrompt1_1 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt1_2":
        return (
          <QPrompt1_2 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt1_3":
        return (
          <QPrompt1_3 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt2_1":
        return (
          <QPrompt2_1 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt2_2":
        return (
          <QPrompt2_2 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt2_3":
        return (
          <QPrompt2_3 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt2_4":
        return (
          <QPrompt2_4 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt3_1":
        return (
          <QPrompt3_1 data={question} order={question.questionIndex + 1} />
        );
      case "QPrompt3_2":
        return (
          <QPrompt3_2 data={question} order={question.questionIndex + 1} />
        );
      default:
        return <div></div>;
    }
  };

  const TemplateQuestion = () => {
    switch (questionTemplate) {
      case "MultipleChoice":
        return (
          <MultipleChoice
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "Essay":
        return (
          <Essay
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "ShortAnswerNumber":
        return (
          <ShortAnswerNumber
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "ShortAnswerText":
        return (
          <ShortAnswerText
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "ShortAnswerFraction":
        return (
          <ShortAnswerFraction
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "GapfillImage":
        return (
          <GapFillImage
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "GapfillText":
        return (
          <GapFillText
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "GapfillFloat":
        return (
          <GapFillFloat
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "EquationMultiple":
        return (
          <EquationMultiple
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      case "EquationSingle":
        return (
          <EquationSingle
            question={question.question}
            isAnswered={question.isAnswered}
            backgroundImage={
              checkImage(question?.question.backgroundImage)
                ? question?.question.backgroundImage
                : ""
            }
            isPreview={isPreview}
            questionIndex={question.questionIndex}
            explanation={question.explanation}
            questions={questions}
            questionId={question.questionId}
            handleSubmitAnswer={(payload, id) =>
              handleSubmitAnswer(payload, id)
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {widthScreen >= 70 && !isPreview && (
        <div
          className="has-background-kurio-purple-light-3 is-flex is-justify-content-center is-align-items-center"
          style={{
            width: widthScreen * 0.2 < 16 ? `${widthScreen - 16}rem` : "80%",
            maxWidth: `${widthScreen - (widthScreen - 80) - 16}rem`,
            height: "6rem",
            position: "fixed",
            gap: "2.4375rem",
            zIndex: 1,
            top: 0,
            left: `${widthScreen - 80 > 0 ? (widthScreen - 80) / 2 : 0}rem`,
          }}
        >
          <div className="is-flex">
            <img
              src="/images/menu/logo-IMAS.png"
              alt="logo"
              style={{ mixBlendMode: "multiply", height: "4rem" }}
            />
            <img src="/images/menu/IEG-logo.png" alt="logo" style={{objectFit: "cover",height:"4rem" }} />
          </div>
          <div
            style={{
              width: "0rem",
              height: "3rem",
              borderLeft: "0.25rem dashed #0A2A66",
            }}
          ></div>
          <div
            style={{
              fontSize: "0.75rem",
              lineHeight: "normal",
              width: "14.75rem",
            }}
          >
            <p>Vòng tuyển chọn</p>
            <p className="is-uppercase has-text-weight-bold">
            KỲ THI ĐÁNH GIÁ NĂNG LỰC TƯ DUY TOÁN HỌC QUỐC TẾ IMAS
            </p>
          </div>
          <div
            style={{
              width: "0rem",
              height: "3rem",
              borderLeft: "0.25rem dashed #0A2A66",
            }}
          ></div>
          <div
            className="is-flex is-flex-direction-column is-flex-gap-1"
            style={{ fontSize: "0.75rem", lineHeight: "normal" }}
          >
            <p className="is-uppercase has-text-weight-bold">
              {listUserInfo?.fullName}
            </p>
            <div className="is-flex is-flex-gap-2 has-text-weight-bold is-align-items-center">
              <p
                className="has-background-kurio-main-color-white has-text-kurio-main-color-purple is-uppercase"
                style={{
                  borderRadius: "0.5rem",
                  padding: "0.125rem 0.5rem",
                }}
              >
                SBD
              </p>
              <p>{userName}</p>
            </div>
          </div>
        </div>
      )}
      <div
        className={`is-flex is-flex-direction-column is-flex-grow-1 is-centered `}
        style={{
          ...backgroundStyle,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: widthScreen >= 57.5 && widthScreen < 70 ? "54rem" : "60rem",
          filter: "drop-shadow(0px 6px 16px rgba(10, 42, 102, 0.25))",
        }}
      >
        {selectedQuestion === -1 &&
        questions.length <= 14 &&
        questions.length > 1 ? (
          <>
            <div
              className="is-flex is-flex-grow-1 is-flex-direction-column is-flex-gap-4 has-text-kurio-main-color-black has-text-weight-bold"
              style={{ fontSize: "1.5rem", lineHeight: "normal" }}
            >
              <p>
                {jsonParser(
                  "There are 14 questions. Each question is worth 5 points. Partial credit is given for question 10 and 13."
                )}
              </p>
              <p>
                {jsonParser(
                  "For all answers, no need to key in their units. The format as following:"
                )}
              </p>
              <p>{jsonParser("For integer$$a,$$key in “$$a$$”.")}</p>
              <p>
                {jsonParser(
                  "For decimal$$a.bc,$$where$$a, b$$and$$c$$are digits, key in “$$a.bc$$” (decimal rounded to the hundredths place)."
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <TemplateComponent />
            </div>
            <div className="mb-6">
              <TemplateQuestion />
            </div>
          </>
        )}
      </div>

      <div className="is-flex is-flex-gap-4">
        {selectedQuestion !== -1 && selectedQuestion !== 0 && (
          <div
            className="is-clickable"
            onClick={showPreviousQuestion}
            disabled={question?.questionIndex === 0}
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage:
                "url('/images/button/previous-question-private.svg')",
              width: "14rem",
              height: "5rem",
              // position: "fixed",
              // bottom: "0",
              // left: "0",
            }}
          ></div>
        )}
        {question?.questionIndex !== questions?.length - 1 && (
          <div
            className="is-clickable is-flex is-flex-gap-3 is-align-items-center is-justify-content-center"
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage:
                "url('/images/button/background-button-next.svg')",
              width: "14rem",
              height: "5rem",
              // position: "fixed",
              // bottom: "0",
              // right: widthScreen >= 57.5 && widthScreen < 70 ? "0" : "20%",
            }}
            onClick={showNextQuestion}
          >
            <p
              className="is-uppercase has-text-kurio-main-color-white has-text-weight-bold"
              style={{ fontSize: "1.5rem", width: "7rem" }}
            >
              {selectedQuestion === -1 ? "Tiếp Tục Làm Bài" : "Câu Tiếp"}
            </p>
            <img
              src="images/button/arrow-right.svg"
              alt=""
              style={{ height: "1.875rem" }}
            />
          </div>
        )}
      </div>
    </>
  );
});
function mapStateToProps(state) {
  return {
    listUserInfo: state.grades.listUserInfo,
  };
}
export default connect(mapStateToProps)(ExamQuestionRightWrapper);
