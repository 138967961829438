import katex from "katex";
import React from "react";

const jsonParser = (text, index, className) => {
  // check regex for html tags between 2 @ signs or backlash n or latex strings
  const regex = /(@@.*?@@|\\n|\$\$.*?\$\$)/g;

  const regexTesxt = text.replace(
    /(<span class="math-tex">\\\()|\\\)(<\/span>)/g,
    (match, p1, p2) => {
      if (p1) {
        return '<span class="math-tex">@@$$';
      } else if (p2) {
        return "$$@@</span>";
      }
      return match;
    }
  );

  // split text into array of strings
  const textArray = regexTesxt.split(regex);

  // filter out empty strings
  const textArrayFiltered = textArray.filter((string) => string !== "");
  // filter undefined strings
  const textArrayFiltered2 = textArrayFiltered.filter(
    (string) => string !== undefined
  );

  // filter out latex strings
  const latex = textArrayFiltered2.filter((string) =>
    string.match(/^\$\$.*?\$\$/)
  );

  // filter out html tags
  const htmlTags = textArrayFiltered2.filter((string) =>
    string.match(/^@@.*?@@$/)
  );

  // filter out backlash n
  const backlashN = textArrayFiltered2.filter((string) => string.match(/\\n/g));

  // // filter out text
  // const text = textArrayFiltered.filter((string) => !string.match(/(<([^>]+)>|\\n|\$.*?\$)/g));

  // create array to store text to display
  var textToDisplay = [];
  var htmlComponents = [];
  var latexComponents = [];
  var backlashNComponents = [];
  // for each html tag in htmlTags, return a react component without the @ signs
  for (let i = 0; i < htmlTags.length; i++) {
    const htmlTag = htmlTags[i];

    let htmlTagWithoutAtSigns = htmlTag.replace(/@@/g, "");
    if (htmlTagWithoutAtSigns.includes("Times New Roman")) {
      htmlTagWithoutAtSigns = htmlTagWithoutAtSigns.replaceAll(
        "Times New Roman",
        "Montserrat"
      );
    }
    if (htmlTagWithoutAtSigns.includes("#000000") || htmlTagWithoutAtSigns.includes("#333333")) {
      htmlTagWithoutAtSigns = htmlTagWithoutAtSigns.replaceAll(
        /color:#(333333|000000)/g, "color:#0A2A66"
      );
    }

    if (htmlTagWithoutAtSigns.includes('class="math-tex"')) {
      htmlTagWithoutAtSigns = htmlTagWithoutAtSigns
        .replace(/<p([^>]*)>/g, "")
        .replace(/<\/p>/g, "");
    }

    const htmlTagWithReactComponent = (
      <span dangerouslySetInnerHTML={{ __html: htmlTagWithoutAtSigns }} />
    );

    htmlComponents.push(htmlTagWithReactComponent);
  }

  // for each latex string in latex, return a MathComponent
  for (let i = 0; i < latex.length; i++) {
    const latexString = latex[i];

    const latexStringWithoutDollarSigns = latexString.replace(/\$\$/g, "");

    const latexStringWithMathComponent = katex.renderToString(
      latexStringWithoutDollarSigns,
      { displayMode: false, output: "htmlAndMathml" }
    );

    const latexHtml = (
      <span
        className={className}
        style={{ margin: "0.5rem", display: "inline-block !important" }}
        dangerouslySetInnerHTML={{ __html: latexStringWithMathComponent }}
      />
    );
    latexComponents.push(latexHtml);
  }

  // for each backlash n in backlashN, return a react component
  for (let i = 0; i < backlashN.length; i++) {
    const backlashNStringWithReactComponent = <br />;
    backlashNComponents.push(backlashNStringWithReactComponent);
  }

  // go through text, if there is a latex string, replace it with the MathComponent
  // if there is a html tag, replace it with the html component not including the @ signs
  // if there is a backlash n, replace it with the backlash n component
  var displayingLatex = false;
  var displayingHtmlTag = false;
  var displayingBacklashN = false;

  for (let i = 0; i < regexTesxt.length; i++) {
    const char = regexTesxt[i];

    if (displayingLatex) {
      if (char === "$") {
        if (regexTesxt[i + 1] === "$") {
          textToDisplay.push(latexComponents.shift());
          displayingLatex = false;
        } else {
          continue;
        }
      }
    } else if (displayingHtmlTag) {
      if (char === "@") {
        if (regexTesxt[i + 1] === "@") {
          textToDisplay.push(htmlComponents.shift());
          displayingHtmlTag = false;
        } else {
          continue;
        }
      }
    } else if (displayingBacklashN) {
      if (char === "n") {
        textToDisplay.push(backlashNComponents.shift());
        displayingBacklashN = false;
      }
    } else {
      if (char === "$") {
        if (regexTesxt[i + 1] === "$") {
          displayingLatex = true;
        } else {
          continue;
        }
      } else if (char === "@") {
        if (regexTesxt[i + 1] === "@") {
          displayingHtmlTag = true;
        } else {
          continue;
        }
      } else if (char === "\\") {
        displayingBacklashN = true;
      } else {
        textToDisplay.push(char);
      }
    }
  }

  // return the text with the MathComponents as react components
  return (
    <span key={index}>
      {textToDisplay.map((text, index) => {
        return <span key={index}>{text}</span>;
      })}
    </span>
  );
};

export default jsonParser;
