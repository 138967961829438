import { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";

function QPrompt3_2({ data, order, isLast }) {
  const content = data?.content || data?.prompt;
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  console.log("load", isLoading)
  return (
    <div>
      {!isLast && !isLoading && <ScrollRef />}
      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`${
          isLoading ? "is-hidden" : ""
        }  has-text-centered has-background-kurio-main-color-white is-flex is-flex-direction-column is-justify-content-center is-align-items-center `}
        style={{
          borderRadius: "1rem 1rem 0 0",
          padding: "2rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {data?.template.question !== null &&
          data?.template.question &&
          data?.template.question.length > 0 && (
            <div className="mb-4">
              <QuestionNumberTag number={order === 0 ? data?.order : order} />
            </div>
          )}
        <div className={`content-image`}>
          <img src={getImageUrl(content?.images[0])} alt={content?.images[0]} />
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

export default QPrompt3_2;
