import React from "react";

function QuestionNumberTag({ number }) {
    return (
        <div
            className="tag has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
            style={{ borderRadius: "1rem", fontSize: "1.25rem" }}
        >
            <b>Question {number}</b>
        </div>
    );
}

export default QuestionNumberTag;
