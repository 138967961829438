// Still needs to review explanation part
// Revamp color and scaling

import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import TagCheckPrivate from "../../components/question/TagCheckPrivate";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/examsQuestionSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import { sumWithButton } from "../helper/calculateWidthButton";
import jsonParser from "../helper/jsonParser";
import { getCacheImage } from "../helper/parseImage";
export const MultipleChoice = memo(function MultipleChoice({
  question,
  questionId,
  questionIndex,
  backgroundImage,
  explanation,
  questions,
  isAnswered,
  isPreview,
  handleSubmitAnswer,
}) {
  const cache = getCachesQuestion(questionId, questionIndex);
  const dispatch = useDispatch();
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  const answeredQuestions = useSelector(selectAnsweredQuestions);

  //check if explanation is null
  // if (practiceQuizId !== undefined) {
  //   cookies.set("practiceQuizId", practiceQuizId);
  // }
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation.images[1]?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }
  const questionStateExams = JSON.parse(
    localStorage.getItem("questionStateExams")
  );

  var indexInLocal = questionStateExams?.questionsAnswered.findIndex(
    (item) => item.questionIndex === questionIndex
  );

  const [selectedItem] = useState(() => {
    return questions
      ?.filter((question) => question.questionIndex === questionIndex)
      .map((item) => item.answer)
      .flat()
      .indexOf(true);
  });

  //check if explanation is null
  //create a state variable to store the selected answer

  var [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  var [selected, setSelected] = useState(() => {
    if (question.state) {
      if (
        indexInLocal !== -1 &&
        questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
        questionStateExams?.questionsAnswered[indexInLocal]?.answer !== null
      ) {
        return questionStateExams?.questionsAnswered[indexInLocal].selected;
      }
      return question.state.selected;
    } else {
      if (selectedItem !== -1) {
        return selectedItem;
      } else {
        if (
          indexInLocal !== -1 &&
          questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
          questionStateExams?.questionsAnswered[indexInLocal]?.answer !== null
        ) {
          return questionStateExams?.questionsAnswered[indexInLocal].selected;
        }
        return -1;
      }
    }
  });

  //get max length of a choice
  var maxLength = 0;
  for (var i = 0; i < questionChoices.length; i++) {
    if (questionChoices[i].length > maxLength) {
      maxLength = questionChoices[i].length;
    }
  }
  //create a state variable to store the checking state

  const [imgWidth, setImgWidth] = useState(() => {
    if (cache?.imgWidth) {
      return cache.imgWidth;
    } else {
      return 0;
    }
  });
  const [widthButton] = useState(
    checkImage(questionChoices[0]) ? 0 : maxLength >= 11 ? 20 : 10
  );
  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });
  const [isLoading, setIsLoading] = useState(
    imgWidth === 0 && checkImage(questionChoices[0]) ? true : false
  );
  const [submitState, setSubmitState] = useState(isAnswered);

  const [showAnswer, setShowAnswer] = useState(false);

  var backgroundStyle = {
    borderRadius: "0rem 0rem 1rem 1rem",
    opacity: 1,
  };
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }
  let buttonStyle = {};
  if (maxLength <= 10 && !checkImage(questionChoices[0])) {
    buttonStyle = {
      width: "10rem",
      padding: "0.75rem 1rem",
      height: "4rem",
      opacity: 1,
      fontWeight: "bold",
      fontSize: "1.125rem",
    };
  } else {
    if (checkImage(questionChoices[0])) {
      buttonStyle = {
        maxWidth: "20rem",
        width: `${imgWidth !== 0 ? `${imgWidth + 0.125}rem` : "auto"}`,
        height: "auto",
        padding: "1rem",
        whiteSpace: "pre-wrap",
        border: "0.0625rem solid",
        opacity: 1,
        borderRadius: "1.5rem",
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    } else {
      buttonStyle = {
        width: "20rem",
        height: "auto",
        padding: "1rem 2.5rem",
        whiteSpace: "pre-wrap",
        border: "0.0625rem solid",
        opacity: 1,
        fontWeight: "bold",
        fontSize: "1.125rem",
      };
    }
  }

  var selectedStyle = {
    ...buttonStyle,
    border: "0.125rem solid",
  };

  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    top: "0.7rem",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.7rem",
  };

  //McqButton component
  const handleSetWidthImage = (width) => {
    setImgWidth(width);
  };
  const divRef = useRef(null);
  const McqButton = ({ index, children }) => {
    const buttonRef = useRef(null);
    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        {index === selected && isPreview && submitState ? (
          <>
            {questionSolutions[selected] === true ? (
              <img
                src="/images/common/greenV.svg"
                style={iconStyle}
                alt="loading"
              />
            ) : (
              <img
                src="/images/common/redX.svg"
                style={iconStyle}
                alt="loading"
              />
            )}
          </>
        ) : (
          <>
            <div
              style={{
                ...iconStyle,
                width: "0.75rem",
                height: "0.75rem",
              }}
              className="icon"
            ></div>
          </>
        )}
        <button
          ref={buttonRef}
          className={`${
            index === selected
              ? isPreview && submitState
                ? questionSolutions[selected] === true
                  ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                  : "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                : "has-background-kurio-purple-light-3 is-kurio-purple-main-accent has-text-kurio-purple-main-accent"
              : isPreview && submitState
              ? " is-kurio-gray-main-accent has-text-kurio-gray-main-accent"
              : "has-text-kurio-main-color-black is-kurio-main-color-black"
          } button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={index === selected && !isPreview ? selectedStyle : buttonStyle}
          onClick={() => handleSelect(index)}
          disabled={submitState || showAnswer}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
              getCachesQuestion(questionId, questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div className="column is-narrow" key={index}>
        <McqButton
          id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
          index={index}
        >
          {choice}
        </McqButton>
      </div>
    );
  });
  const McqAnswerButton = ({ index, children }) => {
    const buttonRef = useRef(null);
    return (
      //if index = selected, set the style to selectedStyle
      //if index = selected and checkingState = true, set the style to correctStyle if the answer is correct, set the style to incorrectStyle if the answer is incorrect
      <div className="is-relative">
        <img src="/images/common/greenV.svg" style={iconStyle} alt="loading" />
        <button
          ref={buttonRef}
          className={`${"has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"} button is-shadowless has-text-weight-bold is-outlined has-background-kurio-main-color-white`}
          style={index === selected && !isPreview ? selectedStyle : buttonStyle}
          onClick={() => handleSelect(index)}
          disabled={showAnswer}
        >
          {!children.slice(0, 2).includes("@@") && checkImage(children) ? (
            <>
              {getCacheImage([children]) &&
              getCachesQuestion(questionId, questionIndex) ? (
                <>
                  <img src={getCacheImage([children])} alt="loading" />
                </>
              ) : (
                <>
                  {index === 0 ? (
                    <ImageFromUrl
                      setImgWidth={(width) => handleSetWidthImage(width)}
                      objectId={children}
                    />
                  ) : (
                    <ImageFromUrl objectId={children} />
                  )}
                </>
              )}
            </>
          ) : (
            jsonParser(children)
          )}
        </button>
      </div>
    );
  };

  //mapping each choice to a component
  const MapAnswerChoices = questionChoices.map((choice, index) => {
    return (
      //id = "auto-height" if the choice is longer than 32 characters
      <div className="column is-narrow" key={index}>
        {questionSolutions[index] && (
          <McqAnswerButton
            id={choice.length > 10 && !checkImage(choice) ? "auto-height" : ""}
            index={index}
          >
            {choice}
          </McqAnswerButton>
        )}
      </div>
    );
  });
  const McqAnswerImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
        sumWithButton("IMAGE", imgWidth, 4) > maxWidth ? (
          <div>
            <div className="columns is-centered m-0 p-0">
              {MapAnswerChoices[0]}
              {MapAnswerChoices[1]}
            </div>
            <div className="columns is-centered m-0 p-0">
              {MapAnswerChoices[2]}
              {MapAnswerChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 5) > maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[0]}
                  {MapAnswerChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[2]}
                  {MapAnswerChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[0]}
                  {MapAnswerChoices[1]}
                  {MapAnswerChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[3]}
                  {MapAnswerChoices[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 6) > maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[0]}
                  {MapAnswerChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[2]}
                  {MapAnswerChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[4]}
                  {MapAnswerChoices[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[0]}
                  {MapAnswerChoices[1]}
                  {MapAnswerChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapAnswerChoices[3]}
                  {MapAnswerChoices[4]}
                  {MapAnswerChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 3) > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapAnswerChoices[0]}
                  {MapAnswerChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapAnswerChoices[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapAnswerChoices[0]}
                  {MapAnswerChoices[1]}
                  {MapAnswerChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered">
            {MapAnswerChoices}
          </div>
        )}
      </>
    );
  };
  const McqImageWrap = () => {
    return (
      <>
        {questionChoices.length === 4 &&
        sumWithButton("IMAGE", imgWidth, 4) > maxWidth ? (
          <div>
            <div className="columns is-centered m-0 p-0">
              {MapChoices[0]}
              {MapChoices[1]}
            </div>
            <div className="columns is-centered m-0 p-0">
              {MapChoices[2]}
              {MapChoices[3]}
            </div>
          </div>
        ) : questionChoices.length === 5 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 5) > maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[4]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 6 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 6) > maxWidth ? (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[2]}
                  {MapChoices[3]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
                <div className="columns is-centered m-0 p-0">
                  {MapChoices[3]}
                  {MapChoices[4]}
                  {MapChoices[5]}
                </div>
              </div>
            )}
          </>
        ) : questionChoices.length === 3 ? (
          <>
            {sumWithButton("IMAGE", imgWidth, 3) > maxWidth ? (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                </div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[2]}
                </div>
              </div>
            ) : (
              <div>
                <div className="columns is-centered p-0 m-0">
                  {MapChoices[0]}
                  {MapChoices[1]}
                  {MapChoices[2]}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="columns is-multiline is-centered">{MapChoices}</div>
        )}
      </>
    );
  };
  const saveAnswer = (index) => {
    var questionState = {
      index: questionIndex,
      state: {
        selected: Number(index),
      },
    };
    var answer = questionChoices.map((item) => false);
    answer[index] = true;
    if (index !== -1) {
      answer[index] = true;
    } else {
      answer = null;
    }

    var newAnswer = {
      questionId: questionId,
      answer: answer,
      questionIndex: questionIndex,
      selected: index,
    };
    // setSelected(selected);
    var result = [...answeredQuestions];
    if (result.length === 0) {
      result.push(newAnswer);
    } else {
      var flag = true;
      for (let question = 0; question < answeredQuestions.length; question++) {
        if (answeredQuestions[question].questionId === newAnswer.questionId) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if (!flag) {
        result.push(newAnswer);
      } else {
        result = answeredQuestions.map((question) =>
          question.questionId === questionId ? newAnswer : question
        );
      }
    }

    var questionsAnswered = result;

    const submit = { submit: false, questions: [newAnswer] };
    var questionAnswered = { questionIndex: questionIndex, status: true };

    if (index === -1) {
      questionAnswered.status = false;
    }
    const value = {
      questionState,
      questionAnswered,
      questionsAnswered,
      submit,
    };
    localStorage.setItem("questionStateExams", JSON.stringify(value));
  };

  //create a function to handle the selection of an answer
  //when an answer is selected, set the selected state variable to the index of the answer
  const handleSelect = (index) => {
    if (selected === index) {
      setSelected(-1);
      saveAnswer(-1);
    } else {
      setSelected(index);
      if (!question.showSolution) {
        saveAnswer(index);
      }
    }
  };
  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const handleSubmit = async () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    var payload = result.submit;
    if (!isPreview) {
      handleSubmitAnswer(payload, localStorage.getItem("examId"));
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    } else {
      setSubmitState(true);
    }
  };
  const handleReset = () => {
    setSelected(-1);
    setSubmitState(false);
    setShowAnswer(false)
    saveAnswer(-1);
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    if (questions?.length > 0) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    }
  };

  if (imgWidth !== 0) {
    cachesQuestion(questionId, questionIndex, imgWidth, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
  useEffect(() => {
    if (divRef?.current?.clientWidth / ConstantStrings.BASE_REM !== maxWidth) {
      setMaxWidth(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    };

    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [maxWidth]);

  //map each choice to a component
  return (
    <>
      <div ref={divRef}>
        <BackgroundImageFromUrl
          objectId={backgroundImage}
          style={backgroundStyle}
          className={`${
            !checkImage(backgroundImage)
              ? "has-background-kurio-main-color-light-gray"
              : ""
          }`}
        >
          {isLoading && <div className="has-text-centered">Loading</div>}
          {!explanationState && (
            <div
              className={`${isLoading ? "is-hidden" : ""}`}
              style={{
                padding: "1.5rem 2rem",
              }}
            >
              {/* {jsonParser("$$\\begin{aligned} 12 \\times (20 - 15) &= 12 \\times 5 \\\\ &=  \\color{red}{60}\\end{aligned}$$  \\n @@<p style='color:red'>test ok</p>@@ ")} */}
              {
                //render questionTexts if its array has length greater than 0
                questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div
                      className=" has-text-centered m-auto"
                      style={{ maxWidth: "40rem" }}
                    >
                      {questionTexts}
                    </div>
                  )
              }
              {checkImage(questionChoices[0]) ? (
                <>
                  <McqImageWrap />
                </>
              ) : (
                <>
                  {questionChoices.length === 4 &&
                  (widthButton + 1.5) * 4 + 4 > maxWidth ? (
                    <div>
                      <div className="columns is-centered m-0 p-0">
                        {MapChoices[0]}
                        {MapChoices[1]}
                      </div>
                      <div className="columns is-centered m-0 p-0">
                        {MapChoices[2]}
                        {MapChoices[3]}
                      </div>
                    </div>
                  ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                  questionChoices.length === 5 ? (
                    <>
                      {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                        <div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[2]}
                            {MapChoices[3]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[4]}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[3]}
                            {MapChoices[4]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                  questionChoices.length === 6 ? (
                    <>
                      {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                        <div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[2]}
                            {MapChoices[3]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[4]}
                            {MapChoices[5]}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapChoices[3]}
                            {MapChoices[4]}
                            {MapChoices[5]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : questionChoices.length === 3 ? (
                    <>
                      {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                        <div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                          </div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[2]}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="columns is-centered p-0 m-0">
                            {MapChoices[0]}
                            {MapChoices[1]}
                            {MapChoices[2]}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="columns p-0 m-0 is-multiline is-centered">
                      {MapChoices}
                    </div>
                  )}
                </>
              )}
              {showAnswer && (
                <>
                  <div
                    className=""
                    style={{ borderTop: "4px dashed #8C9DC1" }}
                  ></div>
                  <p
                    className="is-uppercase has-text-kurio-main-color-black has-text-weight-bold has-text-centered my-4"
                    style={{ lineHeight: "normal" }}
                  >
                    đáp án
                  </p>
                  {checkImage(questionChoices[0]) ? (
                    <>
                      <McqAnswerImageWrap />
                    </>
                  ) : (
                    <>
                      {questionChoices.length === 4 &&
                      (widthButton + 1.5) * 4 + 4 > maxWidth ? (
                        <div>
                          <div className="columns is-centered m-0 p-0">
                            {MapAnswerChoices[0]}
                            {MapAnswerChoices[1]}
                          </div>
                          <div className="columns is-centered m-0 p-0">
                            {MapAnswerChoices[2]}
                            {MapAnswerChoices[3]}
                          </div>
                        </div>
                      ) : // if questionChoices has length 5, render the choices in a 3x2 grid
                      questionChoices.length === 5 ? (
                        <>
                          {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                            <div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[0]}
                                {MapAnswerChoices[1]}
                              </div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[2]}
                                {MapAnswerChoices[3]}
                              </div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[4]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[0]}
                                {MapAnswerChoices[1]}
                                {MapAnswerChoices[2]}
                              </div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[3]}
                                {MapAnswerChoices[4]}
                              </div>
                            </div>
                          )}
                        </>
                      ) : // if questionChoices has length 6, render the choices in a 3x3 grid
                      questionChoices.length === 6 ? (
                        <>
                          {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                            <div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[0]}
                                {MapAnswerChoices[1]}
                              </div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[2]}
                                {MapAnswerChoices[3]}
                              </div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[4]}
                                {MapAnswerChoices[5]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[0]}
                                {MapAnswerChoices[1]}
                                {MapAnswerChoices[2]}
                              </div>
                              <div className="columns is-centered m-0 p-0">
                                {MapAnswerChoices[3]}
                                {MapAnswerChoices[4]}
                                {MapAnswerChoices[5]}
                              </div>
                            </div>
                          )}
                        </>
                      ) : questionChoices.length === 3 ? (
                        <>
                          {(widthButton + 1.5) * 3 + 4 > maxWidth ? (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapAnswerChoices[0]}
                                {MapAnswerChoices[1]}
                              </div>
                              <div className="columns is-centered p-0 m-0">
                                {MapAnswerChoices[2]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="columns is-centered p-0 m-0">
                                {MapAnswerChoices[0]}
                                {MapAnswerChoices[1]}
                                {MapAnswerChoices[2]}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="columns p-0 m-0 is-multiline is-centered">
                          {MapAnswerChoices}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {question.showSolution && (
            <>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center"
                style={{
                  gap: "2rem",
                }}
              >
                <ImageFromUrl
                  style={{ padding: "0rem 2rem" }}
                  objectId="admin/frontend-images/exam/Vector 2.png"
                />
                <h1
                  className="has-text-kurio-main-color-black"
                  style={{
                    fontWeight: 700,
                    fontSize: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  Hướng dẫn
                </h1>
              </div>
              <div
                className="is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "1.5rem" }}
              >
                {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                {question.showSolution && explanationState === false && (
                  <>
                    {(explanation.texts !== null ||
                      explanation.images !== null) &&
                    (explanation.texts?.length > 0 ||
                      explanation.images.length > 0) ? (
                      <div
                        style={{
                          gap: "1.5rem",
                          fontSize: "1.25rem",
                          padding: "1rem 3rem",
                          width: "100%",
                        }}
                        className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                      >
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 0 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[0]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 0 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[0]} />
                              </div>
                            </div>
                          )}
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 1 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[1]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 1 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[1]} />
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {/* only appears when explanationState is true */}
          <div
            className="has-background-kurio-main-color-light-gray is-flex is-align-items-center is-justify-content-center is-flex-gap-5"
            style={{
              padding: "0 2rem 2rem 2rem",
              borderRadius: "0 0 1rem 1rem",
            }}
          >
            {submitState && (
              <>
                <TagCheckPrivate />
                <PrimaryButtonQuestion onClick={handleReset}>
                  {ConstantStrings.RETRY}
                </PrimaryButtonQuestion>
              </>
            )}

            {!submitState && (
              <PrimaryButtonQuestion
                onClick={handleSubmit}
                disabled={selected === -1}
              >
                {ConstantStrings.SUBMIT}
              </PrimaryButtonQuestion>
            )}
            {isPreview && (
              <>
                {showAnswer && (
                  <PrimaryButtonQuestion onClick={handleShowAnswer}>
                    Ẩn đáp án
                  </PrimaryButtonQuestion>
                )}
                {!showAnswer && (
                  <PrimaryButtonQuestion onClick={handleShowAnswer}>
                    Hiện đáp án
                  </PrimaryButtonQuestion>
                )}
              </>
            )}
          </div>
        </BackgroundImageFromUrl>
      </div>

      {/* {!question.isLast && (
        <div
          style={nextButtonStyle}
          className="is-flex is-flex-direction-row is-justify-content-space-between"
        >
          <div
            className=""
            onClick={showPreviousQuestion}
            // style={nextButtonStyle}
            disabled={questionIndex === 0}
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage:
                "url('/images/button/previous-question-private.svg')",
              width: "9.0625rem",
              height: "9.875rem",
            }}
          >
            Previous
          </div>

          {questionIndex !== questions.length - 1 && (
            <div
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage:
                  "url('/images/button/next-question-private.svg')",
                width: "9.0625rem",
                height: "9.875rem",
              }}
              className=""
              onClick={showNextQuestion}
            >
              {selected !== -1 ? "Next" : "Skip"}
            </div>
          )}
        </div>
      )} */}
    </>
  );
});

export default MultipleChoice;
