import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import ShowPopupError from "../../components/ShowError409";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import ConstantStrings from "../../constants/ConstantStrings";
import { getExamQuestion } from "../../reducers/examsQuestionSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import Footer from "../footer/Footer";
import MenuTopBar from "./MenuTopBar";

const MenuMainWrapper = ({ listUserInfo, examQuestions, error }) => {
  const [widthDiv, setWidthDiv] = useState(57.5);
  const [pathname, setPathname] = useState("");
  const [childHeight, setChildHeight] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showPopup, setShowPopup] = useState(
    JSON.parse(localStorage.getItem("showPopup"))
      ? JSON.parse(localStorage.getItem("showPopup")).showPopup
      : false
  );
  const handleSetPathname = (value) => {
    setPathname(value);
  };
  const dispatch = useDispatch();
  const outLetStyle = {
    position: "absolute",
    top:
      pathname === "/"
        ? widthDiv >= 70
          ? listUserInfo !== null && listUserInfo
            ? "36.06rem"
            : "calc(100% - 7.5rem)"
          : listUserInfo !== null && listUserInfo
          ? "40.81rem"
          : "37.6rem"
        : widthDiv >= 70
        ? "11rem"
        : "12.5rem",
    left:
      widthDiv >= 70
        ? `${(widthDiv - 58.61813) / 2}rem`
        : `${(widthDiv - 40) / 2}rem`,
  };
  const childRef = useRef(null);
  const divRef = useRef(null);
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };
  const handleStartExam = (token) => {
    dispatch(
      getExamQuestion({
        takeExamsQuestionId: listUserInfo?.examId,
        page: 1,
        size: 70,
        token,
      })
    );
    setLoadingImage(true);
    // setTimeout(() => {
    //   navigate("/exam-questions");
    // }, 300);
  };
  const removePopup = () => {
    localStorage.removeItem("showPopup");
    setShowPopup(false);
  };
  useEffect(() => {
    // localStorage.removeItem("examId");
    if (childRef.current) {
      const height = childRef?.current?.offsetHeight;
      setChildHeight(height);
      divRef.current.style.height =
        listUserInfo !== null && listUserInfo
          ? `${height / ConstantStrings.BASE_REM + 7.5 + 3.5 + 31.0625}rem`
          : pathname === "/"
          ? ``
          : `${height / ConstantStrings.BASE_REM + 7.5 + 3.5}rem`;
    }
    if (showPopup) {
      setTimeout(() => {
        localStorage.removeItem("showPopup");
        setShowPopup(false);
      }, 5000);
    }
    const setWidth = () => {
      setWidthDiv(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    };
    if (divRef?.current?.clientWidth / ConstantStrings.BASE_REM !== 57.5) {
      setWidthDiv(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    }
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [widthDiv, childHeight, pathname, listUserInfo, showPopup]);
  if (error !== null && error === 409) {
    return <ShowPopupError isShowPopup={true} />;
  } else if (loadingImage && error !== null && error && error?.length > 0) {
    // handleSetLoadingImage(false)
    if (error === 409) {
      localStorage.setItem("showPopup", JSON.stringify({ showPopup: true }));
      window.location.href =
        process.env.REACT_APP_ENV === "development"
          ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
          : process.env.REACT_APP_LANDING_PAGE_URL;
    } else {
      window.location.href =
        process.env.REACT_APP_ENV === "development"
          ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
          : process.env.REACT_APP_LANDING_PAGE_URL;
          alert(error);
    }
  }
  if (loadingImage && examQuestions !== null && examQuestions?.length > 0) {
    const result = getAllImage(examQuestions, "EXAM");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={"exam-questions"}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      window.location.href = window.location.origin + "/exam-questions";
      // setLoadingImage(false);
    }
  }
  return (
    <div
      ref={divRef}
      className=" m-auto wrapper-main-content is-relative has-background-white"
      style={{ maxWidth: "80rem", minHeight: "100vh" }}
    >
      {/* <Notification /> */}
      {loadingImage && <LoadingImage />}
      {showPopup && (
        <div className=" modal is-active " style={{ zIndex: 101 }}>
          <div
            className="modal-background "
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "2rem",
              width: "37.75rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Hãy thử lại bắt đầu làm bài. !
            </h2>
            <div
              className="is-flex is-flex-direction-column has-text-centered is-flex-gap-5"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.125rem",
              }}
            >
              <p className="has-text-centered">
                Liên hệ hotline <b>0981048228</b> để được trợ giúp
              </p>
            </div>
            <div className="is-flex is-flex-direction-column is-flex-gap-4">
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Đóng
              </PrimaryButtonPageLevel>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-12%",
                left: "-8%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
      {!loadingImage && (
        <div
          className="is-flex is-relative is-flex-direction-column p-0 m-0 has-background-white is-justify-content-space-between"
          style={{ minHeight: "100vh", width: "100%" }}
        >
          <MenuTopBar
            handleSetPathname={(value) => handleSetPathname(value)}
            handleStartExam={(token) => handleStartExam(token)}
            // handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            widthDiv={widthDiv}
          />

          <div ref={childRef} style={outLetStyle}>
            <Outlet />
            <Footer widthDiv={widthDiv} />
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    listUserInfo: state.grades.listUserInfo,
    examQuestions: state.examsQuestion.questions,
    error: state.examsQuestion.error,
  };
}
export default connect(mapStateToProps)(MenuMainWrapper);
