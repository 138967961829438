import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const HomePagePrivate = ({ listUserInfo, widthDiv }) => {
  return (
    <>
      {/* <Notification /> */}
      {listUserInfo !== null && listUserInfo && (
        <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-8 mb-8">
          <div
            className="title-header is-flex is-flex-direction-column has-text-centered is-flex-gap-4 is-align-items-center has-text-weight-bold is-uppercase"
            style={{
              color: "#011CAA",
              // fontFamily: "Arimo !important",
              width: widthDiv >= 57.5 && widthDiv < 70 ? "40rem" : "47.3125rem",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            <h2 style={{ fontSize: "3rem" }}>NỘI QUY & QUY CHẾ</h2>
            <p style={{ fontSize: "1.5rem" }}>
              KỲ THI ĐÁNH GIÁ NĂNG LỰC TƯ DUY TOÁN HỌC QUỐC TẾ IMAS
              <br></br> – VÒNG 1–<br></br>
              HÌNH THỨC TRỰC TUYẾN
            </p>
          </div>
          <div
            className="is-flex is-flex-direction-column is-flex-gap-5 has-text-kurio-main-color-black "
            style={{
              width: "40rem",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            <p>
              1. Thí sinh cần có hai thiết bị kết nối internet và chỉ được đăng
              nhập vào <b>hai (02) website</b> sau để dự thi, trong đó:
            </p>
            <p className="pl-5">
              1.1. <b>Thiết bị giám sát: </b>
              Máy tính cá nhân/Điện thoại/Máy tính
              bảng có camera, micro và cài đặt phần mềm Zoom. Đăng nhập vào
              phòng thi online qua ứng dụng Zoom.us tại link được cung cấp trên
              thẻ dự thi. Thí sinh chỉ được phép đăng nhập vào đúng phòng thi
              như trên thẻ dự thi{" "}
              <b style={{ color: "red" }}>
                (trước 30 phút để BTC điểm danh, kiểm tra camera)
              </b>
            </p>
            <p className="pl-5">
              1.2. <b>Thiết bị bài làm: </b>Máy tính cá nhân/Laptop
            </p>
            <i style={{ color: "red" }}>
              <b>Lưu ý:</b> Thiết bị làm bài chỉ dùng để đăng nhập vào hệ thống
              thi, không cần đăng nhập vào Zoom. Cần tắt chế độ tự động dịch
              trên Google Chrome của thiết bị.
            </i>
            <div className="pl-5 has-text-weight-normal">
              <ul className="pl-3" style={{ listStyle: "inside" }}>
                <li>
                  Từ 14:00 ngày 04/12/2024, phụ huynh và học sinh có thể đăng
                  nhập làm quen hệ thống thi tại
                  <Link
                    className="has-text-kurio-main-color-black is-underlined has-text-weight-bold pl-2"
                    to={"https://online-imas.ieg.vn"}
                  >
                    online-imas.ieg.vn
                  </Link>{" "}
                  <p className="is-italic pl-5 my-2">
                    Hệ thống sẽ đóng vào 18:00 ngày 08/12/2024.
                  </p>
                </li>
                <li
                  className="is-italic ml-8 mb-2"
                  style={{ listStyle: "square" }}
                >
                  Không cần truy cập vào Zoom trong thời gian này
                </li>
                <li className="has-text-weight-bold is-italic">
                  Thời gian thi chính thức vào ngày 15/12/2024
                </li>
                <li
                  className="is-italic ml-8 my-2"
                  style={{ listStyle: "square" }}
                >
                  Cấp độ 1: Từ 14:00 đến 15:15
                </li>
                <li
                  className="is-italic ml-8 mb-2"
                  style={{ listStyle: "square" }}
                >
                  Cấp độ 2 và 3: Từ 16:30 đến 17:45
                </li>
                <li
                  className="is-italic ml-8 mb-2"
                  style={{ listStyle: "square" }}
                >
                  Thí sinh phải truy cập vào hệ thống Zoom vào hôm thi chính
                  thức.
                </li>
              </ul>
            </div>
            <p>
              2. Thí sinh đăng nhập sau khi đã bắt đầu thời gian làm bài thi 15
              phút sẽ không được dự thi.
            </p>
            <p>
              3. Thí sinh không được phép rời khỏi máy tính, rời khỏi khung hình
              trên phòng thi online trong suốt thời gian làm bài thi cho đến khi
              nộp bài hoặc hết giờ thi.
            </p>
            <p>
              4. Thí sinh chỉ được sử dụng trình duyệt Google Chrome để làm bài
              dự thi. <b>Thí sinh không refresh (tải lại) trang</b> trước khi
              nộp bài vì hệ thống sẽ không lưu lại kết quả, thí sinh có thể sẽ
              phải làm lại từ đầu.
            </p>
            <b style={{ color: "red" }}>
              Lưu ý: Thí sinh nên ghi đáp án từng câu ra nháp để nhập lại trong
              trường hợp kết nối mạng gặp sự cố.
            </b>
            <p>
              5. Thí sinh phải đảm bảo tốc độ đường truyền Internet cá nhân ổn
              định trong suốt quá trình làm bài thi.
            </p>
            <p>
              6. Thí sinh phải đăng nhập vào phòng thi online trên ứng dụng Zoom
              trước giờ thi 30 phút. Mỗi thí sinh được phép chuẩn bị bút, giấy
              trắng (làm nháp, tối đa 3 tờ). BTC sẽ yêu cầu thí sinh giơ giấy
              trắng lên để kiểm tra.
            </p>
            <p className="pl-5">
              Trong suốt thời gian làm bài phải mở camera trên Zoom và bật
              micro. Thí sinh phải có camera được cài theo yêu cầu của BTC như
              sau: Khung hình trong camera bao gồm:{" "}
            </p>
            <p className="pl-5 has-text-weight-bold"> - Thí sinh làm bài thi</p>
            <p className="pl-5 has-text-weight-bold">
              {" "}
              - Màn hình máy tính làm bài thi
            </p>
            <p className="pl-5 has-text-weight-bold">
              {" "}
              - Mặt bàn làm bài của thí sinh, thấy rõ các thao tác của thí sinh{" "}
            </p>
            <img
              style={{ width: "100%", height: "100%" }}
              src="/images/hub/image22.svg"
              alt=""
            />
            <p>
              7. Thí sinh không được sử dụng máy tính cầm tay, từ điển hay bất
              cứ thiết bị, trang web, phần mềm hỗ trợ trong việc tính toán nào
              khác.
            </p>
            <p>
              8. Nghiêm cấm mọi hành vi gian lận, không được trao đổi ý kiến,
              trao đổi tài liệu khi làm bài.
            </p>
            <p>
              9. BTC có quyền quyết định cuối cùng về việc công nhận kết quả bài
              thi của thí sinh.
            </p>
          </div>
          <div
            className="is-flex is-flex-direction-column is-flex-gap-5 has-text-kurio-main-color-black "
            style={{
              width: "40rem",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            <b className="" style={{ fontSize: "1.5rem" }}>
              I. HƯỚNG DẪN ĐĂNG NHẬP LÀM BÀI THI
            </b>
            <div className="is-flex is-flex-direction-column is-flex-gap-5">
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 1
                </p>
                <div
                  className="p-4 is-flex-grow-1 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    Kiểm tra SBD, thông tin dự thi trong thẻ dự thi. Thẻ dự thi
                    sẽ được gửi vào email của nhà trường. Quý phụ huynh liên hệ
                    với nhà trường để lấy thẻ dự thi cho thí sinh.
                  </p>
                </div>
              </div>
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 2
                </p>
                <div
                  className="p-4 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    Truy cập vào ứng dụng Zoom{" "}
                    <Link
                      className="has-text-kurio-main-color-black is-underlined"
                      to={"https://www.zoom.com/"}
                      target="_blank"
                    >
                      (www.zoom.us)
                    </Link>{" "}
                    trước giờ thi 30 phút và cài đặt ứng dụng Zoom trên máy.
                  </p>
                  <div>
                    <p className="has-text-weight-bold">
                      Hướng dẫn tải ứng dụng Zoom tham khảo tại:
                    </p>
                    <Link
                      className="has-text-kurio-main-color-black is-underlined"
                      target="_blank"
                      to={
                        "https://zoom.org.vn/huong-dan-su-dung-zoom/tai-phan-mem-zoom/"
                      }
                    >
                      https://zoom.org.vn/huong-dan-su-dung-zoom/tai-phan-mem-zoom/
                    </Link>
                  </div>
                </div>
              </div>
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 3
                </p>
                <div
                  className="p-4 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    Nhập ID và mật khẩu phòng thi online <b>Zoom</b> (thông tin
                    đã có trong thẻ dự thi).
                  </p>
                </div>
              </div>
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 4
                </p>
                <div
                  className="p-4 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    <b>
                      Đặt tên người dùng trong phòng Zoom là SBD của thí sinh
                    </b>{" "}
                    và đợi giám thị xác nhận vào phòng thi.
                  </p>
                  <div>
                    <p>
                      Thí sinh đặt tên camera như sau:{" "}
                      <b>[SBD] . [Họ và Tên]</b>
                    </p>
                    <p>
                      <b>Ví dụ:</b> 010101.Nguyễn Văn Anh
                    </p>
                  </div>
                </div>
              </div>
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 5
                </p>
                <div
                  className="p-4 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    Kiểm tra micro, camera trong phòng thi online hiển thị rõ
                    ràng hình ảnh thí sinh.
                  </p>
                </div>
              </div>
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 6
                </p>
                <div
                  className="p-4 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    Truy cập vào hệ thống thi tuyển chọn online tại:
                    <i
                      className="has-text-weight-bold is-italic"
                      style={{ color: "red" }}
                    >
                      online-imas.ieg.vn
                    </i>
                  </p>
                  <p
                    className="has-text-weight-bold is-italic"
                    style={{ color: "red" }}
                  >
                    Đăng nhập để làm bài: Sử dụng SBD, mật khẩu dự thi (thông
                    tin trong thẻ dự thi) (trước giờ thi 15 phút).
                  </p>
                </div>
              </div>
              <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                <p
                  className="has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
                  style={{
                    borderRadius: "2rem",
                    padding: "0.375rem 1rem",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    width: "6.75rem",
                  }}
                >
                  Bước 7
                </p>
                <div
                  className="p-4 has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-5"
                  style={{
                    background: "#EBF1F3",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  <p>
                    Bài thi sẽ tự động mở khi đến thời gian làm bài thi theo quy
                    định.
                  </p>
                  <p>
                    Khi gặp sự cố, thí sinh phải lập tức giơ tay (trong phòng
                    zoom) để giám thị và BTC kịp thời hỗ trợ.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="is-flex is-flex-direction-column is-flex-gap-5 has-text-kurio-main-color-black "
            style={{
              width: "40rem",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            <b style={{ fontSize: "1.5rem" }}>
              II. CÁC TRƯỜNG HỢP VI PHẠM QUY CHẾ THI
            </b>
            <p>
              1. Thí sinh không đáp ửng đủ điều kiện dự thi như đã thông báo
              (không cài đặt camera theo hướng dẫn của BTC).
            </p>
            <p>
              2. Thí sinh đăng nhập vào hệ thống thi sau thời gian bắt đầu làm
              bài dự thi 15 phút.
            </p>
            <p>
              3. Thí sinh không tham gia vào phòng zoom. Trong lúc thi, cố tình
              làm ồn, không nghe nhắc nhở của giám thị sẽ bị nhắc nhớ 3 lần, sau
              đó nếu có tái phạm sẽ bị hủy bài thi.
            </p>
            <p>
              4. Thí sinh tự ý tắt trình duyệt và mở tab khác trong thời gian
              làm bài thi.
            </p>
            <p>
              5. Thí sinh tự ý rời khỏi máy tính hoặc không xuất hiện trên
              camera trong thời gian làm bài thi.
            </p>
            <p>
              6. Thí sinh không mở camera, micro trong thời gian làm bài thi.
              Giám thị sẽ nhắc nhở thí sinh 3 lần, sau đó nếu có tái phạm sẽ bị
              huỷ bài thi.
            </p>
            <p>
              7. Thí sinh sử dụng máy tính, các phần mềm, thiết bị hỗ trợ tính
              toán, dịch thuật, tra cứu hoặc trao đổi đề thi với người khác
              trong thời gian làm bài thi.
            </p>
            <p>8. Thí sinh nhờ người thi hộ.</p>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    listUserInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(HomePagePrivate);
