import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";

function QPrompt1_1({ data, order, isLast }) {
  const content = data?.content || data?.prompt;
  const contentTexts =
    content.texts !== null &&
    content.texts !== undefined &&
    content.texts?.length > 0
      ? content.texts.map((text, index) =>
          jsonParser(text, index, "MainContent11Context")
        )
      : content.texts;
  const contentImages = content?.images;
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  if (checkImage(content?.images[0]) && isLoading) {
    var key = getImageUrl(content?.images[0]);
    loadWidthImage(key, (err, img) => {
      cacheImage([key]);
      setIsLoading(false);
    });
  }
  return (
    <div>
      {/* {!isLast && !isLoading && <ScrollRef />} */}
      <BackgroundImageFromUrl
        objectId={
          checkImage(content?.backgroundImage) ? content?.backgroundImage : ""
        }
        className={`${
          isLoading ? "is-hidden" : ""
        } has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-kurio-main-color-black has-background-kurio-main-color-white`}
        style={{
          borderRadius: "1rem 1rem 0 0",
          fontSize: "1.25rem",
          lineHeight: "160%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          gap: "2rem",
          padding: "2rem 2rem 3rem 2rem",
        }}
      >
        <div
          className={`${
            isLoading ? "is-hidden" : ""
          } m-auto has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center`}
          style={{
            maxWidth: "60rem",
            gap: "1rem",
          }}
        >
          <div>
            {data?.template.question !== null &&
              data?.template.question &&
              data?.template.question.length > 0 && (
                <div className="">
                  <QuestionNumberTag
                    number={order === 0 ? data?.order : order}
                  />
                </div>
              )}
          </div>
          <div
            className={`is-flex is-flex-direction-column is-flex-gap-6 is-justify-content-center`}
          >
            {((content?.texts !== null &&
              content?.texts !== undefined &&
              content?.texts?.length > 0) ||
              (content?.image !== null &&
                content?.image !== undefined &&
                content?.images?.length > 0)) &&
              contentTexts?.length >= contentImages?.length &&
              contentTexts.map((item, index) => (
                <div
                  key={index}
                  className="is-flex is-flex-direction-column is-flex-gap-6 is-justify-content-center has-text-justified"
                >
                  {contentTexts !== null &&
                    contentTexts !== undefined &&
                    content?.texts[index]?.length > 0 && (
                      <div
                        className={`${isLoading ? "is-hidden" : ""}`}
                        key={index}
                        style={{ fontSize: "1.25rem" }}
                      >
                        {item}
                      </div>
                    )}
                  {contentImages !== null &&
                    contentImages !== undefined &&
                    contentImages[index]?.length > 0 && (
                      <div className="is-flex is-justify-content-center">
                        <img
                          src={getImageUrl(contentImages[index])}
                          alt={contentImages[index]}
                        />
                      </div>
                    )}
                </div>
              ))}
            {contentTexts?.length < contentImages?.length &&
              contentImages.map((item, index) => (
                <div
                  key={index}
                  className="is-flex is-flex-direction-column is-flex-gap-6 is-justify-content-center has-text-justified"
                >
                  {contentTexts !== null &&
                    contentTexts !== undefined &&
                    content?.texts[index]?.length > 0 && (
                      <div
                        className={`${isLoading ? "is-hidden" : ""}`}
                        key={index}
                        style={{ fontSize: "1.25rem" }}
                      >
                        {contentTexts[index]}
                      </div>
                    )}
                  {contentImages !== null &&
                    contentImages !== undefined &&
                    item?.length > 0 &&
                    checkImage(item) && (
                      <div className="is-flex is-justify-content-center">
                        <img src={getImageUrl(item)} alt={item} />
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

export default QPrompt1_1;
