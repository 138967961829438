/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import ConstantStrings from "../../constants/ConstantStrings";
import "./GapFill.style.css";
const MultipleGapFill = ({
  choice,
  answer,
  correctAnswers,
  onChange,
  checkingState,
  placeholder,
  index,
  type,
}) => {
  var gapStyle = {
    width: "10rem",
    height: "3rem",
    padding: "1rem",
    borderRadius: "0.5rem",
    border: "0.0625rem solid",
    // textAlign: "center",
    fontSize: "1.125rem",
    lineHeight: "normal",
    fontStyle: "normal",
  };
  var focusStyle = {
    ...gapStyle,
    border: "0.125rem solid #572CD1",
  };
  var imgStyle = {
    width: "3rem",
    height: "3rem",
  };
  if (type === "TEXT" || type === "SINGLE") {
    imgStyle = { fontSize: "1.125rem", lineHeight: "normal" };
  }
  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    width: "1.5rem",
    height: "1.5rem",
    top: "0.75rem",
    right: "-0.75rem",
  };
  const [focus, setFocus] = useState(false);
  const handleChangeValue = (index) => (e) => {
    e.preventDefault();
    const regex =
      type === "FLOAT" ? /^0?(?!0)[0-9]*(\.[0-9]*)?$/ : /^0?(?!0)[0-9]*$/;
    var value = e.target.value;
    const maxLength = 10;
    if (value.length > maxLength) {
      return;
    }
    if (regex.test(value) === true || !value) {
      onChange(e);
    }
  };
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      event.preventDefault();
    }
  };
  var imageUrl = ConstantStrings.IMAGE_URL + encodeURIComponent(choice);
  imageUrl = imageUrl.replace(/\(/g, "%28").replace(/\)/g, "%29");
  const IconComponent = ({ index }) => {
    return (
      <>
        {checkingState && correctAnswers !== undefined ? (
          correctAnswers === true ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <img
              src="/images/common/redX.svg"
              style={iconStyle}
              alt="loading"
            />
          )
        ) : (
          <div
            className="icon"
            style={{ ...iconStyle, width: "0.75rem", height: "0.75rem" }}
          ></div>
        )}
      </>
    );
  };

  return (
    <div style={{}}>
      <div
        className={`${
          type === "TEXT" ? "is-justify-content-space-between" : ""
        } column is-flex is-flex-direction-row is-relative is-narrow p-2 is-relative is-align-items-center`}
        style={{ gap: "1rem" }}
      >
        {choice && choice.length > 0 && (
          <>
            {type === "IMAGE" && (
              <img src={imageUrl} style={imgStyle} alt={choice} />
            )}
            {(type === "SINGLE" || type === "TEXT") && (
              <p
                className="has-text-kurio-main-color-black has-text-centered has-text-weight-bold"
                style={imgStyle}
              >
                {choice}
              </p>
            )}
          </>
        )}
        <div className="is-relative" style={{}}>
          <input
            style={focus && !checkingState ? focusStyle : gapStyle}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            className={`${
              correctAnswers === true
                ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                : correctAnswers === false
                ? "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                : focus || answer
                ? "has-background-kurio-purple-light-3 has-text-kurio-main-color-black is-kurio-main-color-black"
                : ""
            } input is-shadowless has-text-kurio-main-color-black has-text-weight-bold`}
            type="text"
            placeholder={placeholder ? placeholder : "Trả lời..."}
            value={answer || ""}
            onChange={handleChangeValue(index)}
            disabled={checkingState}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onKeyDown={handleKeyDown}
          />
          <IconComponent index={index} />
        </div>
        {/* {choice && choice.length > 0 && (
          <>
            {type === "TEXT" && (
              <p
                className="has-text-kurio-main-color-black has-text-centered has-text-weight-bold"
                style={imgStyle}
              >
                {choice}
              </p>
            )}
          </>
        )} */}
      </div>
    </div>
  );
};

export default MultipleGapFill;
