import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "../config/auth";

const initialState = {
  listUserInfo: null,
  error:null
};

export const getGradeUserInfo = createAsyncThunk(
  "grades/getGradeUserInfo",
  async (thunkAPI) => {
    try {
      const { result } = await getUserInfo();
      localStorage.setItem("examName", result.examName);
      return result;
    } catch (error) {
      if (error?.response.status === 409) {
        return { error: 409 };
      } else {
        return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
      }
    }
  }
);

export const gradesSlice = createSlice({
  name: "grades",
  initialState,
  reducers: {
    clearStateGrade: (state) => {
      state.listUserInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGradeUserInfo.fulfilled, (state, action) => {
      if (action.payload?.error === 409) {
        state.error = 409
      } else {
        state.listUserInfo = action.payload;
        state.gradeId = action.payload.gradeId;
        state.gradeName = action.payload.gradeName;
      }
    });
  },
});
export const { clearStateGrade } = gradesSlice.actions;
export default gradesSlice.reducer;
