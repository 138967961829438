import React, { useEffect, useState } from "react";

const PaginationBigButton = ({
  disabled,
  isClick,
  onClick,
  children,
  isAnswered,
  isSection,
  isCorrect,
}) => {
  var style = {
    width: "2.5rem",
    height: "2.5rem",
    border: "none",
    borderRadius: "0.5rem",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
  };
  const [defaultState, setDefaultState] = useState(
    disabled ? false : isClick ? false : true
  );
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  if (hoveredState) {
    if (isSection) {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-section-hovered.svg')",
        filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
      };
    } else if (!isAnswered && isCorrect === undefined) {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-private-default.svg')",
      };
    } else if (!isAnswered && isCorrect !== undefined) {
      if (isCorrect) {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-unit-quizz-success-hover.svg')",
          filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
        };
      } else {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-unit-quiz-failed-hover.svg')",
          filter: "drop-shadow(0rem 0.375rem 1rem rgba(10, 42, 102, 0.25))",
        };
      }
    } else {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-private-finished.svg')",
      };
    }
  }

  if (defaultState) {
    if (isSection) {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-section-default.svg')",
      };
    } else if (!isAnswered && isCorrect === undefined) {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-private-default.svg')",
      };
    } else if (!isAnswered && isCorrect !== undefined) {
      if (isCorrect) {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-unit-quiz-success-default.svg')",
        };
      } else {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-unit-quiz-failed-default.svg')",
        };
      }
    } else {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-private-finished.svg')",
      };
    }
  }

  if (clickedState || isClick) {
    if (isSection) {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-big-clicked-bg.svg')",
      };
    } else if (!isAnswered && isCorrect === undefined) {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-private-current.svg')",
      };
    } else if (!isAnswered && isCorrect !== undefined) {
      if (isCorrect) {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-unit-quiz-click-success.svg')",
        };
      } else {
        style = {
          ...style,
          backgroundImage:
            "url('/images/button/pagination-unit-quiz-click-failed.svg')",
        };
      }
    } else {
      style = {
        ...style,
        backgroundImage:
          "url('/images/button/pagination-button-private-finished.svg')",
      };
    }
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled && !isClick) {
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
    if (!disabled && isClick) {
      // setClickedState(!clickedState);
      // setHoveredState(false);
      // setDefaultState(!clickedState);
      if (onClick) {
        onClick();
      }
    }
  };
  useEffect(() => {
    if (!disabled && isClick !== undefined) {
      setDefaultState(!isClick);
      setClickedState(isClick);
    } else {
      setDefaultState(!disabled);
      setClickedState(!disabled);
    }
  }, [disabled, isClick]);
  return (
    <div
      className="main"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ maxWidth: "2.5rem" }}
    >
      <div
        // objectId={objectId}
        className={`${
          defaultState
            ? `${
                isSection
                  ? "has-text-kurio-main-color-purple"
                  : !isAnswered && isCorrect === undefined
                  ? "is-kurio-main-color-white has-text-kurio-main-color-purple"
                  : !isAnswered && isCorrect !== undefined && isCorrect
                  ? "is-kurio-green-main-accent has-text-kurio-main-color-white"
                  : !isAnswered && isCorrect !== undefined && !isCorrect
                  ? "is-kurio-red-main-accent has-text-kurio-main-color-white"
                  : "is-kurio-main-color-yellow has-text-kurio-main-color-purple"
              }`
            : hoveredState
            ? `${
                isSection
                  ? "has-text-kurio-main-color-purple"
                  : !isAnswered && isCorrect === undefined
                  ? "is-kurio-main-color-white has-text-kurio-main-color-purple"
                  : !isAnswered && isCorrect !== undefined && isCorrect
                  ? "is-kurio-green-light-1 has-text-kurio-main-color-white"
                  : !isAnswered && isCorrect !== undefined && !isCorrect
                  ? "is-kurio-red-light-1 has-text-kurio-main-color-white"
                  : "is-kurio-yellow-light-1 has-text-kurio-main-color-purple"
              }`
            : clickedState || isClick
            ? `${
                isSection
                  ? "has-text-kurio-main-color-white is-kurio-main-color-purple"
                  : !isAnswered && isCorrect === undefined
                  ? "has-text-kurio-main-color-white is-kurio-purple-dark"
                  : !isAnswered && isCorrect !== undefined && isCorrect
                  ? "is-kurio-green-dark has-text-kurio-main-color-white"
                  : !isAnswered && isCorrect !== undefined && !isCorrect
                  ? "is-kurio-red-dark has-text-kurio-main-color-purple"
                  : "is-kurio-yellow-dark has-text-kurio-main-color-purple"
              }`
            : disabled
            ? "is-kurio-gray-light-2 has-text-kurio-gray-main-accent"
            : ""
        }  button main-color has-text-weight-bold is-uppercase has-text-centered`}
        style={style}
        onClick={handleClick}
        type="button"
      >
        {children}
      </div>
    </div>
  );
};

export default PaginationBigButton;
