import "@fontsource/arimo/500.css";
import "@fontsource/arimo/700.css";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Routes } from "react-router-dom";
import ConstantStrings from "./constants/ConstantStrings";
import HomePagePrivate from "./layout-components/HomePagePrivate";
import RulePage from "./layout-components/RulePage";
import Signin from "./layout-components/auth/signin/Signin";
import Error from "./layout-components/error/Error";
import ErrorServerTime from "./layout-components/error/ErrorServerTime";
import ExamQuestionsMainWrapper from "./layout-components/exam-questions/ExamQuestionsMainWrapper";
import MenuMainWrapper from "./layout-components/menu-bar/MenuMainWrapper";
import PreviewExam from "./layout-components/preview/PreviewExam";
import PreviewQuestion from "./layout-components/preview/PreviewQuestion";
import PrivateRouter from "./templates/helper/PrivateRouter";
import { cacheWidthScreen } from "./templates/helper/widthScreen";

function App() {
  const [widthDiv, setWidthDiv] = useState(57.5);
  const divRef = useRef(null);
  if (widthDiv) {
    cacheWidthScreen(widthDiv);
  }
  useEffect(() => {
    const setWidth = () => {
      setWidthDiv(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    };
    if (divRef.current.clientWidth / ConstantStrings.BASE_REM !== 57.5) {
      setWidthDiv(divRef.current.clientWidth / ConstantStrings.BASE_REM);
    }
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [widthDiv]);
  return (
    <div
      className="App  notranslate" //class notranslate disables google translate
      ref={divRef}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "url('/images/menu/bg-image.svg')",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      {widthDiv >= 57.5 && (
        <Routes>
          <Route path="/" element={<PrivateRouter page={"BASE_AUTH"}><MenuMainWrapper widthDiv={widthDiv} /></PrivateRouter>}>
            <Route index element={<HomePagePrivate widthDiv={widthDiv} /> } />
            <Route path="rules" element={<RulePage widthDiv={widthDiv} />} />
            {/* <Route path="search" element={<SearchPage />} /> */}
            <Route path="error" element={<Error />} />
            <Route path="error-server-time" element={<ErrorServerTime />} />
          </Route>
          <Route
            path="signin"
            element={
              <PrivateRouter page={"LOGIN"}>
                <Signin />
              </PrivateRouter>
            }
          />

          <Route
            path="/exam-questions"
            element={
                <DndProvider backend={HTML5Backend}>
                  <ExamQuestionsMainWrapper />
                </DndProvider>
            }
          />
          <Route path="/preview-question" element={<PreviewQuestion />} />
          <Route path="/preview-exam" element={<PreviewExam />} />
        </Routes>
      )}
      {widthDiv < 57.5 && (
        <div className="m-auto has-text-centered has-text-kurio-main-color-black is-size-2 has-text-weight-bold">
          <img src="/images/menu/IEG-logo.png" alt="" />
          <p>
            Xin lỗi, sản phẩm của chúng tôi hiện chỉ hỗ trợ màn hình có kích
            thước lớn hơn hoặc bằng 1024px để hoạt động tốt nhất. Chúng tôi xin
            lỗi vì sự bất tiện này. Vui lòng sử dụng thiết bị với màn hình lớn
            hơn hoặc điều chỉnh cài đặt màn hình của bạn để tiếp tục sử dụng sản
            phẩm của chúng tôi. Xin cảm ơn
          </p>
        </div>
      )}
    </div>
  );
}

export default App;
