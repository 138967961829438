/* eslint-disable default-case */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ButtonQuizExam from "../../../components/button/button-quiz-exam/ButtonQuizExam";
import PaginationBigButton from "../../../components/button/pagination-button/PaginationBigButton";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import {
  clearState,
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../../reducers/examsQuestionSlice";
import { clearStateGrade } from "../../../reducers/gradesSlice";
import "./ExamQuestionLeftWrapper.css";

const ExamQuestionLeftWrapper = ({
  examQuestions,
  selectedIndex,
  handleQuestionChange,
  deadline,
  takeExamsQuestionId,
  idExamAdmin,
  serverTime,
  handleSubmitAnswer,
  isPreview,
  isMockTest,
}) => {
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [showPopupSubmit, setShowPopupSubmit] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [showFinishedQuiz, setShowFinishedQuiz] = useState(
    localStorage.getItem("finishExam")
      ? localStorage.getItem("finishExam")
      : false
  );
  // const [toggle, setToggle] = useState(false);
  const [start, setStart] = useState(false);
  const [changeTime, setChangeTime] = useState(false);
  const [timeDifference, setTimeDifference] = useState(null);
  const [countdownDate, setCountDownDate] = useState(null);
  const [count, setCount] = useState(0);

  // const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const arrayPath = ["/rules", "/exam-questions", "/"];
  // const token = cookies.get("signinUser", { path: "/" });
  // const roles = ["EXAM-MANAGER", "ADMIN"];
  // const decode = decodeToken(token);
  if (new Date(serverTime).getTime() > 0 && timeDifference === null) {
    setTimeDifference(new Date(serverTime).getTime() - new Date().getTime());
  }
  const convertTimeExam = (time) => {
    const utcDate = moment(time);
    utcDate.locale("vi");

    const formattedTime = utcDate.format("H:mm");
    return formattedTime;
  };

  var checkStateAnswered =
    examQuestions && examQuestions.filter((item) => item.isAnswered === false);

  var answeredQuestions = useSelector(selectAnsweredQuestions);

  const checkingState = examQuestions?.every(
    (item) => item.question.showSolution === true
  );

  const clickShowPopupSubmit = async () => {
    setShowPopupSubmit(true);
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    if (result) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
      result.questionsAnswered = result.questionsAnswered.map((item) =>
        item.questionIndex === result.submit.questions[0].questionIndex
          ? result.submit.questions[0]
          : item
      );
      answeredQuestions = result.questionsAnswered;
    }
  };
  const handleBackToHub = () => {
    const listCookies = cookies.getAll();
    localStorage.clear();
    dispatch(clearStateGrade());
    dispatch(clearState());
    Object.keys(listCookies).forEach((cookie) => {
      if (cookie !== "baseAuth") {
        cookies.remove(cookie);
      }
    });
    arrayPath
      .forEach((path) => {
        if (cookies.get("signinUser", { path: path })) {
          cookies.remove("signinUser", { path: path });
        }
      })
      ?.then(() => {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
      })
      .catch((error) => {
        console.error("Error removing cookie:", error);
      });
    navigate("/");
  };
  const handleNavigate = () => {
    dispatch(clearStateGrade());
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  const handleCancel = () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    if (result) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
      result.questionsAnswered = result.questionsAnswered.map((item) =>
        item.questionIndex === result.submit.questions[0].questionIndex
          ? result.submit.questions[0]
          : item
      );
      answeredQuestions = result.questionsAnswered;
      var submit = result.submit;
      handleSubmitAnswer(submit, localStorage.getItem("examId"));
    }

    localStorage.clear();
    navigate("/");
  };

  const removePopup = (type) => {
    setShowPopupCancel(false);
    setShowPopupSubmit(false);
  };

  const handleChange = (val) => {
    handleQuestionChange(val);
    setShowPopupSubmit(false);
  };

  const handleSubmit = () => {
    if (!isPreview) {
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        result.questionsAnswered = result.questionsAnswered.map((item) =>
          item.questionIndex === result.submit.questions[0].questionIndex
            ? result.submit.questions[0]
            : item
        );
        var newQuestionsAnswered = [];
        for (let index = 0; index < result.questionsAnswered.length; index++) {
          const indexQuestion = examQuestions?.findIndex(
            (item) =>
              item.questionIndex ===
              result.questionsAnswered[index].questionIndex
          );
          if (indexQuestion !== -1) {
            const template = examQuestions[indexQuestion].template.question;
            if (
              template === "GapfillFloat" ||
              template === "GapfillText" ||
              template === "EquationMultiple" ||
              template === "EquationSingle" ||
              template === "ShortAnswerFraction" ||
              template === "GapfillImage"
            ) {
              var answeredItem = result.questionsAnswered[index].answer;
              answeredItem = result.questionsAnswered[index].answer?.map(
                (item) =>
                  template === "GapfillFloat" ||
                  template === "ShortAnswerFraction"
                    ? parseFloat(item)
                    : parseInt(item)
              );
              newQuestionsAnswered.push({
                questionId: result.questionsAnswered[index].questionId,
                questionIndex: result.questionsAnswered[index].questionIndex,
                answer: answeredItem,
              });
            } else {
              newQuestionsAnswered.push(result.questionsAnswered[index]);
            }
          }
        }
        answeredQuestions = newQuestionsAnswered;
      } else {
        answeredQuestions = examQuestions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer,
        }));
      }

      var payload = {
        submit: true,
        questions: answeredQuestions,
      };
      takeExamsQuestionId = localStorage.getItem("examId");
      handleSubmitAnswer(payload, takeExamsQuestionId);
      // if (showPopupSubmit) {
      //   setTimeout(() => {
      //   }, 300);
      // }
      // localStorage.setItem("finishExam", true);
      setShowPopupSubmit(false);
      setShowFinishedQuiz(true);
    }
  };
  useEffect(() => {
    if (deadline !== null && !start) {
      const count = Math.floor(
        new Date(deadline).getTime() - new Date(serverTime).getTime()
      );
      if (!isMockTest) {
        setStart(true);
        setTimeLeft(count);
      }
    }
    const countdown = setInterval(() => {
      setChangeTime(true);
      setCount((count) => count + 1);
      // setTimeLeft((timeLeft)=> timeLeft - 60000)
      setCountDownDate(
        new Date().getTime() +
          timeDifference -
          (new Date().getTime() +
            timeDifference -
            new Date(serverTime).getTime()) +
          timeLeft
      );
    }, 60000);
    return () => clearInterval(countdown);
  }, [
    deadline,
    serverTime,
    start,
    timeDifference,
    countdownDate,
    timeLeft,
    count,
    changeTime,
    isMockTest,
  ]);
  const rerender = ({ days, hours, minutes, seconds, completed }) => {
    // console.log("test", (minutes + hours * 60 + seconds )*1000 - timeLeft)
    if (completed) {
      // handleSubmit();
    } else {
      return (
        <>
          <p
            style={{
              marginRight: "0.25rem",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}
          >
            {zeroPad(minutes + hours * 60)}
          </p>
          <div>
            <p style={{ marginRight: "0.25rem" }}> : </p>
          </div>

          <p
            style={{
              textTransform: "uppercase",
              display: "flex",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
            }}
          >
            {zeroPad(seconds)}
          </p>
        </>
      );
    }
  };

  return (
    <>
      {showFinishedQuiz && (
        <div
          className="modal is-active modal-background background-monthly-exam"
          style={{
            opacity: 1,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: "url('/images/exam/bg-finish-exam-private.svg')",
          }}
        >
          <div className="modal__monthly-exam">
            <div
              className="finished-notice is-flex is-flex-direction-column is-align-items-center"
              style={{ width: "40rem", height: "31.5rem" }}
            >
              <div className="is-flex is-flex-gap-4">
                <img
                  src="/images/menu/logo-IMAS.png"
                  alt="logo"
                  style={{ mixBlendMode: "multiply", height: "4rem" }}
                />
                <img src="/images/menu/IEG-logo.png" alt="logo" style={{objectFit: "cover",height:"4rem" }} />
              </div>

              <div
                className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center has-text-centered"
                style={{ width: "40rem" }}
              >
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-4"
                  style={{
                    lineHeight: "normal",
                    width: "35.875rem",
                    color: "#011CAA",
                  }}
                >
                  <p style={{ fontSize: "1.5rem" }}>Vòng tuyển chọn</p>
                  <p
                    style={{ fontSize: "2.5rem" }}
                    className="is-uppercase has-text-weight-bold"
                  >
                    KỲ THI ĐÁNH GIÁ NĂNG LỰC TƯ DUY TOÁN HỌC QUỐC TẾ IMAS<br></br> – VÒNG 1–<br></br>
                    HÌNH THỨC TRỰC TUYẾN
                  </p>
                </div>
              </div>
              <div
                className="has-background-kurio-main-color-white px-5 py-4 is-flex is-flex-gap-4 is-justify-content-center"
                style={{
                  width: "100%",
                  height: "4rem",
                  borderRadius: "0.5rem",
                }}
              >
                <img
                  style={{ width: "2rem", height: "2rem" }}
                  src="/images/exam/icon-firework.svg"
                  alt="icon"
                />
                <p
                  className="has-text-kurio-main-color-purple has-text-weight-bold has-text-centered"
                  style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                >
                  Chúc mừng thí sinh đã hoàn thành bài thi!
                </p>
                <img
                  style={{
                    width: "2rem",
                    height: "2rem",
                    transform: "scaleX(-1)",
                  }}
                  src="/images/exam/icon-firework.svg"
                  alt="icon"
                />
              </div>
            </div>
            <div
              className="back-quiz"
              style={{
                position: "absolute",
                bottom: "0rem",
                right: "0rem",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: "url('/images/button/back-to-home.svg')",
                width: "9.0625rem",
                height: "9.875rem",
              }}
              onClick={handleNavigate}
            ></div>
          </div>
        </div>
      )}
      {showPopupCancel && !checkingState && (
        <div
          onClick={removePopup}
          className="modal is-active modal-background"
          style={{ opacity: 1 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Đang làm bài !
            </h2>
            <p className="document-quiz">
              Bạn đang làm bài và chưa bấm nút nộp bài. Bạn có muốn nộp bài ngay
              bây giờ?
            </p>

            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Tiếp tục làm bài
              </PrimaryButtonPageLevel>
              <PrimaryButtonPageLevel
                isDarkColor={true}
                style={{ height: "4rem" }}
                onClick={handleSubmit}
              >
                Nộp Bài Ngay
              </PrimaryButtonPageLevel>
              <SecondaryButtonLevelPage onClick={handleCancel}>
                Đóng
              </SecondaryButtonLevelPage>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupSubmit && !checkingState && (
        <>
          {isMockTest ? (
            <div
              className="modal is-active "
              style={{ opacity: 1, zIndex: 101 }}
            >
              <div
                className="modal-background"
                onClick={removePopup}
                style={{ opacity: 0.8 }}
              ></div>
              <div
                className="is-flex is-flex-direction-column has-background-kurio-main-color-white is-relative"
                style={{
                  padding: "4rem",
                  borderRadius: "2rem",
                  width: "56.375rem",
                  // height: "30.25rem",
                  gap: "4rem",
                }}
              >
                <div className="has-text-centered is-flex is-flex-direction-column is-align-items-center is-flex-gap-4">
                  <h2
                    className=" has-text-weight-bold"
                    style={{
                      fontSize: "2.25rem",
                      lineHeight: "normal",
                      fontStyle: "normal",
                    }}
                  >
                    ! Lưu ý !
                  </h2>
                  <div
                    className="is-flex is-flex-direction-column has-text-centered is-flex-gap-5"
                    style={{
                      lineHeight: "normal",
                      fontStyle: "normal",
                      fontSize: "1.125rem",
                      width: "35.75rem",
                    }}
                  >
                    <p className="has-text-centered">
                      Đây là kỳ Thi Thử. Khi Thi Thử, ấn nút Nộp bài bạn có thể
                      đăng nhập để làm lại. Vào ngày Thi Thật (31/08/2024), ấn
                      nút Nộp bài bạn sẽ không được làm lại
                    </p>
                  </div>
                </div>

                <div
                  className="is-flex is-flex-direction-row"
                  style={{ gap: "1rem", margin: "0 auto" }}
                >
                  <SecondaryButtonLevelPage onClick={removePopup}>
                    Tiếp Tục Làm Bài
                  </SecondaryButtonLevelPage>
                  <PrimaryButtonPageLevel
                    onClick={handleBackToHub}
                    style={{ height: "4rem" }}
                  >
                    Thoát
                  </PrimaryButtonPageLevel>
                </div>

                <div
                  onClick={removePopup}
                  className=""
                  style={{
                    position: "absolute",
                    top: "-8%",
                    left: "-4%",
                    zIndex: 9999,
                    background: "transparent",
                    border: "none",
                    width: "5rem",
                    fontSize: "2rem",
                  }}
                >
                  <PopupCloseButton />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="modal is-active "
              style={{ opacity: 1, zIndex: 101 }}
            >
              <div
                className="modal-background"
                onClick={removePopup}
                style={{ opacity: 0.8 }}
              ></div>
              <div
                className="is-flex is-flex-direction-column has-background-kurio-main-color-white is-relative"
                style={{
                  padding: "4rem",
                  borderRadius: "2rem",
                  width: "56.375rem",
                  // height: "30.25rem",
                  gap: "4rem",
                }}
              >
                <div
                  className="is-flex is-flex-direction-row is-align-items-center"
                  style={{ gap: "5rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-flex-gap-6 is-align-items-flex-start"
                    style={{ width: "18.125rem" }}
                  >
                    <p
                      className="has-text-weight-bold"
                      style={{
                        fontSize: "2.25rem",
                        fontStyle: "normal",
                        lineHeight: "normal",
                      }}
                    >
                      ! Nộp bài !
                    </p>
                    <div
                      className="has-text-weight-bold"
                      style={{
                        fontSize: "1.125rem",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    >
                      <p className="has-text-weight-medium">
                        Tổng số câu hỏi: {examQuestions?.length} câu
                      </p>
                      <p>
                        Số câu đã làm:{" "}
                        {examQuestions?.length - checkStateAnswered?.length} /{" "}
                        {examQuestions?.length} câu.
                      </p>
                      <p className="has-text-kurio-red-main-accent">
                        Số câu chưa làm: {checkStateAnswered?.length} /{" "}
                        {examQuestions?.length} câu.
                      </p>
                      {/* <p>Bạn muốn tiếp tục làm bài?</p> */}
                    </div>
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-flex-gap-5"
                    style={{ width: "25.25rem" }}
                  >
                    <div className="is-flex is-justify-content-space-between is-align-items-center">
                      <p style={{ fontSize: "1rem" }}>
                        Bấm vào câu bạn muốn đến
                      </p>
                      {/* {examQuestions?.length > 32 && (
                        <div
                          className="is-uppercase has-text-weight-bold has-text-kurio-main-color-purple px-4 py-2 is-flex is-flex-gap-4 is-align-items-center is-clickable "
                          onClick={() => {
                            handleChangePage(page === 1 ? 2 : 1);
                            localStorage.setItem("page", page === 1 ? 2 : 1);
                          }}
                          style={{
                            background: "#F0EDF9",
                            borderRadius: "1rem",
                            fontSize: "0.75rem",
                            width: page === 1 ? "8.625rem" : "9.8125rem",
                          }}
                        >
                          {page === 1 && (
                            <>
                              <p>Trang sau</p>
                              <img
                                src="/images/button/icon-arrow.svg"
                                alt="icon"
                              />
                            </>
                          )}
                          {page === 2 && (
                            <>
                              <img
                                style={{ transform: "scaleX(-1)" }}
                                src="/images/button/icon-arrow.svg"
                                alt="icon"
                              />
                              <p>Trang trước</p>
                            </>
                          )}
                        </div>
                      )} */}
                    </div>

                    <div
                      className="columns m-0 is-multiline question-quiz"
                      style={{
                        gap: "0.65rem",
                        maxHeight: "12.25rem",
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {examQuestions &&
                        examQuestions.map((question, index) => {
                          return (
                            <div
                              className={`column is-narrow m-0 p-0`}
                              key={index}
                            >
                              <PaginationBigButton
                                onClick={() => handleChange(index)}
                                key={index}
                                isClick={index === selectedIndex ? true : false}
                                id={question.questionId}
                                value={question.questionIndex}
                                isAnswered={question.isAnswered}
                                isCorrect={question.isCorrect}
                              >
                                {question.questionIndex + 1}
                              </PaginationBigButton>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div
                  className="is-flex is-flex-direction-row"
                  style={{ gap: "1rem", margin: "0 auto" }}
                >
                  <SecondaryButtonLevelPage onClick={removePopup}>
                    Tiếp Tục Làm Bài
                  </SecondaryButtonLevelPage>
                  <PrimaryButtonPageLevel
                    onClick={handleSubmit}
                    style={{ height: "4rem" }}
                  >
                    Xác Nhận Nộp bài
                  </PrimaryButtonPageLevel>
                </div>

                <div
                  onClick={removePopup}
                  className=""
                  style={{
                    position: "absolute",
                    top: "-8%",
                    left: "-4%",
                    zIndex: 9999,
                    background: "transparent",
                    border: "none",
                    width: "5rem",
                    fontSize: "2rem",
                  }}
                >
                  <PopupCloseButton />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {deadline !== null && (
        <div
          className="is-clickable show-time-end"
          style={{
            padding: "0.75rem 1rem",
            margin: "0 auto",
          }}
        >
          <div
            className="has-background-kurio-purple-light-3 p-2 time-end"
            style={{
              position: "absolute",
              borderRadius: "0.25rem",
              // width: "100%",
              top: "4rem",
            }}
          >
            <p>
              Giờ kết thúc:{" "}
              <span className=" is-capitalized">
                {convertTimeExam(deadline)}
              </span>
            </p>
          </div>
          {isMockTest && (
            <p
              className="has-text-weight-bold has-background-kurio-purple-light-3 p-2"
              style={{ fontSize: "1.25rem", borderRadius: "1rem" }}
            >
              Kết thúc 18:00 11/12
            </p>
          )}
          {!isMockTest && (
            <div
              className="is-flex is-relative has-background-kurio-purple-light-3 is-align-items-center is-flex-gap-6 has-text-weight-bold"
              style={{
                gap: "0.75rem",
                width: "14.125rem",
                borderRadius: "0.5rem",
                height: "3rem",
                // padding: "0.75rem",
              }}
            >
              <div
                className="is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-white has-text-weight-bold px-2 has-background-kurio-purple-dark has-text-centered"
                style={{
                  fontSize: "0.75rem",
                  width: "5.5rem",
                  borderRadius: "0.5rem",
                  height: "100%",
                  lineHeight: "normal",
                }}
              >
                Thời gian <br></br> còn lại
              </div>
              {start && (
                <div className="is-flex has-text-kurio-main-color-purple is-justify-content-center">
                  <Countdown
                    // onComplete={handleSubmit}
                    date={
                      changeTime && countdownDate !== null
                        ? countdownDate
                        : new Date(serverTime).getTime() + timeLeft
                    }
                    renderer={rerender}
                  />
                </div>
              )}
              {isMockTest && (
                <p style={{ fontSize: "0.75rem" }}>Kết thúc 18:00 11/12</p>
              )}
            </div>
          )}
        </div>
      )}

      <div
        className="is-flex is-align-items-center is-flex-direction-column"
        style={{ height: "100%", margin: "0 auto", maxWidth: "16rem" }}
      >
        <div
          className="has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center"
          style={{
            padding: "2.5rem 1.5rem",
            height: "calc(100% - 6rem)",
            width: "100%",
          }}
        >
          <div
            className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center has-text-kurio-main-color-black has-text-weight-bold"
            style={{ gap: "1.25rem", width: "100%" }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.375rem",
              }}
            >
              Danh sách<br></br>
              câu hỏi
            </p>
            {examQuestions?.length <= 14 && examQuestions?.length >= 1 && (
              <PaginationBigButton
                onClick={() => handleChange(-1)}
                isClick={-1 === selectedIndex ? true : false}
                value={-1}
              >
                ?
              </PaginationBigButton>
            )}
          </div>
          <div
            className="columns m-0 is-multiline question-quiz is-align-content-flex-start"
            style={{
              gap: "0.5rem",
              overflow: "auto",
              maxHeight: "calc(100% - 8.5rem)",
              width: "12.5rem",
              overflowX: "hidden",
            }}
          >
            {examQuestions &&
              examQuestions.map((question, index) => {
                return (
                  <div className={` column is-narrow m-0 p-0`} key={index}>
                    <PaginationBigButton
                      onClick={() => handleChange(index)}
                      isClick={index === selectedIndex ? true : false}
                      id={question.questionId}
                      value={question.questionIndex}
                      isAnswered={question.isAnswered}
                      isCorrect={question.isCorrect}
                    >
                      {question.questionIndex + 1}
                    </PaginationBigButton>
                  </div>
                );
              })}
          </div>
          {/* {examQuestions?.length > 32 && (
            <div
              className="is-uppercase has-text-weight-bold has-text-kurio-main-color-purple mx-auto px-4 py-2 is-flex is-flex-gap-4 is-align-items-center is-clickable "
              onClick={() => {
                handleChangePage(page === 1 ? 2 : 1);
                localStorage.setItem("page", page === 1 ? 2 : 1);
              }}
              style={{
                background: "#F0EDF9",
                borderRadius: "1rem",
                fontSize: "0.75rem",
                width: page === 1 ? "8.625rem" : "9.8125rem",
              }}
            >
              {page === 1 && (
                <>
                  <p>Trang sau</p>
                  <img src="/images/button/icon-arrow.svg" alt="icon" />
                </>
              )}
              {page === 2 && (
                <>
                  <img
                    style={{ transform: "scaleX(-1)" }}
                    src="/images/button/icon-arrow.svg"
                    alt="icon"
                  />
                  <p>Trang trước</p>
                </>
              )}
            </div>
          )} */}
        </div>
        <div
          style={{
            height: "5rem",
            width: "14rem",
            padding: "0.75rem 1rem",
            margin: "0 center",
            position: "fixed",
            bottom: "0",
          }}
        >
          <ButtonQuizExam
            onClick={clickShowPopupSubmit}
            isClick={false}
            style={{
              maxWidth: "100%",
              width: "14rem",
              padding: "1rem",
              borderRadius: "1rem",
              height: "3.5rem",
            }}
          >
            Nộp bài
          </ButtonQuizExam>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    idExamAdmin: state.examsQuestion.idExamAdmin,
    isMockTest: state.examsQuestion.isMockTest,
    serverTime: state.examsQuestion.serverTime,
    deadline: state.examsQuestion.checkExamTime,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    examQuestions: state.examsQuestion.questions,
  };
}

export default connect(mapStateToProps)(ExamQuestionLeftWrapper);
