import React from "react";

const Footer = ({ widthDiv }) => {
  return (
    <div
      className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between m-auto has-text-kurio-main-color-black"
      style={{
        background: "#EBF1F3",
        width: widthDiv >= 70 ? "58.61813rem" : "40rem",
        height: widthDiv >= 70 ? "7.5rem" : "11.75rem",
        borderRadius: "2rem 2rem 0 0",
        padding: widthDiv >= 70 ? "0 2.5rem" : "2rem 2.5rem",
        gap: widthDiv >= 70 ? "2.5rem" : "auto",
        // position:"absolute",
        // bottom:"0",
        // left:`${(widthDiv - 50)/2}rem`
      }}
    >
      <div
        className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between is-flex-wrap-wrap"
        style={{ gap: widthDiv >= 70 ? "2.5rem" : "1rem" }}
      >
        <div className="is-flex is-flex-gap-4">
          <img
            src="/images/menu/logo-IMAS.png"
            alt=""
            style={{ mixBlendMode: "multiply", height: "4rem" }}
          />
          <img src="/images/menu/IEG-logo.png" alt="" style={{objectFit: "cover",height:"4rem" }} />
        </div>
        <div
          className="is-flex is-flex-direction-column"
          style={{ fontSize: "0.875rem" }}
        >
          <div className=" is-flex is-align-items-center">
            <p
              className="is-uppercase has-text-weight-bold"
              style={{ width: "5.625rem" }}
            >
              Hotline
            </p>
            <p>0981048228</p>
          </div>
          <div className="is-flex is-align-items-center">
            <p
              className="is-uppercase has-text-weight-bold"
              style={{ width: "5.625rem" }}
            >
              FANPAGE
            </p>
            <p style={{ width: "11.375rem", wordWrap: "break-word" }}>
              facebook.com/imasvietnam
            </p>
          </div>
        </div>
      </div>
      <div
        className="is-flex is-flex-gap-4 is-justify-content-center is-align-items-center has-background-kurio-main-color-black"
        style={{ minWidth: "11.625rem", height: "4rem", borderRadius: "2rem" }}
      >
        <img src="/images/menu/icon-phone.svg" alt="" />
        <p className="is-uppercase has-text-white has-text-weight-bold">
          LIÊN HỆ
        </p>
      </div>
    </div>
  );
};

export default Footer;
