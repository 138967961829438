/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import "./GapFill.style.css";
const GapFill = ({
  choice,
  answer,
  correctAnswers,
  onChange,
  checkingState,
  placeholder,
  index,
  type,
}) => {
  var gapStyle = {
    width: "10rem",
    height: "3rem",
    padding: "1rem",
    borderRadius: "0.5rem",
    border: "0.0625rem solid",
    // textAlign: "center",
    fontSize: "1.125rem",
    lineHeight: "normal",
    fontStyle: "normal",
  };
  if (type === "TEXT" || type === "ESSAY") {
    gapStyle = {
      ...gapStyle,
      width: "100%",
      height: type === "ESSAY" ? "15rem" : "3rem",
    };
  }
  var focusStyle = {
    ...gapStyle,
    border: "0.125rem solid #572CD1",
  };

  var iconStyle = {
    zIndex: 100,
    position: "absolute",
    width: "1.5rem",
    height: "1.5rem",
    top: "1.25rem",
    right: "-0.25rem",
  };
  const [focus, setFocus] = useState(false);
  const handleChangeValue = (index) => (e) => {
    e.preventDefault();
    const regex =
      type === "NUMBER" ? /^0?(?!0)[0-9]*$/ : /^[a-zA-Z0-9.,\/\sÀ-ỹ ]+$/;
    var value = e.target.value;
    const maxLength = 10;
    if (value.length > maxLength) {
      return;
    }

    if (regex.test(value) || !value) {
      onChange(e);
    }
  };
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      event.preventDefault();
    }
  };
  const IconComponent = ({ index }) => {
    return (
      <>
        {checkingState && correctAnswers !== undefined ? (
          correctAnswers === true ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <img
              src="/images/common/redX.svg"
              style={iconStyle}
              alt="loading"
            />
          )
        ) : (
          <div
            className="icon"
            style={{ ...iconStyle, width: "0.75rem", height: "0.75rem" }}
          ></div>
        )}
      </>
    );
  };

  return (
    <div style={type === "ESSAY" || type === "TEXT" ? { width: "100%" } : {}}>
      <div
        className="column is-flex is-flex-direction-row is-relative is-narrow p-2 is-relative"
        style={{ gap: "1rem" }}
      >
        <div
          style={type === "ESSAY" || type === "TEXT" ? { width: "100%" } : {}}
        >
          {type === "ESSAY" ? (
            <textarea
              style={
                (focus || answer) && !checkingState ? focusStyle : gapStyle
              }
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              className={`${
                correctAnswers === true
                  ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                  : correctAnswers === false
                  ? "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                  : focus || answer
                  ? "has-background-kurio-purple-light-3 has-text-kurio-main-color-purple is-kurio-main-color-black"
                  : ""
              } textarea is-shadowless has-text-kurio-main-color-black has-text-weight-medium`}
              type="text"
              placeholder={placeholder ? placeholder : "Trả lời..."}
              value={answer || ""}
              onChange={handleChangeValue(index)}
              disabled={checkingState}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <input
              style={focus && !checkingState ? focusStyle : gapStyle}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              className={`${
                correctAnswers === true
                  ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                  : correctAnswers === false
                  ? "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                  : focus || answer
                  ? "has-background-kurio-purple-light-3 has-text-kurio-main-color-black is-kurio-main-color-black"
                  : ""
              } input is-shadowless has-text-kurio-main-color-black has-text-weight-bold`}
              type="text"
              placeholder={placeholder ? placeholder : "Trả lời..."}
              value={answer || ""}
              onChange={handleChangeValue(index)}
              disabled={checkingState}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onKeyDown={handleKeyDown}
            />
          )}
          <IconComponent index={index} />
        </div>
      </div>
    </div>
  );
};

export default GapFill;
