import { configureStore } from "@reduxjs/toolkit";
import examsQuestionReducer from './examsQuestionSlice';
import gradesReducer from './gradesSlice';

export const store = configureStore({
  reducer: {
    grades: gradesReducer,
    examsQuestion: examsQuestionReducer,
  },
});
