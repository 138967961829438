/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { decodeToken } from "react-jwt";
import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import {
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/examsQuestionSlice";
import ExamQuestionLeftWrapper from "../exam-questions/left/ExamQuestionLeftWrapper";
import ExamQuestionRightWrapper from "../exam-questions/right/ExamQuestionRightWrapper";

const PreviewExamContent = ({
  takeExamsQuestionId,
  examQuestions,
  seenQuestions,
  takeExamIdInfo,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  // const [page, setPage] = useState(
  //   localStorage.getItem("page") ? parseInt(localStorage.getItem("page")) : 1
  // );
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser", { path: "/" });
  const roles = ["EXAM-MANAGER", "ADMIN"];
  const decode = decodeToken(token);
  if (decode?.roles.some((role) => roles.includes(role))) {
    const lastLoginKey = "lastLoginDate";
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key !== lastLoginKey) {
        localStorage.removeItem(key);
      }
    });
    cookies.remove("questionState");
  }
  // const handleChangePage = (page) => {
  //   setPage(page);
  // };
  const handleSubmitAnswer = (payload, id) => {};
  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        // var submit = result.submit
        // dispatch(submitAnswers({submit, token, practiceQuizId:newPracticeId ? newPracticeId : practiceQuizId }));
      }

      setSelectedQuestion(val);
    }
  };
  const widthScreen = 71;

  return (
    <div>
      <div className="columns is-mobile m-0 p-0">
        {examQuestions && (
          <>
            <div
              style={{
                background: "linear-gradient(180deg, #E8E1ED 0%, #D7C5EB 100%)",
                minHeight: "100vh",
                padding: widthScreen >= 70 ? "5rem" : "4rem 3rem 5rem 3rem",
              }}
              className={`${
                widthScreen >= 70 ? "is-four-fifths" : ""
              } column is-relative  is-flex is-flex-direction-column is-align-items-center is-justify-content-center`}
            >
              <ExamQuestionRightWrapper
                question={examQuestions[selectedQuestion]}
                isPreview={true}
                selectedQuestion={selectedQuestion}
                seenQuestions={seenQuestions}
                widthScreen={widthScreen}
                handleQuestionChange={handleQuestionChange}
                handleSubmitAnswer={(payload, id) =>
                  handleSubmitAnswer(payload, id)
                }
                questions={examQuestions}
                takeExamsQuestionId={takeExamsQuestionId}
              />
            </div>
            <div
              className="column is-one-fifth p-0 has-background-kurio-main-color-white"
              style={{
                position: "fixed",
                right: "0",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <ExamQuestionLeftWrapper
                isPreview={true}
                selectedIndex={selectedQuestion}
                handleQuestionChange={handleQuestionChange}
                takeExamsQuestionId={takeExamsQuestionId}
                handleSubmitAnswer={(payload, id) =>
                  handleSubmitAnswer(payload, id)
                }
                // page={page}
                // handleChangePage={(page) => handleChangePage(page)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    takeExamIdInfo: state.examsQuestion?.examInformation?.takeExamId,
    takeExamsQuestionId: state.examsQuestion.idExamAdmin,
    examQuestions: state.examsQuestion.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
  };
}

export default connect(mapStateToProps)(PreviewExamContent);
