import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { decodeToken } from "react-jwt";
import { getExamQuestions, submitExamAnswers } from "../config/examsQuestion";
import { getAQuestion } from "../config/question";

const initialState = {
  checkExamTime: null,
  idExamAdmin: null,
  serverTime: null,
  questions: null,
  seenQuestions: 0,
  recordCount: 0,
  pageCount: 0,
  currentPage: 0,
  pageSize: 0,
  status: "idle",
  error: null,
  isMockTest:null,
  answeredQuestions: [],
};

export const getExamQuestion = createAsyncThunk(
  "examsQuestion/getExamQuestion",
  async ({ takeExamsQuestionId, page, size, token }, thunkAPI) => {
    try {
      const apiPromise = getExamQuestions(
        takeExamsQuestionId,
        page,
        size,
        token
      );
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Request timeout"));
        }, 10000); // Timeout sau 10 giây
      });
      const { result } = await Promise.race([apiPromise, timeoutPromise]);
      var roles = ["EXAM-MANAGER", "ADMIN"];
      var check = decodeToken(token)?.roles.some((role) =>
        roles.includes(role)
      );
      if (check) {
        var listQuestion = await axios.all(
          result.questions.map(async (questionItem) => {
            const { result } = await getAQuestion(
              questionItem.questionId,
              token
            );

            questionItem = {
              ...questionItem,
              explanation: result.explanation,
              prompt: result.prompt,
              question: result.question,
              type: result.type,
            };

            return questionItem;
          })
        );
        result.questions = listQuestion;
      } else {
        result?.answers?.map((answerItem) => {
          var index = result.records.findIndex(
            (record) => record.questionId === answerItem.questionId
          );
          return (result.records[index].answer = answerItem.answer);
        });
      }

      return result;
    } catch (error) {
      if (error?.response.status === 409) {
        return { error: 409 };
      } else {
        return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
      }
    }
  }
);

export const submitExamAnswer = createAsyncThunk(
  "examsQuestion/submitExamAnswer",
  async ({ submit, examAnswerId }, thunkAPI) => {
    try {
      const { result } = await submitExamAnswers(submit, examAnswerId);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.errorMessage);
    }
  }
);

export const examsQuestion = createSlice({
  name: "examsQuestion",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setQuestionState: (state, action) => {
      state.questions[action.payload.index].question.state =
        action.payload.state;
    },

    setQuestionsAnswered: (state, action) => {
      state.answeredQuestions = action.payload;
      for (var i = 0; i < action.payload.length; i++) {
        if (action.payload[i]) {
          state.questions[action.payload[i].questionIndex].answer =
            action.payload[i].answer;
        }
      }
    },

    clearState: (state) => {
      state.questions = null;
      state.createNewPractice = null;
      state.answeredQuestions = [];
      state.newPracticeId = null;
    },

    setQuestionAnswered: (state, action) => {
      state.questions[action.payload.questionIndex].isAnswered =
        action.payload.status;
    },

    setSeenQuestions: (state, action) => {
      state.seenQuestions = action.payload;
    },
    setErrorExam: (state, action) => {
      state.error = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getExamQuestion.pending, (state) => {
        state.status = "loading";
      })

      .addCase(getExamQuestion.fulfilled, (state, action) => {
        if (action.payload?.error === 409) {
          state.error = 409;
        } else {
          state.status = "succeeded";
          state.isMockTest = action.payload.isMockTest
          state.idExamAdmin = action.payload._id;
          state.checkExamTime = action.payload.endDate;
          state.serverTime = action.payload.serverTime;
          state.questions = action.payload.records
            ? action.payload.records
            : action.payload.questions;
          state.correctAnswers = action.payload.correctAnswers;
          state.wrongAnswers = action.payload.wrongAnswers;
          state.skippedQuestions = action.payload.skippedQuestions;
          state.seenQuestions =
            action.payload.seenQuestions === 0
              ? 1
              : action.payload.seenQuestions;
          state.questions[state?.questions?.length - 1].isLast = true;
          state.questions[0].visible = true;
          state.questions.forEach((question, index) => {
            question.questionIndex = index;
            question.isAnswered =
              question?.answer !== null && question?.answer?.length > 0;
          });
          state.questions
            .filter((question, index) => index < state.seenQuestions)
            .forEach((question) => {
              question.done = true;
              question.visible = true;
            });
        }
      })

      .addCase(getExamQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(submitExamAnswer.fulfilled, (state, action) => {
        if (action.payload?.error === 409) {
          state.error = 409;
        } else {
          state.status = "succeeded";
        }
      });
  },
});

export const {
  setStatus,
  setQuestionsAnswered,
  clearState,
  setQuestionAnswered,
  setQuestionState,
  setSeenQuestions,
  setErrorExam,
} = examsQuestion.actions;

export const selectQuestions = (state) => state.examsQuestion.questions;
export const selectAnsweredQuestions = (state) =>
  state.examsQuestion.answeredQuestions;

export const selectStatus = (state) => state.examsQuestion.status;

export default examsQuestion.reducer;
