// a React template for Gapfill type question

import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import MultipleGapFill from "../../components/question/MultipleGapFill";
import TagCheckMultipleResponse from "../../components/question/TagCheckMultipleResponse";
import TagCheckPrivate from "../../components/question/TagCheckPrivate";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/examsQuestionSlice";
import { getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

export const GapFillFloat = memo(function GapFillFloat({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  questions,
  questionId,
  isAnswered,
  isPreview,
  handleSubmitAnswer,
}) {
  const cache = getCachesQuestion(questionId, questionIndex);
  const dispatch = useDispatch();
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = [""];
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation?.images[1]?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const answeredQuestions = useSelector(selectAnsweredQuestions);

  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
  };
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }
  const questionStateExams = JSON.parse(
    localStorage.getItem("questionStateExams")
  );
  var indexInLocal = questionStateExams?.questionsAnswered.findIndex(
    (item) => item.questionIndex === questionIndex
  );

  const [answersItem] = useState(() => {
    return questions
      ?.filter((question) => question.questionIndex === questionIndex)
      .map((item) =>
        item.answer !== null
          ? questionStateExams
            ? indexInLocal !== -1 &&
              questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
              questionStateExams?.questionsAnswered[indexInLocal]?.answer !==
                null
              ? questionStateExams?.questionsAnswered[indexInLocal].answer
              : item.answer
            : item.answer
          : []
      )
      .flat();
  });

  const [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      if (
        questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
        questionStateExams?.questionsAnswered[indexInLocal]?.answer !== null
      ) {
        return questionStateExams?.questionsAnswered[indexInLocal].answer;
      }
      return question.state.answers;
    } else {
      if (answersItem && answersItem.length > 0) {
        return answersItem;
      } else {
        return [];
      }
    }
  });

  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (question.showSolution) {
      let allCorrect = true;
      for (let i = 0; i < questionSolutions.length; i++) {
        if (answersItem[i] !== questionSolutions[i]) {
          allCorrect = false;
          break;
        }
      }

      return allCorrect;
    } else {
      return false;
    }
  });

  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });

  const [isLoading] = useState(
    cache || !checkImage(questionChoices[0]) ? false : true
  );

  const [showAnswer, setShowAnswer] = useState(false);

  // const [gapFillWithImages, setGapFillWithImages] = useState(
  //   questionChoices !== null &&
  //     questionChoices &&
  //     questionChoices.length > 0 &&
  //     questionChoices[0] !== null &&
  //     questionChoices[0].length > 0
  //     ? true
  //     : false
  // );
  const [submitState, setSubmitState] = useState(isAnswered);

  const divRef = useRef(null);
  // if (
  //   questionChoices !== null &&
  //   questionChoices &&
  //   questionChoices.length > 0 &&
  //   questionChoices[0] !== null &&
  //   questionChoices[0].length > 0
  // ) {
  //   cachesQuestion(questionId, questionIndex, 0, maxWidth);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 300);
  // }

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    // if (
    //   questionChoices !== null &&
    //   questionChoices &&
    //   questionChoices.length > 0 &&
    //   questionChoices[0] !== null &&
    //   questionChoices[0].length > 0
    // ) {
    //   setGapFillWithImages(true);
    // }
    if (
      divRef?.current?.clientWidth / ConstantStrings.BASE_REM !== maxWidth &&
      divRef?.current?.clientWidth / ConstantStrings.BASE_REM < 60
    ) {
      setMaxWidth(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWidth]);

  const saveAnswer = (answers) => {
    if (!question.showSolution) {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
        },
      };
      var answer = questionChoices.map((item) => false);

      if (answers.length > 0) {
        answers.map(
          (item, index) => (answer[index] = item === "" ? null : item)
        );
      } else {
        answer = null;
      }

      if (
        answer?.every(
          (item) =>
            item === "" || item === false || item === null || item === undefined
        )
      ) {
        answer = null;
      }
      var result = [...answeredQuestions];
      var newAnswer = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };

      // setSelected(selected);
      if (result.length === 0) {
        result.push(newAnswer);
      } else {
        var flag = true;
        for (
          let question = 0;
          question < answeredQuestions.length;
          question++
        ) {
          if (answeredQuestions[question].questionId === newAnswer.questionId) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (!flag) {
          result.push(newAnswer);
        } else {
          result = answeredQuestions.map((question) =>
            question.questionId === questionId ? newAnswer : question
          );
        }
      }
      var questionsAnswered = result;
      const submitPayload = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      if (submitPayload.answer !== null && submitPayload.answer?.length > 0) {
        submitPayload.answer = submitPayload?.answer.map((item) =>
          item !== "" && item ? parseFloat(item) : item
        );
      }
      const submit = { submit: false, questions: [submitPayload] };
      var questionAnswered = {
        questionIndex: questionIndex,
        status: true,
      };

      if (answer === null) {
        questionAnswered.status = false;
      }
      const value = {
        questionState,
        questionAnswered,
        questionsAnswered,
        submit,
      };
      localStorage.setItem("questionStateExams", JSON.stringify(value));
    }
  };

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    if (newAnswers.length === 0) {
      questionChoices.map((item) => newAnswers.push(""));
    }

    const value = e.target.value;
    if (value !== "") {
      var valueSplit = value.split(".")[1];
      if (!valueSplit || valueSplit?.length <= 2) {
        newAnswers[index] = value;
      }
    } else {
      newAnswers[index] = "";
    }
    saveAnswer(newAnswers);
    setAnswers(newAnswers);
  };
  const handleSubmit = () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    var payload = result.submit;
    if (!isPreview) {
      handleSubmitAnswer(payload, localStorage.getItem("examId"));
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    } else {
      let allCorrect = true;
      for (let i = 0; i < questionSolutions.length; i++) {
        if (payload.questions[0].answer[i] !== questionSolutions[i]) {
          allCorrect = false;
          break;
        }
      }
      setCorrectAnswers(allCorrect);
      setSubmitState(true);
    }
  };
  const handleReset = () => {
    setAnswers([]);
    setSubmitState(false);
    setShowAnswer(false);
    saveAnswer([]);
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    if (questions?.length > 0) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    }
    if (isPreview) {
      setCorrectAnswers(false);
    }
  };
  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const checkValue = answers.some((item) => {
    if (typeof item === "number") {
      const decimalPart = item.toString().split(".")[1];
      return decimalPart !== undefined && decimalPart.length === 0;
    } else if (typeof item === "string" && item.includes(".")) {
      const decimalPart = item.split(".")[1];
      return decimalPart !== undefined && decimalPart.length === 0;
    }
    return false;
  });

  return (
    <>
      <div style={backgroundStyle} ref={divRef} className="">
        <BackgroundImageFromUrl
          style={backgroundStyle}
          objectId={backgroundImage}
        >
          {isLoading && (
            <div className="has-text-kurio-main-color-black has-text-centered">
              Loading
            </div>
          )}
          {!explanationState && (
            <div
              className={`${
                isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column`}
              style={{
                padding: "1.5rem 2rem",
                gap: "1rem",
              }}
            >
              {
                //render questionTexts if its array has length greater than 0
                questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div
                      className="has-text-centered m-auto"
                      style={{ maxWidth: "40rem" }}
                    >
                      {questionTexts}
                    </div>
                  )
              }
              {isPreview && submitState && (
                <div className="is-flex is-align-items-center is-justify-content-center">
                  <TagCheckMultipleResponse check={correctAnswers} />
                </div>
              )}
              {questionChoices.length === 4 ? (
                <>
                  {(10 + 1.5 + 0.5) * 4 + 6 >= maxWidth ? (
                    <div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          padding: "0.5rem",
                        }}
                      >
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[0]}
                          choice={questionChoices[0]}
                        />
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[1]}
                          choice={questionChoices[1]}
                        />
                      </div>
                      <div
                        className="columns is-centered m-0 "
                        style={{
                          padding: "0.5rem",
                        }}
                      >
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[2]}
                          choice={questionChoices[2]}
                        />
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[3]}
                          choice={questionChoices[3]}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="columns is-multiline is-centered m-0 "
                        style={{
                          padding: "0.5rem",
                        }}
                      >
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={0}
                          onChange={handleChange(0)}
                          answer={answers[0]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[0]}
                          choice={questionChoices[0]}
                        />
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={1}
                          onChange={handleChange(1)}
                          answer={answers[1]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[1]}
                          choice={questionChoices[1]}
                        />
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={2}
                          onChange={handleChange(2)}
                          answer={answers[2]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[2]}
                          choice={questionChoices[2]}
                        />
                        <MultipleGapFill
                          type={"FLOAT"}
                          index={3}
                          onChange={handleChange(3)}
                          answer={answers[3]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          // correctAnswers={correctAnswers[3]}
                          choice={questionChoices[3]}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {questionChoices.length === 6 ? (
                    <>
                      {(10 + 1.5 + 0.5 + 0.5) * 3 + 6 <= maxWidth ? (
                        <div>
                          <div
                            className="columns is-centered m-0 "
                            style={{
                              gap: "0.5rem",
                              padding: "0.5rem",
                            }}
                          >
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={0}
                              onChange={handleChange(0)}
                              answer={answers[0]?.toString() || ""}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[0]}
                              choice={questionChoices[0]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={1}
                              onChange={handleChange(1)}
                              answer={answers[1]?.toString() || ""}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[1]}
                              choice={questionChoices[1]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={2}
                              onChange={handleChange(2)}
                              answer={answers[2]?.toString() || ""}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[2]}
                              choice={questionChoices[2]}
                            />
                          </div>
                          <div
                            className="columns is-centered m-0 "
                            style={{
                              gap: "0.5rem",
                              padding: "0.5rem",
                            }}
                          >
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={3}
                              onChange={handleChange(3)}
                              answer={answers[3]?.toString() || ""}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[3]}
                              choice={questionChoices[3]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={4}
                              onChange={handleChange(4)}
                              answer={answers[4]?.toString() || ""}
                              choice={questionChoices[4]}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[4]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={5}
                              onChange={handleChange(5)}
                              answer={answers[5]?.toString() || ""}
                              choice={questionChoices[5]}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[5]}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className="columns is-multiline is-centered m-0 "
                            style={{
                              gap: "0.5rem",
                            }}
                          >
                            {questionChoices.map((choice, index) => {
                              return (
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  key={index}
                                  index={index}
                                  onChange={handleChange(index)}
                                  answer={answers[index]?.toString() || ""}
                                  checkingState={
                                    (isPreview !== undefined
                                      ? isPreview && submitState
                                      : submitState) || showAnswer
                                  }
                                  // correctAnswers={correctAnswers[index]}
                                  choice={questionChoices[index]}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="columns is-multiline is-centered"
                        style={{ gap: "0.5rem" }}
                      >
                        {questionChoices.map((choice, index) => {
                          return (
                            <MultipleGapFill
                              type={"FLOAT"}
                              key={index}
                              index={index}
                              onChange={handleChange(index)}
                              answer={answers[index]?.toString() || ""}
                              checkingState={
                                (isPreview !== undefined
                                  ? isPreview && submitState
                                  : submitState) || showAnswer
                              }
                              // correctAnswers={correctAnswers[index]}
                              choice={questionChoices[index]}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              )}
              {showAnswer && (
                <>
                  <div
                    className=""
                    style={{ borderTop: "4px dashed #8C9DC1" }}
                  ></div>
                  <p
                    className="is-uppercase has-text-kurio-main-color-black has-text-weight-bold has-text-centered my-4"
                    style={{ lineHeight: "normal" }}
                  >
                    đáp án
                  </p>
                  {questionChoices.length === 4 ? (
                    <>
                      {(10 + 1.5 + 0.5) * 4 + 6 >= maxWidth ? (
                        <div>
                          <div
                            className="columns is-centered m-0 "
                            style={{
                              padding: "0.5rem",
                            }}
                          >
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={0}
                              onChange={handleChange(0)}
                              answer={questionSolutions[0]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[0]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={1}
                              onChange={handleChange(1)}
                              answer={questionSolutions[1]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[1]}
                            />
                          </div>
                          <div
                            className="columns is-centered m-0 "
                            style={{
                              padding: "0.5rem",
                            }}
                          >
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={2}
                              onChange={handleChange(2)}
                              answer={questionSolutions[2]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[2]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={3}
                              onChange={handleChange(3)}
                              answer={questionSolutions[3]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[3]}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className="columns is-multiline is-centered m-0 "
                            style={{
                              padding: "0.5rem",
                            }}
                          >
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={0}
                              onChange={handleChange(0)}
                              answer={questionSolutions[0]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[0]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={1}
                              onChange={handleChange(1)}
                              answer={questionSolutions[1]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[1]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={2}
                              onChange={handleChange(2)}
                              answer={questionSolutions[2]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[2]}
                            />
                            <MultipleGapFill
                              type={"FLOAT"}
                              index={3}
                              onChange={handleChange(3)}
                              answer={questionSolutions[3]?.toString() || ""}
                              checkingState={showAnswer}
                              correctAnswers={true}
                              choice={questionChoices[3]}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {questionChoices.length === 6 ? (
                        <>
                          {(10 + 1.5 + 0.5 + 0.5) * 3 + 6 <= maxWidth ? (
                            <div>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              >
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  index={0}
                                  onChange={handleChange(0)}
                                  answer={
                                    questionSolutions[0]?.toString() || ""
                                  }
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                  choice={questionChoices[0]}
                                />
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  index={1}
                                  onChange={handleChange(1)}
                                  answer={
                                    questionSolutions[1]?.toString() || ""
                                  }
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                  choice={questionChoices[1]}
                                />
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  index={2}
                                  onChange={handleChange(2)}
                                  answer={
                                    questionSolutions[2]?.toString() || ""
                                  }
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                  choice={questionChoices[2]}
                                />
                              </div>
                              <div
                                className="columns is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              >
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  index={3}
                                  onChange={handleChange(3)}
                                  answer={
                                    questionSolutions[3]?.toString() || ""
                                  }
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                  choice={questionChoices[3]}
                                />
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  index={4}
                                  onChange={handleChange(4)}
                                  answer={
                                    questionSolutions[4]?.toString() || ""
                                  }
                                  choice={questionChoices[4]}
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                />
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  index={5}
                                  onChange={handleChange(5)}
                                  answer={
                                    questionSolutions[5]?.toString() || ""
                                  }
                                  choice={questionChoices[5]}
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div
                                className="columns is-multiline is-centered m-0 "
                                style={{
                                  gap: "0.5rem",
                                }}
                              >
                                {questionChoices.map((choice, index) => {
                                  return (
                                    <MultipleGapFill
                                      type={"FLOAT"}
                                      key={index}
                                      index={index}
                                      onChange={handleChange(index)}
                                      answer={
                                        questionSolutions[index]?.toString() ||
                                        ""
                                      }
                                      checkingState={showAnswer}
                                      correctAnswers={true}
                                      choice={questionChoices[index]}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            className="columns is-multiline is-centered"
                            style={{ gap: "0.5rem" }}
                          >
                            {questionChoices.map((choice, index) => {
                              return (
                                <MultipleGapFill
                                  type={"FLOAT"}
                                  key={index}
                                  index={index}
                                  onChange={handleChange(index)}
                                  answer={
                                    questionSolutions[index]?.toString() || ""
                                  }
                                  checkingState={showAnswer}
                                  correctAnswers={true}
                                  choice={questionChoices[index]}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {question.showSolution && (
            <>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center"
                style={{
                  gap: "2rem",
                }}
              >
                <ImageFromUrl
                  style={{ padding: "0rem 2rem" }}
                  objectId="admin/frontend-images/exam/Vector 2.png"
                />

                <h1
                  className="has-text-kurio-main-color-black"
                  style={{
                    fontWeight: 700,
                    fontSize: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  Hướng dẫn
                </h1>
              </div>
              <div
                className="is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "1.5rem" }}
              >
                {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                {question.showSolution && explanationState === false && (
                  <>
                    {(explanation.texts !== null ||
                      explanation.images !== null) &&
                    (explanation.texts?.length > 0 ||
                      explanation.images.length > 0) ? (
                      <div
                        style={{
                          gap: "1.5rem",
                          fontSize: "1.25rem",
                          padding: "1rem 3rem",
                          width: "100%",
                        }}
                        className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                      >
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 0 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[0]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 0 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[0]} />
                              </div>
                            </div>
                          )}
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 1 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[1]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 1 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[1]} />
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div
            className="has-background-kurio-main-color-light-gray is-flex is-align-items-center is-justify-content-center is-flex-gap-5"
            style={{
              padding: "0 2rem 2rem 2rem",
              borderRadius: "0 0 1rem 1rem",
            }}
          >
            {submitState && (
              <>
                <TagCheckPrivate />
                <PrimaryButtonQuestion onClick={handleReset}>
                  {ConstantStrings.RETRY}
                </PrimaryButtonQuestion>
              </>
            )}

            {!submitState && (
              <PrimaryButtonQuestion
                onClick={handleSubmit}
                disabled={
                  answers.some(
                    (item) => item === undefined || item === "" || item === null
                  ) ||
                  checkValue ||
                  answers.length < questionChoices?.length
                }
              >
                {ConstantStrings.SUBMIT}
              </PrimaryButtonQuestion>
            )}
            {isPreview && (
              <>
                {showAnswer && (
                  <PrimaryButtonQuestion onClick={handleShowAnswer}>
                    Ẩn đáp án
                  </PrimaryButtonQuestion>
                )}
                {!showAnswer && (
                  <PrimaryButtonQuestion onClick={handleShowAnswer}>
                    Hiện đáp án
                  </PrimaryButtonQuestion>
                )}
              </>
            )}
          </div>
        </BackgroundImageFromUrl>
      </div>
    </>
  );
});

export default GapFillFloat;
