import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { checkImage } from "../../constants/checkImage";
import { getImageUrl } from "../../templates/helper/parseImage";

const BackgroundImageFromUrl = memo(
  ({ objectId, style, className, children, onClick, type }) => {
    const imageUrl = checkImage(objectId) ? getImageUrl(objectId) : ""
    const navigate = useNavigate();

    // const [isLoading, setIsLoading] = useState(true);
    // useEffect(() => {
    //   if (!window.location.pathname.includes("signin") && objectId!== null && objectId && objectId.length > 0) {
    //     var key = objectId;
    //     //make key an array
    //     key = [key];
    //     parseImage(key).then((res) => {
    //       const imageUrls = res.map((item) => item.url);
    //       setImageUrl(imageUrls[0]);
    //       // setIsLoading(false);
    //     });
    //   }
    // }, [objectId]);
    const handleClick = (e) => {
      if (e?.target?.className === "hub__exam-solution" || e?.target?.className === "card-monthly-exam") {
        navigate("/activities/monthly-exams");
      }

      if (onClick) {
        onClick();
      }
    };
    return (
      <>
        {type === "button" ? (
          <button
            className={className}
            onClick={handleClick}
            style={{ ...style, backgroundImage: `url(${objectId!== null && objectId && objectId.length > 0 ?imageUrl : ""})` }}
          >
            {children}
          </button>
        ) : (
          <div
            className={className}
            onClick={handleClick}
            style={{ ...style, backgroundImage: `url(${objectId!== null && objectId && objectId.length > 0 ?imageUrl : ""})` }}
          >
            {children}
          </div>
        )}
      </>
    );
  }
);

export default BackgroundImageFromUrl;
