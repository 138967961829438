import axios from "axios";
import Cookies from "universal-cookie";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_BASE_URL
      : process.env.REACT_APP_BASE_URL,
});
//
const urlArray = [
  "create-register",
  "exam/v1/admin/question/get-a-question",
  "exam/v1/admin/exam/get-an-exam",
  "confirm-register-otp",
  "create-reset-password-phone",
  "create-reset-password-email",
  "confirm-reset-password-phone-otp",
  "reset-password-phone",
  "check-reset-password-email-request",
  "reset-password-email",
  "token",
];
// let isDailyLoginInProgress = false; // Biến kiểm tra xem API dailyLogin đã được gọi hay chưa
// let dailyLoginPromise = null;
const cookies = new Cookies();
const arrayPath = ["/rules", "/exam-questions", "/"];
const apiCallTimestamps = {};
// Hàm kiểm tra xem một API có thể được gọi hay không dựa trên thời gian gọi gần đây
function canCallApi(url) {
  const now = Date.now();
  const lastCallTimestamp = apiCallTimestamps[url];
  if (!lastCallTimestamp || now - lastCallTimestamp >= 300) {
    // Cho phép gọi API nếu chưa có lần gọi nào hoặc đã qua 300ms từ lần gọi trước đó
    return true;
  }
  return false;
}

instance.interceptors.request.use(
  async function (request) {
    const token =
      cookies.get("signinUser", { path: "/" }) || cookies.get("register");
    // const lastLoginDate = localStorage.getItem("lastLoginDate");

    var checkUrl = false;
    for (let index = 0; index < urlArray.length; index++) {
      if (request.url.includes(urlArray[index])) {
        checkUrl = true;
        break;
      }
    }
    const url = request.url;

    // Kiểm tra xem API có thể được gọi hay không
    if (canCallApi(url)) {
      // Nếu có thể gọi API, ghi lại thời gian gọi gần đây
      apiCallTimestamps[url] = Date.now();
    } else {
      // Nếu không thể gọi API, hủy request
      return Promise.reject({ canceled: true });
    }

    if (!checkUrl) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response?.status === 303) {
      return error.response.data;
    } else if (error.response?.status === 401) {
      if (!window.location.pathname.includes("preview")) {
        arrayPath
          .forEach((path) => {
            if (cookies.get("signinUser", { path: path })) {
              cookies.remove("signinUser", { path: path });
            }
          })
          ?.then(() => {
            window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
          })
          .catch((error) => {
            console.error("Error removing cookie:", error);
          });
        const check = arrayPath.every((path) =>
          cookies.get("signinUser", { path: path })
        );
        if (!check) {
          window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
        }
      }
    } else {
      if (error.response?.status === 401 || error.response?.status === 403) {
        arrayPath
          .forEach((path) => {
            if (cookies.get("signinUser", { path: path })) {
              cookies.remove("signinUser", { path: path });
            }
          })
          ?.then(() => {
            window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
          })
          .catch((error) => {
            console.error("Error removing cookie:", error);
          });
        const check = arrayPath.every((path) =>
          cookies.get("signinUser", { path: path })
        );
        if (!check) {
          window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
        }
      } else if (
        !window.location.pathname.includes("error") &&
        !window.location.pathname.includes("signin")
      ) {
        // window.location.href = window.location.origin + "/error";
      }
      return Promise.reject(error);
    }
  }
);

export default instance;
