/* eslint-disable react-hooks/exhaustive-deps */
import "moment/locale/vi";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ShowPopupError from "../../components/ShowError409";
import PrimaryButtonPrivatePageLevel from "../../components/button/primary-button/PrimaryButtonLevelPagePrivate";
import SecondaryButtonLevelPage from "../../components/button/secondary-button/SecondaryButtonLevelPage";
import { clearStateGrade, getGradeUserInfo } from "../../reducers/gradesSlice";
import "./MenuTopBar.styles.css";

const MenuTopBar = ({
  listUserInfo,
  listGradeUnit,
  widthDiv,
  handleSetPathname,
  handleStartExam,
  error,
}) => {
  const [showAccMenu, setShowAccMenu] = useState(false);
  // const [hoverAccMenu, setHoverAccMenu] = useState(false);
  // const [defaultAccMenu, setDefaultAccMenu] = useState(true);
  // const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  // const [showPopupTimeUp, setShowPopupTimeUp] = useState(false);
  // const [currentDateTime, setCurrentDateTime] = useState(null);
  // const [changeTime, setChangeTime] = useState(false);
  // const [timeDifference, setTimeDifference] = useState(null);
  // const [countdownDate, setCountDownDate] = useState(null);
  // const [start, setStart] = useState(false);
  // const [count, setCount] = useState(0);
  // const [timeLeft, setTimeLeft] = useState(null);
  const cookies = new Cookies();
  const location = useLocation();
  const token = cookies.get("signinUser", { path: "/" });
  const userName = decodeToken(token)?.sub;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arrayPath = ["/rules", "/exam-questions", "/"];
  // const targetDateTime = moment("2023-12-11T18:01:00");
  // if (
  //   new Date(listUserInfo?.serverTime).getTime() > 0 &&
  //   timeDifference === null
  // ) {
  //   setTimeDifference(
  //     new Date(listUserInfo?.serverTime).getTime() - new Date().getTime()
  //   );
  // }
  // const onHandleClick = () => {
  //   setShowAccMenu(!showAccMenu);
  //   setDefaultAccMenu(false);
  //   setHoverAccMenu(false);
  // };
  const currentStyle = {
    padding: "0.5rem 1rem",
    borderRadius: "1.5rem",
    borderBottom: "1px solid #0A2A66",
    background: "#D1E5EE",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
  };
  const convertTimeExam = (time) => {
    const utcDate = moment(time);
    utcDate.locale("vi");

    const formattedTime = utcDate.format("H:mm | dddd, DD/MM/YYYY");
    return formattedTime;
  };
  const handleClickExam = () => {
    if (
      listUserInfo?.takeExamStatus !== 2
      //  &&
      // timeLeft >= 0 &&
      // new Date(listUserInfo?.examStartDate).getTime() <
      //   new Date(listUserInfo?.serverTime).getTime()
    ) {
      if (localStorage.getItem("page")) {
        localStorage.removeItem("page");
      }
      if (localStorage.getItem("questionStateExams")) {
        localStorage.removeItem("questionStateExams");
      }
      localStorage.setItem("examId", listUserInfo?.examId);
      handleStartExam(token);
    }
  };

  const handleLogout = () => {
    if (!showPopup) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
      const listCookies = cookies.getAll();
      localStorage.clear();
      dispatch(clearStateGrade());
      Object.keys(listCookies).forEach((cookie) => {
        if (cookie !== "baseAuth") {
          cookies.remove(cookie);
        }
      });
      arrayPath
        .forEach((path) => {
          if (cookies.get("signinUser", { path: path })) {
            cookies.remove("signinUser", { path: path });
          }
        })
        ?.then(() => {
          window.location.href =
            process.env.REACT_APP_ENV === "development"
              ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
              : process.env.REACT_APP_LANDING_PAGE_URL;
        })
        .catch((error) => {
          console.error("Error removing cookie:", error);
        });
      const check = arrayPath.every((path) =>
        cookies.get("signinUser", { path: path })
      );
      if (!check) {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
      }
    }
  };
  const handleSignin = () => {
    // if (currentDateTime.isAfter(targetDateTime)) {
    //   setShowPopupTimeUp(true);
    // } else {
    // }
    const listCookies = cookies.getAll();
    localStorage.clear();
    dispatch(clearStateGrade());
    Object.keys(listCookies).forEach((cookie) => {
      if (cookie !== "baseAuth") {
        cookies.remove(cookie);
      }
    });
    arrayPath
      .forEach((path) => {
        if (cookies.get("signinUser", { path: path })) {
          cookies.remove("signinUser", { path: path });
        }
      })
      ?.then(() => {
        window.location.href = window.location.origin + "/signin";
      })
      .catch((error) => {
        console.error("Error removing cookie:", error);
      });
    const check = arrayPath.every((path) =>
      cookies.get("signinUser", { path: path })
    );
    if (!check) {
      window.location.href = window.location.origin + "/signin";
    }
  };

  const closeDropdown = () => {
    if (showAccMenu) {
      setTimeout(() => {
        setShowAccMenu(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (
      !window.location.pathname.includes("error") &&
      cookies.get("signinUser", { path: "/" })
    ) {
      if (listUserInfo === null && cookies.get("signinUser", { path: "/" })) {
        dispatch(getGradeUserInfo());
      }
    }
    // const fetchCurrentTime = async () => {
    //   try {
    //     const res = await axios.get(
    //       "http://worldtimeapi.org/api/timezone/Asia/Ho_Chi_Minh",
    //       {
    //         headers: {
    //           Accept: "application/json", // Thêm Accept: application/json vào tiêu đề
    //         },
    //       }
    //     );
    //     setCurrentDateTime(moment(res.data.datetime));
    //   } catch (error) {
    //     const utcDate = moment();
    //     utcDate.locale("vi");
    //     setCurrentDateTime(utcDate);
    //   }
    // };
    // if (currentDateTime === null) {
    //   fetchCurrentTime();
    // }

    // if (listUserInfo !== null ) {
    //   localStorage.setItem("examId", listUserInfo?.examId);
    //   const check =
    //     new Date(listUserInfo?.examStartDate).getTime() < new Date().getTime();
    //   if (check) {
    //     // const count = Math.floor(
    //     //   new Date(listUserInfo?.examEndDate).getTime() -
    //     //     new Date(listUserInfo?.serverTime).getTime()
    //     // );

    //     // setStart(true);
    //     // setTimeLeft(0);
    //   } else {
    //     const count = Math.floor(
    //       new Date(listUserInfo?.examStartDate).getTime() -
    //         new Date(listUserInfo?.serverTime).getTime()
    //     );

    //     // setStart(true);
    //     // setTimeLeft(count);
    //   }
    // }
    // const countdown = setInterval(() => {
    //   setChangeTime(true);
    //   setCount((count) => count + 1);
    //   // setTimeLeft((timeLeft)=> timeLeft - 60000)
    //   setCountDownDate(
    //     new Date().getTime() +
    //       timeDifference -
    //       (new Date().getTime() +
    //         timeDifference -
    //         new Date(listUserInfo?.serverTime).getTime()) +
    //       timeLeft
    //   );
    // }, 60000);
    handleSetPathname(location.pathname);
    // return () => {
    //   clearInterval(countdown);
    // };
  }, [location.pathname, handleSetPathname]);
  // const rerender = ({ days, hours, minutes, seconds, completed }) => {
  //   // var currentTime = new Date(listUserInfo?.serverTime).getTime();
  //   // var examStartDate = new Date(listUserInfo?.examStartDate).getTime();
  //   // if (examStartDate >= currentTime && timeLeft > 0 && completed) {
  //   //   // handleStartExam(token)
  //   // }
  //   return (
  //     <>
  //       <p style={{ fontSize: "1.5rem" }}>ĐẾN GIỜ THI CÒN</p>
  //       <p>{zeroPad(hours + days * 24)}</p>
  //       <p>:</p>
  //       <p>{zeroPad(minutes)}</p>
  //       <p>:</p>
  //       <p>{zeroPad(seconds)}</p>
  //     </>
  //   );
  // };
  if (error !== null && error === 409) {
    return <ShowPopupError isShowPopup={true} />;
  }
  return (
    <div
      onBlur={closeDropdown}
      className="wrapper__menu-top-bar p-0 is-flex is-align-items-flex-start is-relative"
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "url('/images/menu/bg-image.svg')",
        height: "47rem",
      }}
    >
      {/* {showPopupError && <ShowPopupError isShowPopup={true} />} */}
      {showPopup && (
        <div className=" modal is-active  ">
          <div className="modal-background" style={{ opacity: 0.8 }}></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "2rem",
              width: "25.5625rem",
              height: "22.125rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Đăng xuất !
            </h2>
            <p
              className="has-text-centered"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.125rem",
              }}
            >
              Bạn có chắc chắn muốn đăng xuất?
            </p>
            <div className="is-flex is-flex-direction-column is-flex-gap-4">
              <PrimaryButtonPrivatePageLevel onClick={handleLogout}>
                đăng xuất
              </PrimaryButtonPrivatePageLevel>
              <SecondaryButtonLevelPage
                style={{ borderRadius: "2rem" }}
                onClick={() => setShowPopup(false)}
              >
                hủy
              </SecondaryButtonLevelPage>
            </div>
          </div>
        </div>
      )}
      {/* {showPopupTimeUp && (
        <div className=" modal is-active  ">
          <div className="modal-background" style={{ opacity: 0.8 }}></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "2rem",
              width: "33.5625rem",
              // height: "22.125rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Đã hết thời gian thi thử !
            </h2>
            <p
              className="has-text-centered"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.5rem",
              }}
            >
              Ngày thi chính thức: <b>31/08/2024</b>
            </p>
            <p
              className="has-text-centered"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.25rem",
              }}
            >
              Cấp độ 1: 1<b>4:00 - 15:15</b>
            </p>
            <p
              className="has-text-centered"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.25rem",
              }}
            >
              Cấp độ 2 & 3: <b>16:15 - 17:30</b>
            </p>
            <div className="is-flex is-flex-direction-column is-flex-gap-4">
              <PrimaryButtonPrivatePageLevel
                onClick={() => setShowPopupTimeUp(false)}
              >
                Xác Nhận
              </PrimaryButtonPrivatePageLevel>
            </div>
          </div>
        </div>
      )} */}
      <div
        className=" is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between"
        style={{ width: "100%", padding: "0 1.5rem" }}
      >
        <div
          className="is-flex is-align-items-center is-justify-content-space-between"
          style={{ width: "100%", height: "6rem" }}
        >
          <div className=" has-text-centered is-flex is-align-items-center is-flex-gap-4">
            <div className="is-flex is-flex-gap-4">
              <img
                src="/images/menu/logo-IMAS.png"
                style={{
                  objectFit: "cover",
                  height: "4rem",
                  mixBlendMode: "multiply",
                }}
                alt="test"
              />
              <img
                src="/images/menu/IEG-logo.png"
                style={{ objectFit: "cover",height:"4rem" }}
                alt="test"
              />
            </div>
            {/* {widthDiv < 70 && (
            <div
              onClick={() => setShowDropdown(!showDropdown)}
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
              className={`${showDropdown ? "is-active" : ""} dropdown `}
            >
              <div className="dropdown-trigger">
                <div
                  className="is-flex is-uppercase is-align-items-center is-flex-gap-3 px-5 has-text-kurio-main-color-black has-text-weight-bold"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#E0EEF5",
                    fontSize: "1.5rem",
                    height: "4rem",
                  }}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="#0A2A66"
                  >
                    <path
                      d="M1.26397 0.000244141H22.7342C23.4302 0.000244141 23.9995 0.569566 23.9995 1.26555V1.40155C23.9995 2.09753 23.4302 2.66686 22.7342 2.66686H1.26397C0.567988 2.66686 0 2.09753 0 1.40155V1.26555C0 0.569566 0.567988 0.000244141 1.26397 0.000244141ZM22.7342 6.66678H1.26397C0.567988 6.66678 0 7.2361 0 7.93208V8.06808C0 8.76407 0.567988 9.33339 1.26397 9.33339H22.7342C23.4302 9.33339 23.9995 8.76407 23.9995 8.06808V7.93208C23.9995 7.2361 23.4302 6.66678 22.7342 6.66678ZM22.7342 13.3333H1.26397C0.567988 13.3333 0 13.9026 0 14.5986V14.7346C0 15.4306 0.567988 15.9999 1.26397 15.9999H22.7342C23.4302 15.9999 23.9995 15.4306 23.9995 14.7346V14.5986C23.9995 13.9026 23.4302 13.3333 22.7342 13.3333Z"
                      fill="#0A2A66"
                    />
                  </svg>
                  <p>menu</p>
                </div>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content is-uppercase has-text-kurio-main-color-black has-text-left">
                  <Link
                    to="/"
                    className="dropdown-item has-text-kurio-main-color-black"
                  >
                    Trang chủ
                  </Link>
                  <Link
                    to="/rules"
                    className="dropdown-item has-text-kurio-main-color-black"
                  >
                    thể lệ & quy chế
                  </Link>
                </div>
              </div>
            </div>
          )} */}
          </div>
          <div className="is-flex is-flex-gap-6 is-align-items-center">
            {widthDiv >= 70 && (listUserInfo === null || !listUserInfo) && (
              <div className="is-flex-grow-1">
                <ul
                  className="is-uppercase is-flex is-justify-content-space-between is-align-items-center is-flex-gap-6 has-text-kurio-main-color-black"
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  <li
                    onClick={() => navigate("/")}
                    className={`is-clickable`}
                    style={window.location.pathname === "/" ? currentStyle : {}}
                  >
                    trang chủ
                  </li>
                  <li
                    onClick={() => navigate("/rules")}
                    className={`is-clickable`}
                    style={
                      window.location.pathname.includes("rules")
                        ? currentStyle
                        : {}
                    }
                  >
                    nội quy & quy chế
                  </li>
                </ul>
              </div>
            )}

            {listUserInfo !== null ? (
              <div
                className="is-relative is-flex has-text-kurio-main-color-black is-flex-gap-5"
                style={{ fontSize: "1rem", lineHeight: "normal" }}
              >
                {widthDiv >= 70 && (
                  <>
                    <div
                      className="is-uppercase is-flex is-flex-direction-column is-flex-gap-2 is-align-items-flex-end is-justify-content-center"
                      style={{
                        height: "3.5rem",
                        borderRadius: "0.5rem",
                        background: "#D5E8EF",
                        padding: "0 1rem",
                      }}
                    >
                      <p>bài thi môn</p>
                      <p className="has-text-weight-bold">
                        {listUserInfo?.examName}
                      </p>
                    </div>
                    <div
                      className="is-uppercase is-flex is-flex-direction-column is-flex-gap-2 is-align-items-flex-end is-justify-content-center"
                      style={{
                        height: "3.5rem",
                        borderRadius: "0.5rem",
                        background: "#D5E8EF",
                        padding: "0 1rem",
                      }}
                    >
                      <p className="has-text-weight-bold">
                        {listUserInfo?.fullName}
                      </p>
                      <p>
                        {" "}
                        SBD{" "}
                        <span className="has-text-weight-bold">{userName}</span>
                      </p>
                    </div>
                  </>
                )}
                <div
                  className=" is-flex is-flex-gap-5 is-align-items-center has-background-white is-clickable is-justify-content-center"
                  style={{
                    width: "8rem",
                    height: "3.5rem",
                    borderRadius: "0.5rem",
                  }}
                  onClick={handleLogout}
                >
                  {/* <div
                    className=" is-flex is-flex-direction-column is-flex-gap-1 is-align-items-flex-end has-text-kurio-main-color-black"
                    style={{ lineHeight: "normal" }}
                  >
                    <p style={{ fontSize: "1rem" }}>Xin chào,</p>
                    <p
                      className="has-text-weight-bold"
                      style={
                        listUserInfo?.fullName.split(" ")[
                          listUserInfo?.fullName.split(" ").length - 1
                        ]?.length >= 10
                          ? {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "4.4rem",
                              display: "block",
                              fontSize: "1.5rem",
                            }
                          : { fontSize: "1.5rem" }
                      }
                    >
                      {
                        listUserInfo?.fullName.split(" ")[
                          listUserInfo?.fullName.split(" ").length - 1
                        ]
                      }
                    </p>
                  </div> */}
                  <p
                    className="has-text-weight-bold is-uppercase"
                    style={{
                      fontSize: "1rem",
                      lineHeight: "normal",
                      width: "3rem",
                    }}
                  >
                    Đăng xuất
                  </p>
                  <div className="is-clickable">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5.6 2.1998C5.6 2.9698 4.97 3.5998 4.2 3.5998H2.8V20.3998H4.2C4.97 20.3998 5.6 21.0298 5.6 21.7998C5.6 22.5698 4.97 23.1998 4.2 23.1998H1.4C0.63 23.1998 0 22.5698 0 21.7998V2.1998C0 1.4298 0.63 0.799805 1.4 0.799805H4.2C4.97 0.799805 5.6 1.4298 5.6 2.1998ZM19.6057 5.59454L23.5453 11.1945C23.8953 11.6901 23.8841 12.3551 23.5201 12.8395L19.3201 18.4395C19.0457 18.8063 18.6243 18.9995 18.1987 18.9995C17.9075 18.9995 17.6121 18.9085 17.3601 18.7195C16.7413 18.2561 16.6167 17.3783 17.0801 16.7609L19.6015 13.3995H19.6001H8.4001C7.6273 13.3995 7.0001 12.7737 7.0001 11.9995C7.0001 11.2253 7.6273 10.5995 8.4001 10.5995H19.6001C19.6232 10.5995 19.6447 10.6059 19.6663 10.6123C19.6839 10.6176 19.7016 10.6229 19.7205 10.6247L17.3153 7.20454C16.8701 6.57314 17.0227 5.69954 17.6555 5.25434C18.2869 4.80774 19.1605 4.96174 19.6057 5.59454Z"
                        fill="#0A2A66"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ width: "21.625rem" }}>
                <PrimaryButtonPrivatePageLevel
                  style={{ width: "21.625rem", maxWidth: "21.625rem" }}
                  onClick={handleSignin}
                >
                  đăng nhập làm bài thi
                </PrimaryButtonPrivatePageLevel>
              </div>
            )}
          </div>
        </div>
        {listUserInfo !== null &&
          widthDiv < 70 &&
          window.location.pathname === "/" && (
            <div
              className="is-uppercase is-flex is-justify-content-center is-align-items-center is-flex-gap-5 has-text-kurio-main-color-black"
              style={{
                width: "100%",
                height: "2.5rem",
                borderRadius: "0.5rem",
                background: "#D5E8EF",
                fontSize: "1rem",
              }}
            >
              <p className="has-text-weight-bold">{listUserInfo?.fullName}</p>
              <div className="is-flex is-flex-gap-2 is-align-items-center is-justify-content-center is-uppercase">
                <p>SBD</p>
                <p className="has-text-weight-bold">{userName}</p>
              </div>
              <div className="is-flex is-flex-gap-2 is-align-items-center is-justify-content-center is-uppercase">
                <p>bài thi môn</p>
                <p className="has-text-weight-bold">{listUserInfo?.examName}</p>
              </div>
            </div>
          )}
      </div>
      {window.location.pathname === "/" && (
        <div
          className="is-flex is-align-items-center is-flex-wrap-wrap is-justify-content-center"
          style={{
            position: "absolute",
            top:
              widthDiv >= 70
                ? listUserInfo !== null && listUserInfo
                  ? "8.19rem"
                  : "14.19rem"
                : "12.5rem",
            // width: widthDiv < 70 ? "" : "",
            // height: "17.0625rem",
            gap: widthDiv >= 70 ? "2.5rem" : "0rem",
            // left: widthDiv < 70 ? `0rem` : `0rem`,
          }}
        >
          {/* <div>
            <img
              style={{
                // width: "31.90476rem",
                height: "15.75rem",
                mixBlendMode: "multiply",
              }}
              src="/images/menu/logo-IMAS.png"
              alt=""
            />
          </div> */}
          <div
            className="has-text-centered is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-flex-gap-5"
            style={{ color: "#011CAA" }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-4 ">
              {/* <p
                className="is-uppercase"
                style={{ fontSize: "1.5rem", lineHeight: "normal" }}
              >
                VÒNG TUYỂN CHỌN
              </p>
              <p
                className="has-text-weight-bold is-uppercase is-relative"
                style={{
                  fontSize: "2.5rem",
                  width: "35.875rem",
                  lineHeight: "normal",
                }}
              >
                KỲ THI OLYMPIC<br></br> TOÁN & KHOA HỌC QUỐC TẾ LẦN THỨ 20
                <span
                  style={{
                    fontSize: "1.5rem",
                    position: "absolute",
                    bottom: "1.75rem",
                  }}
                >
                  TH
                </span>
              </p> */}
              <img src="/images/menu/IMAS-banner-web.jpg" alt="" />
              {(listUserInfo === null || !listUserInfo) && (
                <div className="has-text-kurio-main-color-black is-flex is-flex-direction-column is-flex-gap-1">
                  {/* <h2
                      className=" has-text-weight-bold"
                      style={{
                        fontSize: "2.25rem",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    >
                      ! Đã hết thời gian thi thử !
                    </h2> */}
                   <p
                    className="has-text-centered"
                    style={{
                      lineHeight: "normal",
                      fontStyle: "normal",
                      fontSize: "1.5rem",
                    }}
                  >
                    Thời gian thi thử: <b>14:00 | 04/12/2024</b> đến <b>18:00 | 08/12/2024</b>
                  </p>
                  <p
                    className="has-text-centered"
                    style={{
                      lineHeight: "normal",
                      fontStyle: "normal",
                      fontSize: "1.5rem",
                    }}
                  >
                    Ngày thi chính thức: <b>15/12/2024</b>
                  </p>
                  <p
                    className="has-text-centered"
                    style={{
                      lineHeight: "normal",
                      fontStyle: "normal",
                      fontSize: "1.25rem",
                    }}
                  >
                    Cấp độ 1: <b>14:00 - 15:15</b>
                  </p>
                  <p
                    className="has-text-centered"
                    style={{
                      lineHeight: "normal",
                      fontStyle: "normal",
                      fontSize: "1.25rem",
                    }}
                  >
                    Cấp độ 2 và 3: <b>16:30 - 17:45</b>
                  </p>
                  <p
                    className="has-text-danger has-text-weight-bold"
                    style={{
                      lineHeight: "normal",
                      fontStyle: "normal",
                      fontSize: "1.25rem",
                    }}
                  >
                    <i className=" is-underlined">Lưu ý:</i> 15 phút sau giờ bắt
                    đầu, thí sinh sẽ không đăng nhập được vào hệ thống thi
                  </p>
                </div>
              )}
            </div>
            {/* <p
              className="has-background-white"
              style={{ borderRadius: "0.5rem", width: "20rem" }}
            >
              8 : 00 | Thứ 4, 13 / 07 / 2023
            </p> */}
            {widthDiv < 70 && !listUserInfo && !listUserInfo !== null && (
              <>
                <p
                  onClick={() => navigate("/rules")}
                  className={`is-clickable is-uppercase`}
                  style={{ ...currentStyle, borderBottom: "none" }}
                >
                  nội quy & quy chế
                </p>
              </>
            )}
          </div>
        </div>
      )}
      {listUserInfo !== null &&
        listUserInfo &&
        window.location.pathname === "/" && (
          <div
            className="is-flex is-flex-gap-4 is-flex-wrap-wrap"
            style={{
              position: "absolute",
              bottom: widthDiv >= 70 ? "16.94rem" : "13rem",
              width: widthDiv < 70 ? "35rem" : "auto",
              left:
                widthDiv >= 70
                  ? listUserInfo === null || !listUserInfo
                    ? `${(widthDiv - 17.5) / 2}rem`
                    : `${(widthDiv - 42) / 2}rem`
                  : `${(widthDiv - 37.5) / 2}rem`,
            }}
          >
            {/* <div
              className="is-relative has-text-centered has-text-weight-bold is-flex is-align-items-center is-justify-content-center is-flex-gap-4"
              style={{
                width: "35rem",
                borderRadius: "1rem",
                height: "5rem",
                fontSize: "2.5rem",
                padding: "0 2.625rem",
                background: "#D5E8EF",
                color: "#011CAA",
              }}
            >
              {start && (
                <Countdown
                  onComplete={() => {
                    if (
                      new Date(listUserInfo?.examStartDate).getTime() >=
                        new Date(listUserInfo?.serverTime).getTime() &&
                      timeLeft > 0
                    ) {
                      if (localStorage.getItem("page")) {
                        localStorage.removeItem("page");
                      }
                      handleStartExam(token);
                    }
                  }}
                  date={
                    changeTime && countdownDate !== null
                      ? countdownDate
                      : new Date(listUserInfo?.serverTime).getTime() + timeLeft
                  }
                  renderer={rerender}
                />
              )}
            </div> */}
            {listUserInfo !== null && listUserInfo && (
              <div
                className="is-flex is-align-items-center is-justify-content-center has-text-centered is-justify-content-space-between has-text-kurio-main-color-black is-flex-gap-4"
                style={{
                  width: "42rem",
                  flexDirection: widthDiv < 70 ? "column" : "row",
                }}
              >
                <PrimaryButtonPrivatePageLevel
                  style={{
                    width: widthDiv < 70 ? "35rem" : "17.5rem",
                    maxWidth: widthDiv < 70 ? "35rem" : "17.5rem",
                    height: "5rem",
                    borderRadius: "1rem",
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                    background:
                      // listUserInfo?.takeExamStatus === 2 ||
                      // timeLeft < 0 ||
                      // new Date(listUserInfo?.examStartDate).getTime() >
                      //   new Date(listUserInfo?.serverTime).getTime()
                      //   ? "conic-gradient(from 37deg at 31.38% 100.00%, #B2B2B2 0deg, rgba(225, 225, 225, 0.00) 360deg), linear-gradient(180deg, #A8A8A8 0%, #E8E8E8 100%)"
                      //   :
                      "conic-gradient(from 58deg at 34.83% 100.00%, #001BAA 0deg, rgba(4, 31, 158, 0.00) 360deg), linear-gradient(180deg, #012AA8 0%, #3B62D8 100%)",
                  }}
                  onClick={handleClickExam}
                >
                  {/* {listUserInfo?.takeExamStatus === 2
                  ? "Đã nộp bài"
                  : timeLeft < 0
                  ? "Đã kết thúc"
                  : new Date(listUserInfo?.examStartDate).getTime() <
                    new Date(listUserInfo?.serverTime).getTime()
                  ?
                  : "chưa đến giờ"
                } */}
                  bắt đầu làm bài
                </PrimaryButtonPrivatePageLevel>
                <p>
                  Giờ bắt đầu:{" "}
                  <span className=" is-capitalized">
                    {convertTimeExam(listUserInfo?.examStartDate)}
                  </span>
                </p>
              </div>
            )}
          </div>
        )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    listGradeUnit: state.grades.grades,
    gradeId: state.grades.gradeId,
    gradeName: state.grades.gradeName,
    listUserInfo: state.grades.listUserInfo,
    error: state.grades.error,
    gradeCategory: state.grades.gradeCategory,
  };
}

export default connect(mapStateToProps)(MenuTopBar);
