const ConstantStrings = {
    SLIDE : "Slide",
    SECTION: "Section",
    TEMPLATE_CONTENT: "Template Content",
    SUBMIT: "Trả lời",
    NEXT: "Tiếp theo",
    SKIP: "Bỏ qua",
    RETRY: "Làm Lại",
    RESET: "Reset",
    SHOW_EXPLANATION: "xem giải thích",
    SHOW_QUESTION: "xem bài làm",
    REQUEST_FILL_IN_ALL_GAPS: "Please fill in all gaps",
    MULTIPLIERIMAGE:0.25,
    BASE_REM: 16,
    OTP_IS_INCORRECT: "Sai mã OPT",
    DO_NOT_LEAVE_BLANK :"Vui lòng không để trống",
    PASSWORD_INCORRECT :"Mật khẩu không khớp",
    PASSWORD_CHECK:"Mật khẩu từ 8 đến 50 kí tự chứa 1 chữ hoa 1 chữ thường 1 chữ số",
    PASSWORD_NEW: "Mật khẩu mới không được giống mật khẩu cũ",
    TIME_OUT:"Hết thời gian vui lòng bấm gửi lại để nhận mã OTP mới",
    DATA_SITE_KEY: "6LfNQnckAAAAAConbJxXRdzYPGv_FnYMajhagYts",
    IMAGE_URL: process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_IMAGE_URL
      : process.env.REACT_APP_IMAGE_URL,
    VAPID_KEY:"BHegUtIraBNDtG8TEmpb1s5Uviq5UeWYmI4KYXttBbcfrbflSqaVO03MCYwXBBLQ_DsV9a6JX6wSP81byJHbNdM"
}

export default ConstantStrings;