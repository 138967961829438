import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "react-sweet-progress";
// import "react-sweet-progress/lib/style.css";
import { loadWidthImage } from "../../constants/loadWidthImage";
import "./../../styling/layout-components/LessonRightWrapper.css";

export const LoadingImage = ({
  images,
  router,
  handleSetLoadingImage,
  type,
}) => {
  const navigate = useNavigate();
  var backgroundImage = {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundImage: "url('/images/menu/bg-image.svg')",
  };
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [startTime] = useState(Date.now());
  // if (loadingProgress < 100) {
  //   backgroundImage = {
  //     ...backgroundImage,
  //     backgroundImage: "url('/images/logo-k-mau.gif')",
  //   };
  // } else {
  //   backgroundImage = {
  //     ...backgroundImage,
  //     backgroundImage: "url('/images/logomau_noloop.gif')",
  //   };
  // }

  useEffect(() => {
    if (images && images?.length > 0 && type !== "PRACTICE") {
      const increment = 100 / images.length;
      const minDuration =
        images.length * 200 > 2000 ? images.length * 300 : 2000; // Minimum duration in milliseconds (2 seconds)
      for (let index = 0; index < images.length; index++) {
        const elapsedTime = Date.now() - startTime;
        const targetProgress = Math.min((elapsedTime / minDuration) * 100, 100);

        loadWidthImage(images[index], (err, img) => {
          setTimeout(() => {
            setLoadingProgress((prevProgress) =>
              Math.min(prevProgress + increment, targetProgress, 100)
            );
          }, 300);
        });
      }
      if (loadingProgress >= 100) {
        // loadWidthImage("/images/logomau_noloop.gif", (err, img) => {
        // });
        setTimeout(() => {
          handleSetLoadingImage(false);
          if (router?.length > 0) {
            navigate(`/${router}`);
          }
        }, 500);
      }
    }
  }, [
    loadingProgress,
    handleSetLoadingImage,
    navigate,
    router,
    images,
    startTime,
    type,
  ]);
  return (
    <>
      {(!type || !type.includes("PRACTICE")) && (
        <div className="modal is-active" style={backgroundImage}>
          <div className="modal-background has-background-white"></div>

          <div
            className="is-relative is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered"
            style={{
              ...backgroundImage,
              width: "100%",
              height: "100%",
              gap: "2.5rem",
              zIndex: 1000,
            }}
          >
            <img src="/images/menu/loading.svg" alt="loading" />
            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center is-flex-gap-5">
              <div className="is-flex is-flex-gap-4">
                <img
                  src="/images/menu/logo-IMAS.png"
                  alt="logo"
                  style={{ mixBlendMode: "multiply", height: "4rem" }}
                />
                <img src="/images/menu/IEG-logo.png" alt="logo" style={{objectFit: "cover",height:"4rem" }} />
              </div>
              <div className="is-flex is-flex-direction-column is-flex-gap-4">
                <p
                  style={{
                    fontSize: "1.25rem",
                    color: "#0125A9",
                  }}
                  className=" has-text-left"
                >
                  Đang tải
                </p>
                <Progress
                  style={{
                    width: "24.5rem",
                    height: "0.25rem",
                    margin: "0 auto",
                  }}
                  percent={loadingProgress}
                  status={"active"}
                  theme={{
                    active: {
                      symbol: " ",
                      trailColor: "#DCEDF2",
                      color: "#4188BB",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          {images &&
            images?.length > 0 &&
            images.map((item, index) => {
              return (
                <div key={index}>
                  <img src={item} className="is-hidden" alt="" />

                  {/* {index === images.length - 1 &&
                    setTimeout(() => {
                      handleSetLoadingImage(false);
                      if (router?.length > 0) {
                        navigate(`/${router}`);
                      }
                    }, 300)} */}
                </div>
              );
            })}
        </div>
      )}
      {type && type.includes("PRACTICE") && (
        <>
          {images &&
            images?.length > 0 &&
            images.map((item, index) => {
              return (
                <div
                  key={index}
                  className="is-hidden"
                  style={{ position: "absolute" }}
                >
                  <img src={item} className="is-hidden" alt="" />
                </div>
              );
            })}
        </>
      )}
    </>
  );
};
