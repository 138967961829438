import instance from "./instance";

const userUrl = "/auth/v1/user";

export const signin = (account) => {
  return instance.post(`${userUrl}/auth/token`, account);
};
export const getUserInfo = () => {
  return instance.get(`${userUrl}/account/get-user-info`);
};
