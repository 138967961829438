import React, { useEffect, useState } from "react";
import BackgroundImageFromUrl from "../../parseImage/BackgroundImageFromUrl";

const PrimaryButtonQuestion = ({
  style,
  disabled,
  isClick,
  prefix,
  suffix,
  onClick,
  children,
  isDarkColor,
}) => {
  var buttonStyle = {
    width: "12.5rem",
    height: "3rem",
    border: "none",
    fontSize: "1.125rem",
    borderRadius: "1.5rem",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    // color: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  var prefixStyle = {
    position: "absolute",
    width: "1.125rem",
    height: "1.125rem",
    zIndex: "100",
    top: "0.875rem",
    left: "2.0625rem",
    // backgroundColor: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  var suffixStyle = {
    position: "absolute",
    width: "1.125rem",
    height: "1.125rem",
    zIndex: "100",
    top: "0.875rem",
    right: "2.0625rem",
    // backgroundColor: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  if (hoveredState) {
    if (isDarkColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage: "url('/images/button/primary-button-page-level-bg-dark-default.svg')",
      };
    } else {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage: "url('/images/button/primary-button-question-default-bg-hovered.svg')",
      };
    }
  }
  if (defaultState) {
    if (isDarkColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage: "url('/images/button/primary-button-page-level-bg-dark-default..svg')",
      };
    } else {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage: "url('/images/button/primary-button-question-default-bg-default.svg')",
      };
    }
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick();
      }
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
    }
  };
  const handleBlur = () => {
    setClickedState(false);
    setDefaultState(true);
  };
  useEffect(() => {
    if (disabled === false || disabled === undefined) {
      setDefaultState(true);
    } else {
      setDefaultState(false);
    }
  }, [disabled]);
  return (
    <div
      className="main is-relative"
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...style,
        maxWidth: `${style?.maxWidth ? `${style?.maxWidth}` : "12.5rem"}`,
      }}
    >
      {prefix && (
        <BackgroundImageFromUrl
          objectId={prefix}
          style={prefixStyle}
        ></BackgroundImageFromUrl>
      )}
      <div
        // objectId={objectId}
        className={`${
          defaultState
            ? isDarkColor
              ? "has-text-kurio-main-color-purple"
              : "is-primary-button-default-color has-text-kurio-main-color-white"
            : hoveredState
            ? isDarkColor
              ? "has-text-kurio-main-color-purple"
              : "is-primary-button-hovered-color has-text-kurio-main-color-white"
            : clickedState
            ? isDarkColor
              ? "has-text-kurio-main-color-purple"
              : "is-kurio-main-color-black has-text-kurio-main-color-white"
            : disabled
            ? "is-kurio-gray-light-2 has-text-button-disabled-text-color"
            : ""
        }  button  main-color has-text-weight-bold is-uppercase has-text-centered `}
        style={buttonStyle}
        onClick={handleClick}
        type="button"
      >
        {children}
      </div>
      {suffix && (
        <BackgroundImageFromUrl
          objectId={suffix}
          style={suffixStyle}
        ></BackgroundImageFromUrl>
      )}
    </div>
  );
};

export default PrimaryButtonQuestion;
