import instance from "./instance";

const admUrl = "/exam/v1/admin/question";
export const getAQuestion = (id, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  // checkAndCallDailyLoginAPI()
  return instance.get(`${admUrl}/get-a-question/${id}`,config);
};
