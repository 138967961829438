import React, { useState } from "react";
import Cookies from "universal-cookie";
import PrimaryButtonPrivatePageLevel from "./button/primary-button/PrimaryButtonLevelPagePrivate";
import PopupCloseButton from "./button/special-button/PopupCloseButton";

const ShowPopupError = ({ isShowPopup }) => {
  const [showPopup, setShowPopup] = useState(isShowPopup ? isShowPopup : false);
  const removePopup = () => {
    setShowPopup(false);
    const cookies = new Cookies();
    const arrayPath = ["/rules", "/exam-questions", "/"];
    localStorage.clear();
    arrayPath
      .forEach((path) => {
        if (cookies.get("signinUser", { path: path })) {
          cookies.remove("signinUser", { path: path });
        }
      })
      ?.then(() => {
        window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
      })
      .catch((error) => {
        console.error("Error removing cookie:", error);
      });
    const check = arrayPath.every((path) =>
      cookies.get("signinUser", { path: path })
    );
    if (!check) {
      window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
    }
  };
  return (
    <>
      {showPopup && (
        <div className=" modal is-active  ">
          <div
            className="modal-background"
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "1rem",
              width: "31.1875rem",
              height: "24rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Xin hãy đăng nhập lại !
            </h2>
            <div
              className="has-text-centered"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.125rem",
              }}
            >
              <div className="is-flex is-flex-gap-5 is-flex-direction-column">
                <p> Nếu có vấn đề, xin hãy liên hệ</p>
                <p>
                  1. Hotline: <b>0981048228</b>
                </p>
                <p>2. Hoặc liên hệ Fanpage:</p>
              </div>
              <p className="has-text-weight-bold" style={{wordWrap:"break-word", width:"28.1875rem"}}>
              https://www.facebook.com/imasvietnam
              </p>
            </div>
            <PrimaryButtonPrivatePageLevel
              style={{ width: "20rem", height: "4rem", maxWidth: "20rem" }}
              onClick={removePopup}
            >
              Đóng
            </PrimaryButtonPrivatePageLevel>
          <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowPopupError;
