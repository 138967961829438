import React, { useRef, useState } from "react";
import "./Input.style.css";

const InputText = ({
    type,
    className,
    name,
    readOnly,
    value,
    onChange,
    onClick,
    label,
}) => {
    const inputRef = useRef(null);
    const [isHide, setIsHide] = useState(true);

    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
    };
    const hidePassword = () => {
        setIsHide(!isHide);
    };
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <>
            {type && type === "password" && (
                <div className={`is-relative form-outline p-0`}>
                    <input
                        ref={inputRef}
                        className={`${
                            className
                                ? className
                                : "is-secondary-button-clicked-color"
                        } form-input `}
                        type={isHide ? type : "text"}
                        name={name}
                        value={value}
                        required
                        readOnly={readOnly}
                        onChange={handleChange}
                        onClick={handleClick}
                    />
                    <label className="form-label">{label}</label>
                    {isHide && (
                        <span
                            onClick={hidePassword}
                            className="icon is-clickable"
                        >
                            <img src="/images/input/hide-password.svg" alt="loading" />
                        </span>
                    )}
                    {!isHide && (
                        <span
                            onClick={hidePassword}
                            className="icon is-clickable"
                        >
                            <img src="/images/input/show-password.svg" alt="loading" />
                        </span>
                    )}
                </div>
            )}
            {type !== "password" && type !== "date" && (
                <div className={` is-relative form-outline p-0`}>
                    <input
                        ref={inputRef}
                        className={`${
                            className
                                ? className
                                : "is-secondary-button-clicked-color"
                        } form-input `}
                        type={type}
                        name={name}
                        value={value}
                        required
                        readOnly={readOnly}
                        onChange={handleChange}
                        onClick={handleClick}
                    />
                    <label className="form-label">{label}</label>
                </div>
            )}
        </>
    );
};

export default InputText;
