/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPrivatePageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPagePrivate";
import InputText from "../../../components/input/InputText";
import { signin } from "../../../config/auth";
import ConstantStrings from "../../../constants/ConstantStrings";
import { getCacheWidthScreen } from "../../../templates/helper/widthScreen";

function Signin() {
  const widthScreen = getCacheWidthScreen();
  var backgroundImage = {
    backgroundImage: "url('/images/menu/bg-image.svg')",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    minHeight: "100vh",
  };
  const [account, setAccount] = useState({
    userName: "",
    password: "",
  });
  const [error, setError] = useState({
    userName: "",
    password: "",
  });
  // const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleChange = (value, index, name) => {
    setAccount({ ...account, [name]: value });
    setError({
      userName: "",
      password: "",
    });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@]{8,50}$/;
    if (account.password === "" || account.userName === "") {
      account.password === ""
        ? setError({
            ...error,
            password: ConstantStrings.DO_NOT_LEAVE_BLANK,
          })
        : setError({
            ...error,
            userName: ConstantStrings.DO_NOT_LEAVE_BLANK,
          });
    } else {
      try {
        const login = await signin(account);
        var expires = 7 * 24 * 3600;
        // if (cookies.get("signinUser")) {
        //   cookies.remove("signinUser");
        // }
        cookies.set("signinUser", login.access_token, {
          maxAge: expires,
          path: "/",
          // httpOnly: true,
        });
        cookies.remove("lessonId");
        cookies.remove("unitId");
        // setIsLoading(true);
        navigate("/");
      } catch (error) {
        setError({
          ...error,
          userName: error?.response?.data?.errorMessage,
          password: error?.response?.data?.errorMessage,
        });
      }
    }
  };
  const removePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    localStorage.clear();
    if (cookies.get("signinUser", { path: "/" })) {
      navigate("/");
    }
    // if (isLoading) {
    //   const timer = setInterval(() => {
    //     if (loadingProgress < 100) {
    //       setLoadingProgress((prevProgress) => prevProgress + 5);
    //     } else {
    //       clearInterval(timer);
    //       navigate("/");
    //     }
    //   }, 100);

    //   return () => clearInterval(timer);
    // }
  }, [navigate]);
  return (
    <div className="" style={backgroundImage}>
      <div
        className={`${
          widthScreen >= 57.5 && widthScreen < 70 ? "" : "p-0"
        } columns m-0 is-justify-content-center is-align-items-center is-align-content-flex-start`}
        style={{
          minHeight: "100vh",
          width: "100%",
          padding: "5rem 22rem 5.69rem 21.31rem",
        }}
      >
        <div
          className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between has-background-white  has-text-kurio-main-color-black"
          style={{
            padding: "3.1875rem 4rem 2.5rem 3.5rem",
            width: "27.5rem",
            maxHeight: "40rem",
            maxWidth: "27.5rem",
            position: "relative",
            boxShadow: "0.75rem 0.6875rem #290E76",
            border: "0.25rem solid #E2E7EF",
            borderRadius: "7.5rem",
          }}
        >
          <form onSubmit={handleSubmit} noValidate>
            <input hidden type="submit" />
            <div
              className="is-flex is-flex-direction-column is-align-items-center p-0"
              style={{ gap: "2rem" }}
            >
              <div className="is-flex is-flex-gap-4">
                <img
                  src="/images/menu/logo-IMAS.png"
                  alt="logo"
                  style={{ mixBlendMode: "multiply", height: "4rem" }}
                />
                <img src="/images/menu/IEG-logo.png" alt="logo" style={{objectFit: "cover",height:"4rem" }} />
              </div>
              <h2
                style={{ fontSize: "2.25rem", lineHeight: "2.75rem" }}
                className=" has-text-weight-bold has-text-kurio-main-color-purple"
              >
                Đăng nhập thi
              </h2>
              {error.error && (
                <p className="has-text-danger has-text-centered subtitle is-size-6">
                  {error.error}
                </p>
              )}
              <div style={{ width: "20rem", height: "5rem" }}>
                <InputText
                  label={"Số Báo Danh"}
                  type="text"
                  value={account.userName}
                  name={"userName"}
                  onChange={(e) =>
                    handleChange(e.target.value, 0, e.target.name)
                  }
                />
                {error.email && (
                  <p className="has-text-danger subtitle is-size-6">
                    {error.email}
                  </p>
                )}
              </div>
              <div style={{ width: "20rem", height: "5rem" }}>
                <InputText
                  label={"Mật khẩu"}
                  type="password"
                  value={account.password}
                  name="password"
                  onChange={(e) =>
                    handleChange(e.target.value, 0, e.target.name)
                  }
                />

                {error.password && (
                  <p className="has-text-danger subtitle is-size-6">
                    {error.password}
                  </p>
                )}
              </div>
              <PrimaryButtonPrivatePageLevel
                onClick={handleSubmit}
                style={{
                  height: "4rem",
                  width: "15rem",
                  maxWidth: "20rem",
                }}
                type="submit"
              >
                Đăng nhập
              </PrimaryButtonPrivatePageLevel>
              <p
                onClick={() => setShowPopup(true)}
                className="is-underlined is-clickable has-text-kurio-main-color-black"
              >
                Quên mật khẩu?
              </p>
            </div>
          </form>
        </div>
        {showPopup && (
          <div className=" modal is-active  ">
            <div
              className="modal-background"
              onClick={removePopup}
              style={{ opacity: 0.8 }}
            ></div>
            <div
              className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
              style={{
                borderRadius: "1rem",
                width: "29.1875rem",
                height: "24rem",
                padding: "2.5rem",
              }}
            >
              <h2
                className=" has-text-weight-bold"
                style={{
                  fontSize: "2.25rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                }}
              >
                ! Quên Mật khẩu !
              </h2>
              <div
                className="has-text-centered"
                style={{
                  lineHeight: "normal",
                  fontStyle: "normal",
                  fontSize: "1.125rem",
                }}
              >
                <div className="is-flex is-flex-gap-5 is-flex-direction-column">
                  <p>Xin vui lòng liên hệ</p>
                  <p>
                    1. Qua Zalo: <b>0981048228</b>
                  </p>
                  <p>2. Hoặc inbox Fanpage:</p>
                </div>
                <p
                  className="has-text-weight-bold has-text-centered"
                  style={{ wordWrap: "break-word", width: "26.1875rem" }}
                >
                  https://www.facebook.com/imasvietnam
                </p>
              </div>
              <PrimaryButtonPrivatePageLevel
                style={{ width: "20rem", height: "4rem", maxWidth: "20rem" }}
                onClick={removePopup}
              >
                Đóng
              </PrimaryButtonPrivatePageLevel>
              {/* <div className="is-flex is-flex-direction-column is-flex-gap-4">
                <SecondaryButtonLevelPage
                  style={{ borderRadius: "2rem" }}
                  onClick={removePopup}
                >
                  Đóng
                </SecondaryButtonLevelPage>
              </div> */}
              {/* <div
                onClick={removePopup}
                className=""
                style={{
                  position: "absolute",
                  top: "-8%",
                  left: "-4%",
                  zIndex: 9999,
                  background: "transparent",
                  border: "none",
                  width: "5rem",
                  fontSize: "2rem",
                }}
              >
                <PopupCloseButton />
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Signin;
