import React from "react";

const TagCheckPrivate = () => {
  var imgStyle = {
    width: "1.5rem",
    height: "1.5rem",
  };
  return (
    <div
      className={`has-background-kurio-yellow-main-accent is-flex is-flex-direction-row has-text-kurio-main-color-purple has-text-weight-bold has-text-centered is-align-items-center`}
      style={{
        gap: "0.625rem",
        fontSize: "1rem",
        borderRadius: "1rem",
        padding: "0.5rem 0.75rem",
        height: "3rem",
      }}
    >
      <img src="/images/common/checked.svg" style={imgStyle} alt="loading" />
      <p className="" style={{ fontSize: "1rem", fontStyle: "normal" }}>
        Đã trả lời
      </p>
    </div>
  );
};

export default TagCheckPrivate;
