import React from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { clearStateGrade } from "../../reducers/gradesSlice";

const ErrorServerTime = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const arrayPath = ["/rules", "/exam-questions", "/"];
  const handleClick = () => {
    cookies.remove("signinUser");
    localStorage.clear();
    dispatch(clearStateGrade());
    arrayPath
      .forEach((path) => {
        if (cookies.get("signinUser", { path: path })) {
          cookies.remove("signinUser", { path: path });
        }
      })
      ?.then(() => {
        window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
      })
      .catch((error) => {
        console.error("Error removing cookie:", error);
      });
    const check = arrayPath.every((path) =>
      cookies.get("signinUser", { path: path })
    );
    if (!check) {
      window.location.href = process.env.REACT_APP_ENV === "development"? process.env.REACT_APP_DEV_LANDING_PAGE_URL : process.env.REACT_APP_LANDING_PAGE_URL;
    }
  };
  return (
    <div
      className="has-text-centered is-flex is-flex-direction-column is-align-items-center is-centered"
      style={{ gap: "2.875rem", marginTop: "2.25rem" }}
    >
      <ImageFromUrl src="/images/error/kurio-error-image.svg" alt="loading" />
      <PrimaryButtonPageLevel onClick={handleClick} style={{ height: "4rem" }}>
        Ve Trang Chu
      </PrimaryButtonPageLevel>
    </div>
  );
};

export default ErrorServerTime;
