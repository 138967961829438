import "moment/locale/vi";
import React from "react";
import { decodeToken } from "react-jwt";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ShowPopUpBaseAuth from "../../components/ShowPopUpBaseAuth";

const PrivateRouter = ({ page, children }) => {
  const cookies = new Cookies();
  const token = cookies.get("signinUser", { path: "/" });
  const navigate = useNavigate();
  const roles = ["CREATOR", "REVIEWER"];
  // const targetDateTime = moment("2023-12-11T18:01:00");

  if (page === "LOGIN") {
    if (token) {
      alert("Ban da dang nhap");
      return <Navigate to={"/"} />;
    }
    // axios
    //   .get("http://worldtimeapi.org/api/timezone/Asia/Ho_Chi_Minh", {
    //     headers: {
    //       Accept: "application/json", // Thêm Accept: application/json vào tiêu đề
    //     },
    //   })
    //   .then((res) => {
    //     const currentDateTime = moment(res.data.datetime);
    //     if (currentDateTime.isAfter(targetDateTime)) {
    //       window.location.href = "/"
    //       // return <Navigate to={"/"} />;
    //     }
    //   })
    //   .catch((error) => {
    //     const currentDateTime = moment();
    //     currentDateTime.locale("vi");
    //     if (currentDateTime.isAfter(targetDateTime)) {
    //       window.location.href = "/"
    //     }
    //   });
  }

  // if (page === "BASE_AUTH") {
  //   const baseAuthCookies = cookies.get("baseAuth", { path: "/" });
  //   if (!baseAuthCookies) {
  //     return <ShowPopUpBaseAuth isShowPopup={true} children={children} />;
  //   }
  // }
  if (page === "REQUIRE_LOGIN") {
    if (!token) {
      alert("Phai dang nhap");
      return <Navigate to={"/signin"} />;
    }
  }
  if (page === "ADMIN") {
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (
          !decodeToken(e?.data.token).roles.some((role) => roles.includes(role))
        ) {
          alert("Khong duoc vao");
          navigate("/");
        } else {
          cookies.set("signinUser", e?.data.token, {
            maxAge: 2 * 3600,
            path: "/",
            // httpOnly: true,
          });
        }
      }
    };
    window.addEventListener("message", handleRes);
  }
  return children;
};

export default PrivateRouter;
