import React, { memo, useEffect, useState } from "react";
import ConstantStrings from "../../constants/ConstantStrings";
import { loadWidthImage } from "../../constants/loadWidthImage";
import { getImageUrl } from "../../templates/helper/parseImage";

const ImageFromUrl = memo(
  ({ objectId, style, className, setImgWidth, handleSetIsLoading }) => {
    const [isLoading, setIsLoading] = useState(true);
    const imageUrl = getImageUrl(objectId);
    useEffect(() => {
      if (objectId !== null && objectId && objectId.length > 0) {
        if (setImgWidth) {
          loadWidthImage(imageUrl, (err, img) => {
            setImgWidth(
              (img.width / ConstantStrings.BASE_REM) *
                ConstantStrings.MULTIPLIERIMAGE
            );
          });
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }, [className, imageUrl, objectId, setImgWidth]);

    if (isLoading) {
      return <div className={className}>Loading...</div>;
    }

    return (
      <>
        <img className={className} style={style} src={imageUrl} alt="loading" />
      </>
    );
  }
);

export default ImageFromUrl;
