import React, { useState } from "react";

const SecondaryButtonLevelPage = ({
  disabled,
  prefix,
  isClick,
  suffix,
  onClick,
  children,
  style,
}) => {
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);
  var buttonStyle = {
    width: `${style?.width ? style?.width : "15rem"}`,
    height: `${style?.height ? style?.height : "4rem"}`,
    borderRadius: `${style?.borderRadius ? style?.borderRadius : "0.5rem"}`,
    border: "1px solid #0A2A66",
    boxShadow: "none",
  };
  var prefixStyle = {
    position: "absolute",
    width: "1.125rem",
    height: "1.125rem",
    zIndex: "100",
    backgroundImage: `url(${prefix})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: "1.375rem",
    left: "2rem",
    backgroundColor: disabled
      ? "#8C9DC1"
      : clickedState
      ? "#FAFBFC"
      : "#0A2A66",
  };
  var suffixStyle = {
    position: "absolute",
    width: "1.125rem",
    height: "1.125rem",
    zIndex: "100",
    backgroundImage: `url(${suffix})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: "1.375rem",
    right: "2rem",
    backgroundColor: disabled ? "#8C9DC1" : "#FAFBFC",
  };

  if (hoveredState) {
    buttonStyle = {
      ...buttonStyle,
      filter: "drop-shadow(0rem 0.375rem 0.75rem rgba(10, 42, 102, 0.25))",
    };
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      setClickedState(!clickedState);
      setHoveredState(false);
      setDefaultState(clickedState);
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <div
      className="main is-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...style,
        maxWidth: `${style?.maxWidth ? `${style?.maxWidth}` : "15rem"}`,
      }}
    >
      {prefix && <div style={prefixStyle}></div>}
      <button
        className={`${
          defaultState
            ? "is-secondary-button-default-color"
            : hoveredState
            ? "is-secondary-button-default-color"
            : clickedState
            ? "is-secondary-button-clicked-color"
            : disabled
            ? "is-button-disabled-color"
            : ""
        }  button  main-color has-text-weight-bold is-uppercase has-text-centered ${
          disabled
            ? "has-text-button-disabled-text-color"
            : clickedState
            ? "has-text-secondary-button-default-color"
            : "has-text-secondary-button-clicked-color"
        }`}
        style={buttonStyle}
        onClick={handleClick}
        type="button"
      >
        {children}
      </button>
      {suffix && <div style={suffixStyle}></div>}
    </div>
  );
};

export default SecondaryButtonLevelPage;
