import { decodeToken } from "react-jwt";
import instance from "./instance";
const takeExamUrl = "/exam/v1/user/take-exam";
const admUrl = "/exam/v1/admin/exam";

export const getExamQuestions = (id, page, size, token) => {
  const roles = ["EXAM-MANAGER", "ADMIN"];
  const decode = decodeToken(token);
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  if (
    token !== undefined &&
    decode.roles.some((role) => roles.includes(role))
  ) {
    return instance.get(`${admUrl}/get-an-exam/${id}`, config);
  } else {
    // checkAndCallDailyLoginAPI()
    return instance.get(
      `${takeExamUrl}/get-exam-questions/${id}?page=${page}&size=${size}`
    );
  }
};

export const submitExamAnswers = (answer, id) => {
  // checkAndCallDailyLoginAPI()
  return instance.post(`${takeExamUrl}/submit-exam-answers/${id}`, answer);
};
