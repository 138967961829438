/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ShowPopupError from "../../components/ShowError409";
import PaginationBigButton from "../../components/button/pagination-button/PaginationBigButton";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import { submitExamAnswers } from "../../config/examsQuestion";
import { recordAViolation } from "../../config/violation";
import ConstantStrings from "../../constants/ConstantStrings";
import ScrollRef from "../../detail-components/ScrollRef";
import {
  getExamQuestion,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/examsQuestionSlice";
import { getGradeUserInfo } from "../../reducers/gradesSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import ExamQuestionLeftWrapper from "./left/ExamQuestionLeftWrapper";
import ExamQuestionNav from "./left/ExamQuestionNav";
import ExamQuestionRightWrapper from "./right/ExamQuestionRightWrapper";
import jsonParser from "../../templates/helper/jsonParser";

const ExamQuestionsMainWrapper = ({
  takeExamsQuestionId,
  examQuestions,
  seenQuestions,
  listUserInfo,
  error,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(-1);
  const [loadingImage, setLoadingImage] = useState(false);
  const [widthScreen, setWidthScreen] = useState(57.5);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupReload, setShowPopupReload] = useState(false);
  const [showPopup409, setShowPopup409] = useState(false);
  const [showPopupGuide, setShowPopupGuide] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  // const [page, setPage] = useState(
  //   localStorage.getItem("page") ? parseInt(localStorage.getItem("page")) : 1
  // );
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser", { path: "/" });
  const navigate = useNavigate();
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const handleSubmitAnswer = async (payload, id) => {
    try {
      const apiPromise = submitExamAnswers(payload, id);
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Request timeout"));
        }, 10000); // Timeout sau 10 giây
      });
      await Promise.race([apiPromise, timeoutPromise]);
      setIsScroll(true);
    } catch (error) {
      // Xử lý lỗi, ví dụ: hiển thị thông báo
      if (error?.response?.status === 409) {
        setShowPopup409(true);
      } else {
        console.error(error);
        setShowPopupReload(true);
      }
    }
  };
  // const handleChangePage = (page) => {
  //   setPage(page);
  // };

  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      // if (
      //   val < 32 &&
      //   (!localStorage.getItem("page") ||
      //     parseInt(localStorage.getItem("page")) === 2)
      // ) {
      //   // localStorage.setItem("page", 1);
      //   // setPage(1);
      // } else if (
      //   val >= 32 &&
      //   (!localStorage.getItem("page") ||
      //     parseInt(localStorage.getItem("page")) === 1)
      // ) {
      //   // localStorage.setItem("page", 2);
      //   // setPage(2);
      // }
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        var submit = result.submit;
        handleSubmitAnswer(submit, localStorage.getItem("examId"));
      } else {
        var answeredQuestions = examQuestions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer?.length > 0 ? question.answer : [],
        }));
        dispatch(setQuestionsAnswered(answeredQuestions));
      }

      setSelectedQuestion(val);
    }
  };
  const removePopup = () => {
    setShowPopup(false);
    setShowPopupReload(false);
    setShowPopupGuide(false);
  };

  const handleSetShowPopupGuide = () => {
    if (examQuestions.length <= 14 && examQuestions.length > 1) {
      setShowPopupGuide(true);
    }
  };

  const divRef = useRef(null);
  useEffect(() => {
    if (examQuestions === null || !examQuestions) {
      dispatch(
        getExamQuestion({
          takeExamsQuestionId: localStorage.getItem("examId"),
          page: 1,
          size: 70,
          token,
        })
      );
    }
    if (isScroll) {
      setTimeout(() => {
        setIsScroll(false);
      }, 300);
    }
    if (listUserInfo === null || !listUserInfo) {
      dispatch(getGradeUserInfo());
    }

    const setWidth = () => {
      setWidthScreen(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    };
    if (
      divRef?.current &&
      divRef?.current?.clientWidth / ConstantStrings.BASE_REM !== 57.5
    ) {
      setWidthScreen(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    }
    // window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = () => {
      if (
        window.confirm(
          "Rời khỏi trang Web ?\nCác thay đổi bạn đã thực hiện có thể không được lưu."
        ) === true
      ) {
        navigate("/");
      }
    };

    const handleBeforeUnload = (e) => {
      e.preventDefault();
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        var submit = result.submit;
        handleSubmitAnswer(submit, localStorage.getItem("examId"));
      }
      e.returnValue = "";
    };
    const handleVisibilityChange = async (e) => {
      e.preventDefault();
      if (
        document.visibilityState === "hidden" &&
        !localStorage.getItem("finishExam")
      ) {
        try {
          await recordAViolation({
            examId: localStorage.getItem("examId"),
            action: "Mở tab khác hoặc ẩn tab",
          });
          setShowPopup(true);
        } catch (error) {
          if (error?.response?.status === 409) {
            setShowPopup409(true);
          } else {
            showPopupReload(true);
          }
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("resize", setWidth);
    window.history.pushState(null, null, window.location.pathname);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("resize", setWidth);
    };
  }, [dispatch, widthScreen, isScroll]);
  if ((error !== null && error === 409) || showPopup409) {
    return <ShowPopupError isShowPopup={true} />;
  }
  if (loadingImage && examQuestions !== null && examQuestions?.length > 0) {
    const result = getAllImage(examQuestions, "EXAM");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }
  return (
    <div
      ref={divRef}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "url('/images/exam/bg-exam-question.jpg')",
        backgroundAttachment: "fixed",
      }}
    >
      {/* <Notification /> */}
      {loadingImage && <LoadingImage />}
      {showPopup && (
        <div className=" modal is-active " style={{ zIndex: 101 }}>
          <div
            className="modal-background "
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "2rem",
              width: "34.75rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Phạm quy !
            </h2>
            <div
              className="is-flex is-flex-direction-column has-text-centered is-flex-gap-5"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.125rem",
              }}
            >
              <p className="has-text-centered">
                Bạn đã rời khỏi giao diện làm bài trong thời gian thi. Ban tổ
                chức sẽ được thông báo về sự việc này.
              </p>
            </div>
            <div className="is-flex is-flex-direction-column is-flex-gap-4">
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Đóng
              </PrimaryButtonPageLevel>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-12%",
                left: "-8%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
      {showPopupReload && (
        <div className=" modal is-active " style={{ zIndex: 101 }}>
          <div
            className="modal-background "
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "2rem",
              width: "34.75rem",
              padding: "2.5rem",
            }}
          >
            <h2
              className=" has-text-weight-bold"
              style={{
                fontSize: "2.25rem",
                lineHeight: "normal",
                fontStyle: "normal",
              }}
            >
              ! Có lỗi xảy ra !
            </h2>
            <div
              className="is-flex is-flex-direction-column has-text-centered is-flex-gap-5"
              style={{
                lineHeight: "normal",
                fontStyle: "normal",
                fontSize: "1.125rem",
              }}
            >
              <p className="has-text-centered">
                bạn hãy tải lại trang (nhấn F5 trên bàn phím hoặc biểu tượng
                Refresh trên thanh công cụ của trình duyệt)
              </p>
            </div>
            <div className="is-flex is-flex-direction-column is-flex-gap-4">
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Đóng
              </PrimaryButtonPageLevel>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-12%",
                left: "-8%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
      {showPopupGuide && (
        <div className=" modal is-active " style={{ zIndex: 101 }}>
          <div
            className="modal-background "
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div>
          <div
            className="is-flex is-flex-direction-column is-relative is-align-items-center has-text-kurio-main-color-black is-flex-gap-6 has-background-kurio-main-color-white"
            style={{
              borderRadius: "2rem",
              maxWidth: "54rem",
              // width: "34.75rem",
              padding: "2.5rem",
            }}
          >
            <div
              className="is-flex is-flex-grow-1 is-flex-direction-column is-flex-gap-4 has-text-kurio-main-color-black has-text-weight-bold"
              style={{ fontSize: "2rem", lineHeight: "normal" }}
            >
              <p>
                {jsonParser(
                  "There are 14 questions. Each question is worth 5 points. Partial credit is given for question 10 and 13."
                )}
              </p>
              <p>
                {jsonParser(
                  "For all answers, no need to key in their units. The format as following:"
                )}
              </p>
              <p>{jsonParser("For integer$$a,$$key in “$$a$$”.")}</p>
              <p>
                {jsonParser(
                  "For decimal$$a.bc,$$where$$a, b$$and$$c$$are digits, key in “$$a.bc$$” (decimal rounded to the hundredths place)."
                )}
              </p>
            </div>
            <div className="is-flex is-flex-direction-column is-flex-gap-4">
              <PrimaryButtonPageLevel
                onClick={removePopup}
                style={{ height: "4rem" }}
              >
                Đóng
              </PrimaryButtonPageLevel>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-12%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
      {!loadingImage && examQuestions !== null && examQuestions?.length > 0 && (
        <div
          className={`${
            widthScreen >= 70 ? "columns" : ""
          }  is-mobile m-0 mx-auto p-0 has-text-kurio-main-color-black`}
          style={{
            maxWidth: "80rem",
          }}
        >
          {widthScreen >= 57.5 && widthScreen < 70 && (
            <ExamQuestionNav
              selectedIndex={selectedQuestion}
              handleQuestionChange={handleQuestionChange}
              takeExamsQuestionId={takeExamsQuestionId}
              handleSubmitAnswer={(payload, id) =>
                handleSubmitAnswer(payload, id)
              }
              // page={page}
              // handleChangePage={(page) => handleChangePage(page)}
            />
          )}
          {examQuestions && (
            <div
              style={{
                background: "linear-gradient(180deg, #E8E1ED 0%, #D7C5EB 100%)",
                minHeight: "100vh",
                padding:
                  widthScreen >= 70
                    ? "11rem 5rem 5rem 5rem"
                    : "14rem 3rem 5rem 3rem",
              }}
              className={`${
                widthScreen >= 70 ? "is-four-fifths" : ""
              } column is-relative is-flex is-flex-direction-column is-align-items-center `}
            >
              <ExamQuestionRightWrapper
                question={examQuestions[selectedQuestion] ?? examQuestions[0]}
                selectedQuestion={selectedQuestion}
                widthScreen={widthScreen}
                handleQuestionChange={handleQuestionChange}
                handleSubmitAnswer={(payload, id) =>
                  handleSubmitAnswer(payload, id)
                }
                questions={examQuestions}
              />
              {widthScreen >= 57.5 &&
                widthScreen < 70 &&
                examQuestions.length <= 14 &&
                examQuestions.length > 1 && (
                  <div
                    className="is-flex-grow-1 is-flex"
                    style={{ width: "100%" }}
                  >
                    <PaginationBigButton
                      isClick={true}
                      onClick={() => handleSetShowPopupGuide()}
                      value={-1}
                    >
                      ?
                    </PaginationBigButton>
                  </div>
                )}
              {isScroll && <ScrollRef />}
            </div>
          )}
          {widthScreen >= 70 && (
            <div
              className="column is-one-fifth p-0 has-background-kurio-main-color-white"
              style={{
                position: "fixed",
                zIndex: 2,
                right: `${
                  (widthScreen - 80) / 2 > 0 ? (widthScreen - 80) / 2 : 0
                }rem`,
                display: "flex",
                flexDirection: "column",
                width: "16rem",
                height: "100%",
              }}
            >
              <ExamQuestionLeftWrapper
                selectedIndex={selectedQuestion}
                handleQuestionChange={handleQuestionChange}
                takeExamsQuestionId={takeExamsQuestionId}
                handleSubmitAnswer={(payload, id) =>
                  handleSubmitAnswer(payload, id)
                }
                // page={page}
                // handleChangePage={(page) => handleChangePage(page)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    takeExamIdInfo: state.examsQuestion?.examInformation?.takeExamId,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    takeExamIdInformation: state.examsQuestion.takeExamIdInformation,
    takeExamIdLPInformation: state.examsQuestion.takeExamIdLPInformation,
    examQuestions: state.examsQuestion.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
    serverTime: state.examsQuestion.serverTime,
    deadline: state.examsQuestion.checkExamTime,
    error: state.examsQuestion.error,
    listUserInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(ExamQuestionsMainWrapper);
