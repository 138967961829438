import React, { useState } from "react";

const PopupCloseButton = ({ onClick, children }) => {
  const [defaultState, setDefaultState] = useState(true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(false);
  // var objectId = "";
  var elipStyle = {
    width: "5rem",
    height: "5rem",
    border: "none",
    position: "absolute",
    left: "0",
    top: "0",
    boxShadow: "none",
  };
  var exitStyle = {
    position: "absolute",
    left: "7.5%",
    right: "7.02%",
    top: "72.22%",
    zIndex: "100",
    bottom: "-0.46%",
    borderRadius: "9999rem",
  };

  if (defaultState) {
    elipStyle = {
      ...elipStyle,
      borderRadius: "9999rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage:
        "url('/images/button/popup-close-button-default-bg.svg')",
    };
    // objectId = "admin/frontend-images/button/popup-close-button-default-bg.svg";
  }
  if (!defaultState) {
    exitStyle = {
      ...exitStyle,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage:
        "url('/images/button/thoat.svg')",
    }
  }
  if (hoveredState) {
    elipStyle = {
      ...elipStyle,
      borderRadius: "9999rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "drop-shadow(0rem 0.375rem 0.75rem rgba(10, 42, 102, 0.25))",
      backgroundImage:
        "url('/images/button/popup-close-button-hovered-bg.svg')",
    };
    // objectId = "admin/frontend-images/button/popup-close-button-hovered-bg.svg";
  }
  if (clickedState) {
    elipStyle = {
      ...elipStyle,
      borderRadius: "9999rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage:
        "url('/images/button/popup-close-button-clicked-bg.svg')",
    };
    // objectId = "admin/frontend-images/button/popup-close-button-clicked-bg.svg";
  }
  const handleMouseEnter = () => {
    if (!clickedState && defaultState && !hoveredState) {
      setDefaultState(false);
      setHoveredState(true);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !defaultState && hoveredState) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    setClickedState(!clickedState);
    setHoveredState(false);
    setDefaultState(clickedState);
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className="main is-relative"
      style={{ width: "5rem", height: "5.625rem" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`  button  p-0 main-color has-text-weight-bold is-uppercase has-text-centered`}
        style={elipStyle}
        onClick={handleClick}
        type="button"
      >
        {/* {defaultState && <img src="admin/frontend-images/button/popup-close-button-default-bg.svg" alt="" />}
                {hoveredState && <img src="admin/frontend-images/button/popup-close-button-hovered-bg.svg" alt="" />}
                {clickedState && <img src="admin/frontend-images/button/popup-close-button-clicked-bg.svg" alt="" />} */}
      </div>
      <div
        style={exitStyle}
      ></div>
    </div>
  );
};

export default PopupCloseButton;
