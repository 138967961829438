import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButtonQuestion from "../../components/button/primary-button/PrimaryButtonQuestion";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import GapFill from "../../components/question/GapFill";
import MultipleGapFill from "../../components/question/MultipleGapFill";
import TagCheckPrivate from "../../components/question/TagCheckPrivate";
import ConstantStrings from "../../constants/ConstantStrings";
import { checkImage } from "../../constants/checkImage";
import {
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
} from "../../reducers/examsQuestionSlice";
import { cachesQuestion, getCachesQuestion } from "../helper/cacheQuestion";
import jsonParser from "../helper/jsonParser";

export const EquationMultiple = memo(function EquationMultiple({
  question,
  explanation,
  backgroundImage,
  questionIndex,
  questions,
  questionId,
  isAnswered,
  isPreview,
  handleSubmitAnswer,
}) {
  const cache = getCachesQuestion(questionId, questionIndex);
  const dispatch = useDispatch();
  var questionTexts =
    question.texts !== null &&
    question.texts !== undefined &&
    question.texts?.length > 0
      ? question.texts.map((text, index) => jsonParser(text, index))
      : question.texts;
  var questionChoices = question.choices;
  var questionSolutions = question.solutions;
  if (explanation !== null && explanation) {
    if (
      explanation.texts !== null &&
      explanation.texts !== undefined &&
      explanation.texts?.length > 0 &&
      (explanation.texts[0]?.length > 0 || explanation.texts[1]?.length > 0)
    ) {
      var explanationTexts = explanation.texts.map((text, index) =>
        jsonParser(text, index)
      );
    }
    if (
      explanation.images !== null &&
      explanation.images !== undefined &&
      explanation.images?.length > 0 &&
      (explanation.images[0]?.length > 0 || explanation?.images[1]?.length > 0)
    ) {
      var explanationImages = explanation.images;
    }
  }

  const answeredQuestions = useSelector(selectAnsweredQuestions);

  var backgroundStyle = {
    borderRadius: "0 0 1rem 1rem",
  };
  if (backgroundImage !== null && backgroundImage) {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  } else {
    backgroundStyle = {
      ...backgroundStyle,
      backgroundColor: "#E9EEF8",
    };
  }

  const questionStateExams = JSON.parse(
    localStorage.getItem("questionStateExams")
  );

  var indexInLocal = questionStateExams?.questionsAnswered.findIndex(
    (item) => item.questionIndex === questionIndex
  );

  const [answersItem] = useState(() => {
    return questions
      ?.filter((question) => question.questionIndex === questionIndex)
      .map((item) =>
        item.answer !== null
          ? questionStateExams
            ? indexInLocal !== -1 &&
              questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
              questionStateExams?.questionsAnswered[indexInLocal]?.answer !==
                null
              ? questionStateExams?.questionsAnswered[indexInLocal].answer
              : item.answer
            : item.answer
          : []
      )
      .flat();
  });

  const [explanationState] = useState(() => {
    if (question.state) {
      return question.state.explanationState;
    } else {
      return false;
    }
  });

  const [answers, setAnswers] = useState(() => {
    if (question.state) {
      if (
        indexInLocal !== -1 &&
        questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
        questionStateExams?.questionsAnswered[indexInLocal]?.answer !== null
      ) {
        return questionStateExams?.questionsAnswered[indexInLocal].answer;
      }
      return question.state.answers;
    } else {
      if (answersItem && answersItem.length > 0) {
        return answersItem;
      } else {
        if (
          indexInLocal !== -1 &&
          questionStateExams?.questionsAnswered[indexInLocal]?.answer &&
          questionStateExams?.questionsAnswered[indexInLocal]?.answer !== null
        ) {
          return questionStateExams?.questionsAnswered[indexInLocal].answer;
        }
        return [];
      }
    }
  });

  const [correctAnswers, setCorrectAnswers] = useState(() => {
    if (question.showSolution) {
      let newCorrectAnswers = [];
      const answers = answersItem;
      const sum1234 = answers[1] + answers[2] + answers[3] + answers[4];
      const sum35 = answers[3] + answers[5];
      const sum5678 = answers[5] + answers[6] + answers[7] + answers[8];
      newCorrectAnswers = Array(9).fill(false);

      if (answers[0] === questionSolutions[0]) {
        newCorrectAnswers[0] = true;
        if (sum1234 === questionSolutions[1]) {
          newCorrectAnswers.fill(true, 1, 5);
          if (sum35 === questionSolutions[2]) {
            newCorrectAnswers[5] = true;
            if (sum5678 === questionSolutions[3]) {
              newCorrectAnswers.fill(true, 6);
            } else {
              newCorrectAnswers.fill(false, 1);
            }
          } else {
            newCorrectAnswers.fill(false, 1);
          }
        } else {
          newCorrectAnswers.fill(false, 1);
        }
      }

      return newCorrectAnswers;
    } else {
      return [];
    }
  });

  const [maxWidth, setMaxWidth] = useState(() => {
    if (cache?.maxWidth) {
      return cache.maxWidth;
    } else {
      return 60;
    }
  });

  const [isLoading, setIsLoading] = useState(
    cache || !checkImage(questionChoices[1]) ? false : true
  );

  const [submitState, setSubmitState] = useState(isAnswered);

  const [showAnswer, setShowAnswer] = useState(false);

  const divRef = useRef(null);
  if (
    questionChoices !== null &&
    questionChoices &&
    questionChoices.length > 0 &&
    questionChoices[1] !== null &&
    questionChoices[1].length > 0
  ) {
    cachesQuestion(questionId, questionIndex, 0, maxWidth);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }

  // check first time render if questionChoices are not empty strings
  useEffect(() => {
    if (
      divRef?.current?.clientWidth / ConstantStrings.BASE_REM !== maxWidth &&
      divRef?.current?.clientWidth / ConstantStrings.BASE_REM < 60
    ) {
      setMaxWidth(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    }
    const setWidth = () => {
      setMaxWidth(divRef?.current?.clientWidth / ConstantStrings.BASE_REM);
    };
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWidth]);

  const saveAnswer = (answers) => {
    if (!question.showSolution) {
      var questionState = {
        index: questionIndex,
        state: {
          answers: answers,
        },
      };
      var answer = questionChoices.map((item) => false);

      if (answers.length > 0) {
        answers.map(
          (item, index) => (answer[index] = item === "" ? null : item)
        );
      } else {
        answer = null;
      }

      if (
        answer?.every((item) => item === "" || item === false || item === null)
      ) {
        answer = null;
      }

      var result = [...answeredQuestions];
      var newAnswer = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      // setSelected(selected);
      if (result.length === 0) {
        result.push(newAnswer);
      } else {
        var flag = true;
        for (
          let question = 0;
          question < answeredQuestions.length;
          question++
        ) {
          if (answeredQuestions[question].questionId === newAnswer.questionId) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (!flag) {
          result.push(newAnswer);
        } else {
          result = answeredQuestions.map((question) =>
            question.questionId === questionId ? newAnswer : question
          );
        }
      }
      var questionsAnswered = result;
      const submitPayload = {
        questionId: questionId,
        answer: answer,
        questionIndex: questionIndex,
      };
      if (submitPayload.answer !== null && submitPayload.answer?.length > 0) {
        submitPayload.answer = submitPayload?.answer.map((item) =>
          item !== "" && item ? parseInt(item) : item
        );
      }
      const submit = { submit: false, questions: [submitPayload] };
      var questionAnswered = {
        questionIndex: questionIndex,
        status: true,
      };

      if (answer === null) {
        questionAnswered.status = false;
      }
      const value = {
        questionState,
        questionAnswered,
        questionsAnswered,
        submit,
      };

      localStorage.setItem("questionStateExams", JSON.stringify(value));
    }
  };

  const handleChange = (index) => (e) => {
    e.preventDefault();
    let newAnswers = [...answers];
    if (newAnswers.length === 0) {
      questionChoices.map((item) => newAnswers.push(""));
    }
    newAnswers[index] = e.target.value;
    saveAnswer(newAnswers);
    setAnswers(newAnswers);
  };
  const handleSubmit = () => {
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    var payload = result.submit;
    if (!isPreview) {
      handleSubmitAnswer(payload, localStorage.getItem("examId"));
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    } else {
      let newCorrectAnswers = [];
      const answers = payload.questions[0].answer;
      const sum1234 = answers[1] + answers[2] + answers[3] + answers[4];
      const sum35 = answers[3] + answers[5];
      const sum5678 = answers[5] + answers[6] + answers[7] + answers[8];
      newCorrectAnswers = Array(9).fill(false);

      if (answers[0] === questionSolutions[0]) {
        newCorrectAnswers[0] = true;
        if (sum1234 === questionSolutions[1]) {
          newCorrectAnswers.fill(true, 1, 5);
          if (sum35 === questionSolutions[2]) {
            newCorrectAnswers[5] = true;
            if (sum5678 === questionSolutions[3]) {
              newCorrectAnswers.fill(true, 6);
            } else {
              newCorrectAnswers.fill(false, 1);
            }
          } else {
            newCorrectAnswers.fill(false, 1);
          }
        } else {
          newCorrectAnswers.fill(false, 1);
        }
      }

      // for (let i = 0; i < questionSolutions.length; i++) {
      //   if (payload.questions[0].answer[i] === questionSolutions[i]) {
      //     newCorrectAnswers[i] = true;
      //   } else {
      //     newCorrectAnswers[i] = false;
      //   }
      // }
      setCorrectAnswers(newCorrectAnswers);

      setSubmitState(true);
    }
  };
  const handleReset = () => {
    setAnswers([]);
    setSubmitState(false);
    setShowAnswer(false);
    saveAnswer([]);
    const result = JSON.parse(localStorage.getItem("questionStateExams"));
    if (questions?.length > 0) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
    }
    if (isPreview) {
      setCorrectAnswers([]);
    }
  };
  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };
  return (
    <>
      <div style={backgroundStyle} ref={divRef} className="">
        <BackgroundImageFromUrl
          style={backgroundStyle}
          objectId={backgroundImage}
        >
          {isLoading && (
            <div className="has-text-kurio-main-color-black has-text-centered">
              Loading
            </div>
          )}
          {!explanationState && (
            <div
              className={`${
                isLoading ? "is-hidden" : ""
              } question-container is-flex is-flex-direction-column`}
              style={{
                padding: "1.5rem 2rem",
                gap: "1rem",
              }}
            >
              {
                //render questionTexts if its array has length greater than 0
                questionTexts !== null &&
                  questionTexts !== undefined &&
                  questionTexts?.length > 0 && (
                    <div
                      className="has-text-centered m-auto"
                      style={{ maxWidth: "40rem" }}
                    >
                      {questionTexts}
                    </div>
                  )
              }
              <div className="is-flex is-flex-direction-column is-flex-gap-4">
                <div className="is-flex is-align-items-center is-flex-gap-4">
                  <p className="has-text-weight-bold has-text-kurio-main-color-black">
                    a)
                  </p>
                  <GapFill
                    type={"NUMBER"}
                    index={0}
                    onChange={handleChange(0)}
                    answer={answers[0]?.toString() || ""}
                    checkingState={
                      (isPreview !== undefined
                        ? isPreview && submitState
                        : submitState) || showAnswer
                    }
                    correctAnswers={correctAnswers[0]}
                    choice={""}
                  />
                </div>
                <div className="is-flex is-flex-gap-4">
                  <p className="has-text-weight-bold has-text-kurio-main-color-black mt-4">
                    b)
                  </p>
                  <div className="is-flex is-flex-gap-6">
                    <div className="is-flex is-flex-direction-column is-flex-gap-6">
                      {questionChoices.slice(0, 4).map((choice, index) => (
                        <MultipleGapFill
                          type={"IMAGE"}
                          key={index}
                          index={index + 1}
                          onChange={handleChange(index + 1)}
                          answer={answers[index + 1]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          correctAnswers={correctAnswers[index + 1]}
                          choice={choice}
                        />
                      ))}
                    </div>
                    <div className="is-flex is-flex-direction-column is-flex-gap-6">
                      {questionChoices.slice(4).map((choice, index) => (
                        <MultipleGapFill
                          type={"IMAGE"}
                          key={index + 4}
                          index={index + 5}
                          onChange={handleChange(index + 5)}
                          answer={answers[index + 5]?.toString() || ""}
                          checkingState={
                            (isPreview !== undefined
                              ? isPreview && submitState
                              : submitState) || showAnswer
                          }
                          correctAnswers={correctAnswers[index + 5]}
                          choice={choice}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {showAnswer && (
                <>
                  <div
                    className=""
                    style={{ borderTop: "4px dashed #8C9DC1" }}
                  ></div>
                  <p
                    className="is-uppercase has-text-kurio-main-color-black has-text-weight-bold has-text-centered my-4"
                    style={{ lineHeight: "normal" }}
                  >
                    đáp án
                  </p>
                  <div
                    className="is-flex is-flex-direction-column is-flex-gap-4 has-text-kurio-green-main-accent"
                    style={{ fontSize: "1.5rem" }}
                  >
                    <div className="is-flex is-align-items-center is-flex-gap-4">
                      <p className="has-text-weight-bold ">a)</p>
                      <p className="has-text-weight-bold ">
                        {questionSolutions[0]?.toString()}
                      </p>
                    </div>
                    <div className="is-flex is-align-items-center is-flex-gap-4">
                      <p className="has-text-weight-bold ">b)</p>
                      <div className="is-flex is-flex-wrap-wrap is-flex-gap-6">
                        {questionSolutions.map((solution, index) => (
                          <>
                            {index !== 0 && (
                              <p className="has-text-weight-bold ">
                                {solution}
                              </p>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {question.showSolution && (
            <>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center"
                style={{
                  gap: "2rem",
                }}
              >
                <ImageFromUrl
                  style={{ padding: "0rem 2rem" }}
                  objectId="admin/frontend-images/exam/Vector 2.png"
                />

                <h1
                  className="has-text-kurio-main-color-black"
                  style={{
                    fontWeight: 700,
                    fontSize: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  Hướng dẫn
                </h1>
              </div>
              <div
                className="is-flex is-flex-direction-column is-align-items-center"
                style={{ gap: "1.5rem" }}
              >
                {/* Next and "Xem giai thich" button appears when checkingState is true and selected answer is correct */}
                {question.showSolution && explanationState === false && (
                  <>
                    {(explanation.texts !== null ||
                      explanation.images !== null) &&
                    (explanation.texts?.length > 0 ||
                      explanation.images.length > 0) ? (
                      <div
                        style={{
                          gap: "1.5rem",
                          fontSize: "1.25rem",
                          padding: "1rem 3rem",
                          width: "100%",
                        }}
                        className="explanation-container has-text-kurio-main-color-black is-flex is-flex-direction-column is-align-items-center"
                      >
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 0 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[0]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 0 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[0]} />
                              </div>
                            </div>
                          )}
                        {explanation.texts !== null &&
                          explanation.texts !== undefined &&
                          explanation.texts.length > 1 && (
                            <div style={{ textAlign: "center" }}>
                              {explanationTexts && explanationTexts[1]}
                            </div>
                          )}
                        {/* only appears when explanationImages has length greater than 0 */}
                        {explanation.images !== null &&
                          explanation.images !== undefined &&
                          explanation.images?.length > 1 && (
                            <div className="columns is-multiline is-centered m-0 p-0">
                              <div
                                className="column is-narrow is-centered m-0 p-0"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <ImageFromUrl objectId={explanationImages[1]} />
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div
            className="has-background-kurio-main-color-light-gray is-flex is-align-items-center is-justify-content-center is-flex-gap-5"
            style={{
              padding: "0 2rem 2rem 2rem",
              borderRadius: "0 0 1rem 1rem",
            }}
          >
            {submitState && (
              <>
                <TagCheckPrivate />
                <PrimaryButtonQuestion onClick={handleReset}>
                  {ConstantStrings.RETRY}
                </PrimaryButtonQuestion>
              </>
            )}

            {!submitState && (
              <PrimaryButtonQuestion
                onClick={handleSubmit}
                disabled={
                  answers.some(
                    (item) => item === undefined || item === "" || item === null
                  ) || answers.length < questionChoices?.length
                }
              >
                {ConstantStrings.SUBMIT}
              </PrimaryButtonQuestion>
            )}
            {isPreview && (
              <>
                {showAnswer && (
                  <PrimaryButtonQuestion onClick={handleShowAnswer}>
                    Ẩn đáp án
                  </PrimaryButtonQuestion>
                )}
                {!showAnswer && (
                  <PrimaryButtonQuestion onClick={handleShowAnswer}>
                    Hiện đáp án
                  </PrimaryButtonQuestion>
                )}
              </>
            )}
          </div>
        </BackgroundImageFromUrl>
      </div>
    </>
  );
});

export default EquationMultiple;
