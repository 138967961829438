import React from "react";

const PrimaryButtonPrivatePageLevel = ({
  disabled,
  isClick,
  prefix,
  suffix,
  onClick,
  children,
  isDarkColor,
  isYellow,
  style,
}) => {
  // var objectId = "";
  var buttonStyle = {
    width: `${style?.width ? style?.width : "15rem"}`,
    height: `${style?.height ? style?.height : "4rem"}`,
    border: "none",
    borderRadius: `${style?.borderRadius ? style?.borderRadius : "2rem"}`,
    backgroundSize: "cover",
    fontSize: "1.125rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    // color: disabled ? "#8C9DC1" : "#FAFBFC",
    background:
      "conic-gradient(from 70deg at 34.83% 100.00%, #001BAA 0deg, rgba(4, 31, 158, 0.00) 360deg), linear-gradient(180deg, #012AA8 0%, #3B62D8 100%)",
  };
  var prefixStyle = {
    position: "absolute",
    width: "1.25rem",
    height: "1.25rem",
    zIndex: 10,
    top: "1.375rem",
    left: "2rem",
    backgroundColor: "transparent",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  var suffixStyle = {
    position: "absolute",
    width: "1.25rem",
    height: "1.25rem",
    zIndex: 10,
    top: "1.375rem",
    right: "2rem",
    backgroundColor: "transparent",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };


  const handleClick = () => {
    if (onClick) {
      onClick();
    } 
  };

  return (
    <div
      className="button-primary-level-page is-relative"
      style={{
        ...style,
        maxWidth: `${style?.maxWidth ? `${style?.maxWidth}` : "15rem"}`,
      }}
    >
      {prefix && (
        <div
          // objectId={prefix}
          style={prefixStyle}
        ></div>
      )}
      <div
        className={`has-text-kurio-main-color-white  button  main-color has-text-weight-bold is-uppercase has-text-centered `}
        style={{ ...buttonStyle, ...style }}
        // objectId={objectId}
        onClick={handleClick}
        type="submit"
      >
        {children}
      </div>
      {suffix && (
        <div
          // objectId={suffix}
          style={suffixStyle}
        ></div>
      )}
    </div>
  );
};

export default PrimaryButtonPrivatePageLevel;
