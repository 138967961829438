import React, { useEffect, useState } from "react";

const PrimaryButtonPageLevel = ({
  disabled,
  isClick,
  prefix,
  suffix,
  onClick,
  children,
  isDarkColor,
  isYellow,
  style,
}) => {
  // var objectId = "";
  var buttonStyle = {
    width: `${style?.width ? style?.width : "15rem"}`,
    height: `${style?.height ? style?.height : "5rem"}`,
    border: "none",
    borderRadius: `${style?.borderRadius ? style?.borderRadius : "0.5rem"}`,
    backgroundSize: "cover",
    fontSize: "1.125rem",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    // color: disabled ? "#8C9DC1" : "#FAFBFC",
  };
  var prefixStyle = {
    position: "absolute",
    width: "1.25rem",
    height: "1.25rem",
    zIndex: 10,
    top: "1.375rem",
    left: "2rem",
    backgroundColor: "transparent",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  var suffixStyle = {
    position: "absolute",
    width: "1.25rem",
    height: "1.25rem",
    zIndex: 10,
    top: "1.375rem",
    right: "2rem",
    backgroundColor: "transparent",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const [defaultState, setDefaultState] = useState(disabled ? false : true);
  const [hoveredState, setHoveredState] = useState(false);
  const [clickedState, setClickedState] = useState(isClick ? isClick : false);

  if (defaultState) {
    if (isDarkColor) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-page-level-bg-dark-default.svg')",
      };
    } else if (isYellow) {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-page-level-bg-yellow.svg')",
      };
    } else {
      buttonStyle = {
        ...buttonStyle,
        backgroundImage:
          "url('/images/button/primary-button-page-level-bg-default.svg')",
      };
    }
  }
  if (hoveredState) {
    buttonStyle = {
      ...buttonStyle,
      backgroundImage:
        "url('/images/button/primary-button-page-level-bg-hovered.svg')",
    };
  }

  const handleMouseEnter = () => {
    if (!clickedState && !disabled) {
      setDefaultState(false);
      setHoveredState(true);
      setClickedState(false);
    }
  };
  const handleMouseLeave = () => {
    if (!clickedState && !disabled) {
      setDefaultState(true);
      setHoveredState(false);
    }
  };
  const handleClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick();
        setDefaultState(!clickedState);
      } else {
        setClickedState(!clickedState);
        setDefaultState(clickedState);
      }
      setHoveredState(false);
    }
  };
  const handleBlur = () => {
    setClickedState(false);
    setDefaultState(true);
  };
  useEffect(() => {
    if (disabled === false || disabled === undefined) {
      setDefaultState(true);
    } else {
      setDefaultState(false);
    }
  }, [disabled]);
  return (
    <div
      className="button-primary-level-page is-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      style={{
        ...style,
        maxWidth: `${style?.maxWidth ? `${style?.maxWidth}` : "15rem"}`,
      }}
    >
      {prefix && (
        <div
          // objectId={prefix}
          style={prefixStyle}
        ></div>
      )}
      <div
        className={`${
          defaultState
            ? isDarkColor || isYellow
              ? "has-text-kurio-main-color-purple"
              : "is-primary-button-default-color has-text-kurio-main-color-white"
            : hoveredState
            ? isDarkColor
              ? "has-text-kurio-main-color-white"
              : "is-primary-button-hovered-color has-text-kurio-main-color-white"
            : clickedState
            ? isDarkColor
              ? "is-kurio-purple-main-accent has-text-kurio-main-color-white"
              : "is-kurio-main-color-black has-text-kurio-main-color-white"
            : disabled
            ? "is-kurio-gray-light-2 has-text-button-disabled-text-color"
            : ""
        }  button  main-color has-text-weight-bold is-uppercase has-text-centered `}
        style={{...buttonStyle, ...style}}
        // objectId={objectId}
        onClick={handleClick}
        type="submit"
      >
        {children}
      </div>
      {suffix && (
        <div
          // objectId={suffix}
          style={suffixStyle}
        ></div>
      )}
    </div>
  );
};

export default PrimaryButtonPageLevel;
